/* Bootstrap overload */
body{
  font-family: 'Montserrat', sans-serif !important;
  font-size: 14px;
  /* background-color: #ECF0F4 !important; */
}

.popover{
  font-family: 'Montserrat', sans-serif ;
}

.semi-bold{
  font-family: 'Montserrat', sans-serif;
  /* font-weight: 600; */
}

.container-fluid{
  margin-right: 0px;
  margin-left: 0px;
  padding-right: 0px; 
  padding-left: 0px;
}

.row{
  margin-right: 0px;
  margin-left: 0px;
}

a, a:hover{
  color: #0088C7;
  text-decoration: none;
}

.cursor{
  cursor: pointer;
}

.wait{
  cursor: wait !important;
}

.col-md-auto{
  padding-left: 0px;
  /* padding-right: 0px; */
}

.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12{
  padding-left: 0px;
  padding-right: 0px;
}

.col-md-4.center{
  padding-left: 0px;
  padding-right: 0px;
}

h2{
  margin-top: 15px;
  margin-bottom: 15px;
}

.fade{
  opacity: 1;
}

.border-none{
  border: none !important;
}

.form-control{
  /* border-radius: 30px; */
  /* width: 100%; */
}

/* Firefox */
@-moz-document url-prefix() {
  textarea.form-control{
    height: 26px;
  }
}

.box-shadow-none{
  box-shadow: none !important;
}

/* Synfusion Tabs React Css */
/* @import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-react-navigations/styles/material.css'; */

/* Syncfusion Dashboard Layout Css */
@import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-layouts/styles/material.css";

.e-dashboardlayout .e-control .e-panel{
  border-radius: 5px;
  border: 1px solid #CCE7F4;
  padding: 5px;
  margin: 8px;
}

.e-dashboardlayout.e-control .e-panel{
  min-height: 40px;
  border-radius: 5px;
  border: 0.5px solid #D4E2E7;
}

.e-dashboardlayout.e-control .e-panel .e-panel-header{
  padding: 10px 0px 0px 0px;
  margin-bottom: 5px;
}

.e-dashboardlayout.e-control .e-panel .e-panel-header div{
  overflow: visible;
}

/* Syncfusion Menu Item */
.e-menu-wrapper ul .e-menu-item{
  align-items: center;
  height : 24px;
  background-color: transparent;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 12px;
  font-weight: bold;
  color: #B1B2BE;
  text-decoration: none;
  padding-left: 0px;
  padding-right: 0px;
}

.scrollable-menu-control .e-menu-wrapper.e-custom-scroll{
  width: 400px;
}

.e-bigger .scrollable-menu-control .e-menu-wrapper.e-custom-scroll{
  width: 400px;
}

/* Syncfusion Tabs */
.e-tab .e-tab-header .e-toolbar-items{
  width: 100%;
}

.e-tab .e-tab-header .e-toolbar-item.e-icon .e-tab-wrap{
  padding: 0px 5px;
}

/* Syncfusion Color Picker */
.e-colorpicker-wrapper .e-color-palette{
  width: 100% !important;
  box-shadow: none;
}

.e-container .e-palette .e-circle-palette{
  height: 32px;
  width: 32px;
  border: 0;
  border-radius: 20px;
  margin: 4px;
}

.e-bigger .e-container .e-palette .e-circle-palette{
  height: 32px;
  width: 32px;
}

.e-container .e-palette .e-circle-palette{
  border: 1px solid #8E8E90;
}

.e-container .e-palette .e-circle-palette:hover{
  border: 1px solid #8E8E90;
  box-shadow: none;
  transform: scale(1.2);
  transition: transform .2s ease-out;
}

.e-circle-palette .e-circle-selection{
  display: inline-block;
  height: 32px;
  width: 32px;
  border-radius: 20px;
  transform: scale(0);
  transition: transform 1.2s ease-in;
}

.e-circle-palette.e-selected .e-circle-selection{
  transform: scale(0.8);
  background-color: #fff;
  transition: transform .2s ease-out;
}

#circle-palette+.e-container, #scroll-palette+.e-container{
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}

/* Syncfusion Kanban */
.e-kanban-content{
  height: calc(100vh - 180px);
  overflow-y: scroll;
}

.e-kanban .e-kanban-table .e-header-cells{
  background-color: #FFFFFF;
}

.e-kanban .e-kanban-content .e-content-row.e-swimlane-row .e-content-cells .e-swimlane-header{
  align-items: center;
}

.e-kanban .e-kanban-table.e-content-table .e-content-row:not(.e-swimlane-row) td{
  background-color: #FFFFFF;
}

.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card, .e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card{
  border: none;
}

.e-card .e-template .e-droppable .e-lib .e-draggable{
  min-width: 300px;
}

.e-card{
  font-family: 'Montserrat', sans-serif !important;
  /* flex-direction: inherit; */
  line-height: inherit;
  box-shadow: none;
}

.e-card:hover{
  box-shadow: none;
}

/* Syncfusion Tree/Table */
.e-grid{
  border-style: none;
}

.e-grid .e-table{
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

.e-grid .e-tableborder{
  border-right-style: none;
}

.e-grid .e-toolbar{
  background: none;
  border-top: none;
}

/* #toolbar_Add{
  border-radius: 5px;
  background-color: #00C77A;
  opacity: 0.7;
  padding: 3px;
} */

/* #toolbar_Delete{
  border-radius: 5px;
  background-color: #E21313;
  opacity: 0.7;
  padding: 3px;
} */

.e-grid .e-focused:not(.e-menu-item){
  box-shadow: none;
}

.e-grid .e-columnmenu{
  /* padding: 6px 0px 6px 6px; */
}

.e-grid.e-wrap .e-columnmenu{
  margin: -22px;
}

.e-grid .e-gridheader{
  margin-right: 6px;
  padding-right: 0px !important;
  border-top-style: none;
  border-bottom-style: none;
}

.e-treegrid .e-treegridexpand{
  color: #000000 !important;
}

.e-treegrid .e-treecolumn-container span.e-none{
  color: transparent !important;
}

.miniblockTable div div div .e-control, .e-css{
  /* font-family: 'Montserrat', sans-serif !important; */
  font-size: 11px;
}

.miniblockTable div div div .e-grid .e-gridheader{
  height: 30px !important;
}

.e-grid .e-gridheader tr th:first-child{
  padding-left: 10px;
  /* padding-left: 0px; */
}

.e-grid .e-gridheader tr th:last-child{
  padding-right: 0px;
}

.timetrackingTable div div div .e-grid .e-gridheader tr th:last-child{
  padding-right: 10px;
}

.timetrackingTable div div div .e-grid .e-gridheader .e-sortfilter .e-defaultcursor .e-headercelldiv{
  padding: 0px;
}

.timetrackingTable div div div .e-grid .e-gridheader div table thead tr th:first-child .e-headercelldiv{
  padding-left: 10px;
}

.e-grid .e-headercell{
  padding: 0 10px 0;
}

.miniblockTable div div div .e-grid .e-gridheader .e-sortfilter .e-headercelldiv{
  padding: 0 10px 0;
}

.miniblockHolidays .miniblockTable div div div .e-grid .e-gridcontent div table tbody tr td:first-child div .e-icons{
  width: 0px !important;
}

.timetrackingTable div div div .e-grid .e-gridcontent div table tbody tr td:first-child div .e-icons{
  width: 0px !important;
}

.e-grid .e-rowcell{
  font-size: unset;
  padding: 4px 10px;
}

.e-grid .e-rowcell:first-child{
  padding-left: 10px;
}

.e-grid .e-rowcell:last-child{
  padding-right: 10px;
}

.e-grid .e-gridfooter{
  background-color: #FFFFFF;
}

.e-grid .e-footerpadding{
  padding-right: 0px;
}

.e-grid .e-summaryrow{
  height: 40px;
}

.e-grid .e-summarycell{
  font-size: unset;
  padding: 4px 0px;
}

.e-grid .e-frozencontent{
  height: auto !important;
}

.e-grid .e-summaryrow .e-summarycell, .e-grid .e-summaryrow .e-templatecell{
  background-color: #FFFFFF;
}

.e-treegrid .e-grid .e-wrap .e-rowcell .e-treecolumn-container .e-treecell{
  width: 100%;
}

.e-treegrid .e-treecolumn-container span.e-treecell{
  width: 100%;
}

.e-treecolumn-container{
  display: flex !important;
  align-items: center;
  width: 100%;
}

.e-btn{
  background-color: none;
}

.e-toolbar .e-toolbar-items{
  background: none;
}

.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn{
  background: none;
}

.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn:hover{
  background-color: #D7D7D7 !important;
}

.e-pager .e-pagercontainer{
  margin: 0px 5px 0px 10px;
}

.e-pager .e-pagercontainer .e-firstpage, .e-pager .e-pagercontainer .e-prevpage, .e-pager .e-pagercontainer .e-firstpagedisabled, .e-pager .e-pagercontainer .e-prevpagedisabled,
.e-pager .e-pagercontainer .e-nextpage, .e-pager .e-pagercontainer .e-lastpage, .e-pager .e-pagercontainer .e-nextpagedisabled, .e-pager .e-pagercontainer .e-lastpagedisabled{
  min-width: 25px;
  margin-top: 5px;
  margin-right: 10px;
  padding: 10px 5px 0px 5px;
}

.e-pager div.e-parentmsgbar{
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
}

.timetrackingTable div div div .e-grid .e-gridfooter div table tfoot tr td:last-child{
  padding-right: 0px;
}

/* Syncfusion DatePicker Component */
.planningPeriodDatepicker .e-input-group:not(.e-float-icon-left).e-input-group.e-control-wrapper:not(.e-float-icon-left){
  width: 115px !important;
  border: none;
}

.detailsFieldRow div .e-date-wrapper .e-datepicker{
  font-family: 'Montserrat', sans-serif !important;
  font-size: 12px !important;
}

.detailsFieldRow div .e-input-group, .e-input-group.e-control-wrapper{
  margin-bottom: -3px;
}

.planningPeriodDatepicker div .e-date-wrapper .e-datepicker{
  font-family: 'Montserrat', sans-serif !important;
  font-size: 12px !important;
}

.planningPeriodDatepicker div .e-input-group, .e-input-group.e-control-wrapper{
  margin-bottom: -3px;
}

.communicationSlideDateRange .e-date-wrapper .e-datepicker{
  font-family: 'Montserrat', sans-serif !important;
  font-size: 12px !important;
}

.communicationSlideDateRange .e-input-group, .e-input-group.e-control-wrapper{
  margin-bottom: -3px;
}

/* Syncfusion ListBox Component */
/* .e-listbox-wrapper:not(.e-list-template) .e-list-item, .e-listbox-wrapper .e-list-nrt, .e-listbox-wrapper .e-selectall-parent, .e-listbox-container:not(.e-list-template) .e-list-item, .e-listbox-container .e-list-nrt, .e-listbox-container .e-selectall-parent { */
.e-listbox-wrapper .e-list-item{
  padding: 0 16px;
}

.e-listbox-wrapper .e-list-item .listBoxFilters{
  height: 36px;
  line-height: 36px;
  /* padding: 0 16px; */
  position: relative;
}

.communicationListBox .e-listbox-wrapper .e-list-item, .communicationListBox .e-listbox-container .e-list-item{
  border-radius: 9px;
  border: 1px solid #8E8E90;
  margin: 5px 0px 5px 0px;
  padding: 3px;
}

.communicationListBox .e-listbox-wrapper .e-list-item.e-selected, .communicationListBox .e-listbox-container .e-list-item.e-selected {
  border-radius: 9px;
  border: 3px solid #E21313;
}

.communicationListBox{
  overflow-y: auto;
}

.communicationListBox::-webkit-scrollbar{
  height: 0px;
  width: 6px;
  background-color: #FFFFFF;
  border-radius: 10px;
}
  
.communicationListBox::-webkit-scrollbar-thumb{
  background-color: #B1B2BE;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

/* Firefox */
@-moz-document url-prefix() {
  .communicationListBox{
    /* overflow-y: hidden !important; */
    scrollbar-width: thin;
    border-radius: 10px;
  }
}

/* Scrollbar in Scheduler */
.e-schedule .e-content-wrap{
  overflow-x: scroll;
  /* overflow-y: scroll !important; */
}

.blockRoadmapScheduler .e-schedule .e-date-header-container{
  padding-right: 6px !important;
}

/* Syncfusion RichTextEditor  */
.e-richtexteditor .e-rte-content .e-content, .e-richtexteditor .e-source-content .e-content{
  font-size: 14px;
  font-family: 'Montserrat', sans-serif !important;
  /* margin-top: -8px; */
  padding: 5px 15px;
}

.e-rte-content{
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.e-richtexteditor.textEditorClass{
  min-height: 400px;
  min-width: 800px;
  max-height: 600px;
  max-width: 100%;
}

.e-richtexteditor .e-rte-content .e-rte-placeholder{
  margin-top: -4px;
  padding: 5px 15px;
}

/* .e-richtexteditor .e-rte-content .e-rte-placeholder{
  margin-top: 8px;
} */

.e-toolbar .e-toolbar-items .e-toolbar-item.e-active .e-tbar-btn.e-btn, .e-toolbar .e-toolbar-items .e-toolbar-item.e-active .e-tbar-btn.e-btn:hover, .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn:hover {
  background-color: #8E8E90;
  border-radius: 3px; 
}

/* Chrome and Safari */
.e-schedule .e-content-wrap::-webkit-scrollbar{
  height: 6px;
  width: 6px;
  background-color: #FFFFFF;
  border-radius: 10px;
}

.e-schedule .e-content-wrap::-webkit-scrollbar-thumb{
  background-color: #B1B2BE;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

/* Firefox */
@-moz-document url-prefix() {
  .e-schedule .e-content-wrap{
    /* overflow-x: hidden !important; */
    /* overflow-y: hidden !important; */
    scrollbar-width: thin;
    border-radius: 10px;
  }
}

/* Scrollbar in Table/Tree */
.e-content{
  overflow-x: scroll;
  /* overflow-y: scroll !important; */
}

/* Chrome and Safari */
.e-content::-webkit-scrollbar{
  height: 6px;
  width: 6px;
  background-color: #FFFFFF;
  border-radius: 10px;
}

.e-content::-webkit-scrollbar-thumb{
  background-color: #B1B2BE;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

/* Firefox */
@-moz-document url-prefix() {
  .e-content{
    /* overflow-y: hidden !important; */
    scrollbar-width: thin;
    border-radius: 10px;
  }
}

.kanbanContent::-webkit-scrollbar{
  height: 6px;
  width: 0px;
  background-color: #FFFFFF;
  border-radius: 10px;
}

.kanbanContent::-webkit-scrollbar-thumb{
  background-color: #B1B2BE;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

/* Firefox */
@-moz-document url-prefix() {
  .kanbanContent{
    /* overflow-y: hidden !important; */
    scrollbar-width: thin;
    border-radius: 10px;
  }
}

.e-kanban-content::-webkit-scrollbar-thumb{
  background-color: #B1B2BE;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

.e-kanban-content::-webkit-scrollbar{
  height: 0px;
  width: 6px;
  background-color: #FFFFFF;
  border-radius: 10px;
}

/* Firefox */
@-moz-document url-prefix() {
  .e-kanban-content{
    /* overflow-x: hidden !important; */
    scrollbar-width: thin;
    border-radius: 10px;
  }
}

.miniblockTable::-webkit-scrollbar{
  height: 0px;
  width: 6px;
  background-color: #FFFFFF;
  border-radius: 10px;
}

.miniblockTable::-webkit-scrollbar-thumb{
  background-color: #B1B2BE;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

/* Firefox */
@-moz-document url-prefix() {
  .miniblockTable{
    /* overflow-x: hidden !important; */
    scrollbar-width: thin;
    border-radius: 10px;
  }
}

.miniblockComponent::-webkit-scrollbar{
  height: 0px;
  width: 6px;
  background-color: #FFFFFF;
  border-radius: 10px;
}

.miniblockComponent::-webkit-scrollbar-thumb{
  background-color: #B1B2BE;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

/* Firefox */
@-moz-document url-prefix() {
  .miniblockComponent{
    /* overflow-x: hidden !important; */
    scrollbar-width: thin;
    border-radius: 10px;
  }
}

.miniblockBudgetContent::-webkit-scrollbar{
  height: 0px;
  width: 6px;
  background-color: #FFFFFF;
  border-radius: 10px;
}

.miniblockBudgetContent::-webkit-scrollbar-thumb{
  background-color: #B1B2BE;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

/* Firefox */
@-moz-document url-prefix() {
  .miniblockBudgetContent{
    /* overflow-x: hidden !important; */
    scrollbar-width: thin;
    border-radius: 10px;
  }
}

/* Scrollbar in TimeTracking Table */
.e-grid .e-content{
  display: inherit !important;
}

.e-grid .e-movablecontent{
  height: auto;
}

.e-grid .e-headercontent{
  border-right: none;
}

.e-movablecontent{
  overflow-y: hidden !important;
}

/* Chrome and Safari */
.e-movablecontent::-webkit-scrollbar{
  height: 6px;
  width: 0px;
  background-color: #FFFFFF;
  border-radius: 10px;
}
  
.e-movablecontent::-webkit-scrollbar-thumb{
  background-color: #B1B2BE;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

/* Firefox */
@-moz-document url-prefix() {
  .e-movablecontent{
    /* overflow-y: hidden !important; */
    scrollbar-width: thin;
    border-radius: 10px;
  }
}

.e-movableheader{
  margin-right: 6px;
}

.e-grid .e-frozenheader > .e-table{
  border-right-color: #D5D5D7;
}

.e-grid .e-frozencontent > .e-table{
  border-right-color: #D5D5D7;
}

.e-grid .e-summaryrow .e-summarycell{
  border-color: #FFFFFF;
}

.e-scrollbar{
  height: 0px;
}

/* Syncfusion Pivot Table */
.e-gridcontent .e-content .e-movablecontent{
  /* width: fit-content; */
}

.e-pivotview .e-pivot-toolbar{
  background: none;
  border: none;
}

.e-toolbar .e-toolbar-items .e-toolbar-item.e-separator{
  border: none;
  border-width: 0px;
}

.e-pivotview .e-pivotchart > svg{
  border: none;
}

.e-pivotview .e-gtot, .e-pivotview .e-rowsheader, .e-pivotview .e-gtot.e-rowsheader, .e-pivotview .e-gtot.e-columnsheader, .e-pivotview .e-grid .e-content, .e-pivotview .e-grid .e-gridheader, .e-pivotview .e-headercell{
  background-color: #FFFFFF;
}

.miniblockTable div .e-pivotview .e-grid .e-gridheader .e-headercelldiv .e-headertext{
  font-size: 12px;
}

.miniblockTable div .e-pivotview .e-grid .e-gridcontent .e-rowcell{
  font-size: 11px;
}

.miniblockTable div .e-pivotview .e-grid .e-gridcontent .e-rowcell .e-cellvalue{
  font-size: 11px;
}

/* Syncfusion Gantt */
.e-gantt{
  border: none;
}

.e-gantt .e-gantt-toolbar{
  border: none;
}

/* .e-gantt .e-grid .e-icons:not(.e-treegridexpand):not(.e-stop):not(.e-check):not(.e-icon-left):not(.e-date-icon){
  color: transparent !important;
} */

.e-gantt .e-grid .e-focused:not(.e-menu-item) {
  box-shadow: none !important;
}

.e-gantt .e-grid .e-treegridexpand:not(.e-stop):not(.e-check):not(.e-icon-left):not(.e-date-icon){
  color: #000000 !important;
}

.e-gantt .e-grid .e-treegridcollapse:not(.e-stop):not(.e-check):not(.e-icon-left):not(.e-date-icon){
  color: #000000 !important;
}

.e-gantt .e-grid .e-none:not(.e-stop):not(.e-check):not(.e-icon-left):not(.e-date-icon){
  color: transparent !important;
}

.e-gantt .e-gantt-chart .e-gantt-parent-progressbar-inner-div{
  text-align: left;
}

.e-gantt .e-gantt-chart .e-gantt-parent-taskbar-inner-div{
  border-radius: 12px;
}

.e-gantt .e-gantt-chart .e-gantt-child-taskbar-inner-div{
  border-radius: 12px;
}

.e-connector-line{
  stroke: #176A90;
  stroke-width: 2px;
}

.e-connector-line-arrow{
  fill: #176A90;
}

.e-gantt .e-gantt-chart .e-baseline-bar{
  height: 5px !important;
}

/* .e-gantt .e-gantt-toolbar + .e-gantt-splitter{
  height: calc(100% + 2px);
} */

.e-gantt .e-gantt-tree-grid-pane .e-gridheader{
  /* margin-top: 1px; */
}

/* .e-gantt .e-gantt-chart .e-timeline-header-container{
  margin-bottom: 1px;
} */

.e-gantt .e-gantt-tree-grid-pane .e-gridcontent .e-content{
  margin-bottom: 0px;
}

.e-gantt .e-gantt-chart .e-chart-root-container .e-content{
  margin-bottom: 0px;
}

.miniblockTable div div .e-gantt .e-gantt-tree-grid .e-grid .e-gridheader{
  height: 64px !important;
}

.miniblockTable div div .e-gantt .e-gantt-tree-grid .e-grid .e-gridcontent{
  /* height: calc(100% - 64px) !important; */
}

.miniblockTable div div .e-gantt .e-gantt-tree-grid .e-grid .e-gridcontent .e-content{
  /* height: 100% !important; */
}

.e-gantt .e-grid .e-wrap .e-rowcell .e-treecolumn-container .e-treecell{
  width: 100%;
}

.miniblockTable div div .e-gantt .e-gantt-chart .e-right-label-temp-container{
  font-size: 11px;
}

.miniblockTable div div .e-gantt .e-gantt-chart .e-chart-root-container .e-content{
  /* overflow-x: hidden !important; */
  /* overflow-y: hidden !important; */
}

/* .panelContent div div .e-gantt .e-gantt-chart .e-chart-root-container .e-content{
  overflow-x: hidden !important;
  overflow-y: hidden !important;
} */

/* Gantt Indicators */
.e-gantt .e-gantt-chart .e-label{
  /* background-color: #0088C7;
  border-radius: 10px;
  padding: 10px; */
  z-index: 5;
  margin-top: 0px;
  margin-bottom: 0px;
}

/* Syncfusion Chart */
.e-chart{
  height: 100%;
}

/* Syncfusion Scheduler */
.e-schedule{
  border: none;
  margin-top: -2px;
}

.e-schedule .e-schedule-toolbar{
  box-shadow: none;
}

.e-schedule .e-schedule-toolbar, .e-schedule .e-toolbar, .e-schedule .e-toolbar-items{
  background-color: #FFFFFF !important;
}

.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-date-range .e-tbar-btn .e-tbar-btn-text{
  font-weight: bold;
}

.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-separator{
  border: none;
}

.e-schedule .e-month-view .e-work-cells{
  height: 50px;
  max-height: 50px;
}

.e-schedule .e-month-view .e-appointment{
  /* border-radius: 10px; */
}

.e-schedule .e-timeline-month-view .e-appointment{
  background: #FFFFFF;
  border: none;
  border-radius: 6px;
}

.e-schedule .e-timeline-month-view .e-appointment .e-appointment-details{
  display: block;
  padding: 0px;
}

.template-wrap{
  padding: 0px 4px;
}

/* .e-schedule .e-timeline-view .e-resource-cells, .e-schedule .e-timeline-month-view .e-resource-cells {
  height : 30px;
} */

.e-schedule .e-timeline-view .e-parent-node, .e-schedule .e-timeline-month-view .e-parent-node{
  /* display: flex;
  align-items: center; */
  height : 30px;
  background-color: #EFF0F2 !important;
  /* background-color: #B1B2BE; */
}

.e-schedule .e-timeline-view .e-parent-node .specialist-category, .e-schedule .e-timeline-month-view .e-parent-node .specialist-category{
  margin-top: 4px;
}

.e-schedule .e-timeline-view .e-work-cells, .e-schedule .e-timeline-month-view .e-work-cells{
  background-color: #EFF0F2;
  /* background-color: #B1B2BE; */
}

.e-schedule .e-timeline-view .e-work-days, .e-schedule .e-timeline-month-view .e-work-days{
  background-color: #FFFFFF;
}

.e-quick-popup-wrapper .e-event-popup .e-popup-content{
  padding: 0px;
}

.e-quick-popup-wrapper .e-event-popup .e-popup-footer{
  display: block;
}

.e-quick-popup-wrapper .e-popup-footer{
  padding: 0px;
}

/* Syncfusion ListBox */
.e-listbox-wrapper, .e-listbox-container{
  font-size: 12px;
  /* overflow-x: scroll; */
  overflow-y: scroll !important;
}

.e-listbox-wrapper, .e-listbox-container:not(.e-listboxtool-container):not(.e-sortableclone), .e-listboxtool-container.e-listbox-container .e-ul{
  border: none;
}

.e-listbox-wrapper .e-list-item.e-selected, .e-listbox-container .e-list-item.e-selected{
  background-color: #FFFFFF;
}

/* Chrome and Safari */
.e-listbox-wrapper:not(.e-listbox-container)::-webkit-scrollbar{
  height: 0px;
  width: 6px;
  background-color: #FFFFFF;
  border-radius: 10px;
}
  
.e-listbox-wrapper:not(.e-listbox-container)::-webkit-scrollbar-thumb{
  background-color: #B1B2BE;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

/* Firefox */
@-moz-document url-prefix() {
  .e-listbox-wrapper:not(.e-listbox-container){
    /* overflow-x: hidden !important; */
    overflow-y: hidden !important;
    scrollbar-width: thin;
    border-radius: 10px;
  }
}


/* React Bootstrap Popover */
.popover > .arrow{
  display: none;
}

.bs-popover-auto[x-placement^=bottom], .bs-popover-bottom{
  margin: 0;
}

/* React Bootstrap Table */
.react-bootstrap-table-editing-cell{
  max-height: 100%;
  max-width: 100%;
}

.maxh100pc{
  max-height: 100%;
}

.maxw100pc{
  max-width: 100%;
}

.fit-content{
  width: fit-content;
}

/* React Datepicker */
.react-datepicker{
  font-size: 10px !important;
}

.react-datepicker-wrapper{
  display: flex !important;
  align-items: center;
}

.react-datepicker__input-container>input{
  max-width: 75px;
  border: none;
  padding: 0px;
}

#search-bar-0{
  min-width: 220px;
  border: 1px solid #CCE7F4;
  border-radius: 30px;
}

.layout{
  width:100px;
  height:100px;
}

.search-label{
  font-weight: normal;
}

select.form-control:not([size]):not([multiple]){
  height: auto;
}

.input-group .form-control{
  width: auto;
}

.arrow-dropdown-hidden{
  -webkit-appearance: none;
  appearance: none;
}

.table-bordered>tbody>tr>td,.table-bordered>thead>tr,.table-bordered>thead>tr>th,.table-bordered>tfoot>tr>th{
  border: 1px solid transparent; 
}

.table-bordered>tbody>tr>td{
  border-bottom: 1px solid gainsboro;
  vertical-align: middle;
}

/* Tooltips */
.tooltip>.tooltip-inner{
  background-color: #66B8DD;
}

.tooltip>.arrow::before{
  border-left-color: #66B8DD;
  border-right-color: #66B8DD;
  border-top-color: #66B8DD;
  border-bottom-color: #66B8DD;
}

.tooltip-timetracking>.tooltip-inner{
  background-color: #FFFFFF;
  color: #8E8E90;
  font-weight: bold;
  border: 1px solid #8E8E90;
  border-radius: 13px;
  padding: 4px 8px 4px 8px;
}

.tooltip-timetracking>.arrow::before{
  border-right-color: transparent;
  border-top-color: transparent;
}

.tooltip-warning>.tooltip-inner{
  background-color: #E21313;
  border-radius: 13px;
  padding: 4px 8px 4px 8px;
  font-weight: bold;
}

.tooltip-warning>.arrow::before{
  border-right-color: transparent;
  border-top-color: transparent;
}

/* Buttons Colors */
.bg-primary, .btn-primary{
  background-color: #0088C7 !important;
  border-color: #0088C7 !important;
}

.bg-danger, .btn-danger{
  background-color: #E21313 !important;
  border-color: #E21313 !important;
}

.bg-success, .btn-success{
  background-color: #00C77A !important;
  border-color: #00C77A !important;
}

.bg-info, .btn-info{
  background-color: #26A599 !important;
  border-color: #26A599 !important;
}

.bg-warning, .btn-warning{
  background-color: #FABD05 !important;
  border-color: #FABD05 !important;
}

.bg-secondary, .btn-secondary{
  background-color: #8E8E90 !important;
  border-color: #8E8E90 !important;
}

.btn-secondary:hover{
  color: #FFFFFF;
}

.brd-grey{
  border-color: #8E8E90;
}

.brd-today{
  border-color: #E21313;
}

.bg-light, .btn-light{
  background-color: #B1B2BE !important;
  border-color: #B1B2BE !important;
  opacity: 0.1;
}

/* Progress Bar overload */
.progress-bar{
  height: 100%;
  color: #000000;
  /* border-radius: 2px; */
  box-shadow: none;
  -webkit-box-shadow: none;
}

.progressBlock{
  display: inline-flex;
  align-items: center;
  /* height: 10px; */
  width: 90%;
  /* background-color: #F5F5F5; */
}

.progressMiniBlock{
  height: 10px;
  width: 100%;
  background-color: #F5F5F5;
}

.progressSpan{
  position: absolute;
  display: block;
  width : 100%;
  color: black;
}

.progressLabel{
  display: flex;
  /* position: absolute; */
  /* justify-content: center; */
  width: 45px;
}

.progressBar{
  display: flex;
  width: calc(100% - 45px);
}

.progressTable{
  height: 8px;
  width: 100%;
  /* margin-top: 2px; */
  margin-bottom: 0px;
}

.progressMiniTable{
  height: 8px;
  margin-bottom: 0px;
}

.progressTimeTracking{
  height: 15px;
  width: 80%;
  /* width: 600px; */
  margin: auto;
  background-color: #F5F5F5;
  border-radius: 4px;
}

.timelineMinicard{
  height: 4px;
  background-color: #FFFFFF;
}

.timelineLabel{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-size: 12px;
  /* font-weight: bold; */
  width: 100%;
  height: 100%;
}

.progressBarElement{
  position: relative;
  overflow: hidden;
  height: 20px;
  width: 90%;
  /* max-width: 90%; */
  max-width: 200px;
  border-radius: 13px;
  background-color: #E7E7E7;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
  /* margin: 0 auto; */
  /* margin-bottom: 20px; */
}

.progressBarTimeline{
  position: relative;
  overflow: hidden;
  height: 20px;
  width: 90%;
  min-width: 150px;
  max-width: 200px;
  border-radius: 13px;
  background-color: #E7E7E7;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
  /* margin: 0 auto; */
  /* margin-bottom: 20px; */
}

/* .timelineLabelMiniBlock{
  display: flex;
  justify-content: center;
  position: absolute;
  font-size: 12px;
  font-weight: bold;
  width: 100%;
} */

.timelineLabelCancelled{
  text-decoration: line-through;
}

.brd-dashed-grey-hold{
  border: 1px dashed #9E9EA0;
}

/* Progress Circle overload */
.react-sweet-progress-symbol{
  font-size: 11px;
  color: #000000 !important;
  font-weight: 900 !important;
}

.react-sweet-progress-symbol-absolute{
  top: 48%;
}

/* General */
.montserrat{
  font-family: 'Montserrat';
}

.aligncenter{
  text-align: center;
}

.alignleft{
  text-align: left;
}

.alignright{
  text-align: right;
}

.alignvertical{
  vertical-align: middle;
}

.aligntop{
  vertical-align: top;
}

.alignbottom{
  vertical-align: bottom;
}

.align-baseline{
  display: flex;
  align-items: baseline;
}

.align-flexend{
  display: flex;
  align-items: flex-end;
}

.flex{
  display: flex;
}

.flex-wrap{
  flex-wrap: wrap;
}

.flex-no-wrap{
  flex-wrap: nowrap;
}

.flex-start{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end{
  display: flex;
  justify-content: flex-end;
}

.justify-end{
  display: flex;
  justify-content: end;
}

.flex-column{
  flex-direction: column;
}

.flex-column-reverse{
  flex-direction: column-reverse;
}

.flex-row{
  flex-direction: row;
}

.space-around{
  display: flex;
  justify-content: space-around;
}

.space-between{
  display: flex;
  justify-content: space-between;
}

.space-evenly{
  display: flex;
  justify-content: space-evenly;
}

.align-items-center{
  align-items: center;
}

.align-items-end{
  align-items: flex-end;
}

.align-justify-center{
  align-items: center;
  justify-content: center;
}

.white-space{
  white-space: nowrap;
}

.grid{
  display: grid;
}

.displayblock{
  display: block;
}

.centerblock{
  display: block;
  margin: auto;
}

.absolute{
  position: absolute;
}

.relative{
  position: relative;
}

.fixed{
  position: fixed;
}

.floatleft{
  float: left;
}

.floatinitial{
  float: initial;
}

.floatright{
  float: right;
}

.top0{
  top: 0;
}

.top34{
  top: 34px;
}

.bottom0{
  bottom: 0;
}

.bottom10{
  bottom: 10;
}

.left0{
  left: 0;
}

.left50{
  left: 50%;
}

.right0{
  right: 0;
}

.right3{
  right: 3%;
}

.right25{
  position: relative;
  right: 25px;
}

.right50{
  right: 50%;
}

.hidden{
  display: hidden;
}

.inline{
  display: inline-block;
}

.inline-flex{
  display: inline-flex;
}

.uppercase{
  text-transform: uppercase;
}

.marginauto{
  margin: auto;
}

.centervertical{
  margin-top: auto;
  margin-bottom: auto;
}

.center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.left{
  display: flex;
  justify-content: left;
  align-items: center;
}

.right{
  display: flex;
  justify-content: right;
  align-items: center;
}

.text-justify{
  text-justify: auto;
}

.justify{
  justify-content: center;
}

.p5{
  padding: 5px;
}

.p10{
  padding: 10px;
}

.p20{
  padding: 20px;
}

.ph5{
  padding-left: 5px;
  padding-right: 5px;
}

.ph10{
  padding-left: 10px;
  padding-right: 10px;
}

.ph15{
  padding-left: 15px;
  padding-right: 15px;
}

.ph20{
  padding-left: 20px;
  padding-right: 20px;
}

.ph35{
  padding-right: 35px;
  padding-left: 35px;
}

.ph40{
  padding-right: 40px;
  padding-left: 40px;
}

.pv15{
  padding-top: 15px;
  padding-bottom: 15px;
}

.pv5{
  padding-top: 5px;
  padding-bottom: 5px;
}

.pv4{
  padding-top: 4px;
  padding-bottom: 4px;
}

.p0{
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.pl-10{
  padding-left: -10px;
}

.pl0{
  padding-left: 0px !important;
}

.pl1{
  padding-left: 1px;
}

.pr0{
  padding-right: 0px !important;
}

.pl5{
  padding-left: 5px;
}

.pl10{
  padding-left: 10px;
}

.pl15{
  padding-left: 15px;
}

.pl20{
  padding-left: 20px;
}

.pl30{
  padding-left: 30px;
}

.pl60{
  padding-left: 60px;
}

.pr5{
  padding-right: 5px;
}

.pr10{
  padding-right: 10px;
}

.pr15{
  padding-right: 15px;
}

.pr20{
  padding-right: 20px;
}

.pr30{
  padding-right: 30px;
}

.pr40{
  padding-right: 40px;
}

.pt0{
  padding-top: 0px;
}

.pt2{
  padding-top: 2px;
}

.pt4{
  padding-top: 4px;
}

.pt5{
  padding-top: 5px;
}

.pt6{
  padding-top: 6px;
}

.pt7{
  padding-top: 7px;
}

.pt10{
  padding-top: 10px;
}

.pt20{
  padding-top: 20px;
}

.pt30{
  padding-top: 30px;
}

.pb30{
  padding-bottom: 30px;
}

.pb20{
  padding-bottom: 20px;
}

.pb10{
  padding-bottom: 10px;
}

.pb5{
  padding-bottom: 5px;
}

.mh5{
  margin-left: 5px;
  margin-right: 5px;
}

.mh10{
  margin-left: 10px;
  margin-right: 10px;
}

.mh15{
  margin-left: 15px;
  margin-right: 15px;
}

.mh20{
  margin-left: 20px;
  margin-right: 20px;
}

.mh25{
  margin-left: 25px;
  margin-right: 25px;
}

.mh30{
  margin-left: 30px;
  margin-right: 30px;
}

.mh45{
  margin-left: 45px;
  margin-right: 45px;
}

.mh60{
  margin-left: 60px;
  margin-right: 60px;
}

.mh100{
  margin-left: 100px;
  margin-right: 100px;
}

.mh3percent{
  margin-left: 3%;
  margin-right: 3%;
}

.mh10percent{
  margin-left: 10%;
  margin-right: 10%;
}

.ml1{
  margin-left: 1px;
}

.ml2{
  margin-left: 2px;
}

.ml5{
  margin-left: 5px;
}

.ml10{
  margin-left: 10px;
}

.ml15{
  margin-left: 15px;
}

.ml20{
  margin-left: 20px;
}

.ml25{
  margin-left: 25px;
}

.ml28{
  margin-left: 28px;
}

.ml30{
  margin-left: 30px;
}

.ml35{
  margin-left: 35px;
}

.ml80{
  margin-left: 80px;
}

.ml250{
  margin-left: 250px;
}

.ml0 {
  margin-left: 0;
}

.ml0imp {
  margin-left: 0 !important;
}

.ml-30{
  margin-left: -30px !important;
}

.ml-20{
  margin-left: -20px !important;
}

.ml-5{
  margin-left: -5px !important;
}

.ml-10{
  margin-left: -10px !important;
}

.ml-12{
  margin-left: -12px !important;
}

.ml-3{
  margin-left: -3px !important;
}

.ml-2{
  margin-left: -2px !important;
}

.ml40{
  margin-left: 40px;
}

.ml45{
  margin-left: 45px;
}

.ml50{
  margin-left: 50px;
}

.ml100{
  margin-left: 100px;
}

.mr2{
  margin-right: 2px;
}

.mr5{
  margin-right: 5px;
}

.mr7{
  margin-right: 7px;
}

.mr10{
  margin-right: 10px;
}

.mr14{
  margin-right: 14px;
}

.mr15{
  margin-right: 15px;
}

.mr20{
  margin-right: 20px;
}

.mr30{
  margin-right: 30px;
}

.mr50{
  margin-right: 50px;
}

.mr80{
  margin-right: 80px;
}

.mr-30{
  margin-right: -30px;
}

.mr-50{
  margin-right: -50px;
}

.mr-60{
  margin-right: -60px;
}

.mr-15{
  margin-right: -15px;
}

.mr-10{
  margin-right: -10px;
}

.mvauto{
  margin-bottom: auto;
  margin-top: auto;
}

.mv2{
  margin-bottom: 2px !important;
  margin-top: 2px !important;
}

.mv5{
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}

.mv10{
  margin-bottom: 10px !important;
  margin-top: 10px !important;
}

.mv15{
  margin-bottom: 15px !important;
  margin-top: 15px !important;
}

.mv20{
  margin-bottom: 20px !important;
  margin-top: 20px !important;
}

.mv30{
  margin-bottom: 30px !important;
  margin-top: 30px !important;
}

.mt-25{
  margin-top: -25px !important;
}

.mt-17{
  margin-top: -17px !important;
}

.mt-15{
  margin-top: -15px !important;
}

.mt-12{
  margin-top: -12px !important;
}

.mt-10{
  margin-top: -10px !important;
}

.mt-7{
  margin-top: -7px !important;
}

.mt-6{
  margin-top: -6px !important;
}

.mt-5{
  margin-top: -5px !important;
}

.mt-4{
  margin-top: -4px !important;
}

.mt-2{
  margin-top: -2px !important;
}

.mt5percent{
  margin-top: 5%;
}

.mt8percent{
  margin-top: 8%;
}

.mt10percent{
  margin-top: 10%;
}

.mt1{
  margin-top: 1px;
}

.mt2{
  margin-top: 2px;
}

.mt3{
  margin-top: 3px;
}

.mt4{
  margin-top: 4px;
}

.mt5{
  margin-top: 5px;
}

.mt6{
  margin-top: 6px;
}

.mt7{
  margin-top: 7px;
}

.mt8{
  margin-top: 8px;
}

.mt9{
  margin-top: 9px;
}

.mt10{
  margin-top: 10px;
}

.mt12{
  margin-top: 12px;
}

.mt15{
  margin-top: 15px;
}

.mt17{
  margin-top: 17px !important;
}

.mt20{
  margin-top: 20px;
}

.mt25{
  margin-top: 25px;
}

.mt30{
  margin-top: 30px;
}

.mt40{
  margin-top: 40px;
}

.mt50{
  margin-top: 50px;
}

.mt60{
  margin-top: 60px;
}

.mt80{
  margin-top: 80px;
}

.mt225{
  margin-top: 225px;
}

.mb0{
  margin-bottom: 0px;
}

.mb3{
  margin-bottom: 3px;
}

.mb5{
  margin-bottom: 5px;
}

.mb10{
  margin-bottom: 10px;
}

.mb15{
  margin-bottom: 15px;
}

.mb20{
  margin-bottom: 20px;
}

.mb25{
  margin-bottom: 25px;
}

.mb30{
  margin-bottom: 30px !important;
}

.mb40{
  margin-bottom: 40px;
}

.mb50{
  margin-bottom: 50px;
}

.mb60{
  margin-bottom: 60px;
}

.h13{
  height: 13px;
}

.h10 {
  height: 10px;
}

.h15 {
  height: 10px;
}

.h20{
  height: 20px;
}

.h25{
  height: 25px;
}

.h27{
  height: 27px !important;
}

.h28{
  height: 28px;
}

.h30{
  height: 30px;
}

.h40{
  height: 40px;
}

.h45{
  height: 45px;
}

.h50{
  height: 50px;
}

.h180{
  height: 180px;
}

.h227{
  height: 170px !important;
}

.h80{
  height: 80px;
}

.h100{
  height: 100px;
}

.h110{
  height: 110px;
}

.h330{
  height: 330px;
}

.h500{
  height: 500px;
}

.h600{
  height: 600px;
}

.h-pivot{
  height: 700px;
}

.h-fit-content {
  height: fit-content;
}

.height50{
  min-height: 50px;
}

.height500{
  min-height: 500px;
}

.height50p{
  height: 50%;
}

.height100p{
  height: 100%;
}

.height100width100{
  height: 100%;
  width: 100%;
}

.maxheight250{
  max-height: 250px;
}

.widthauto{
  width: auto;
}

.widthautoimp{
  width: auto !important;
}

.width100p{
  width: 100%;
}

.width98p{
  width: 98%;
}

.width92p{
  width: 92%;
}

.width90p{
  width: 90%;
}

.width80p{
  width: 80%;
}

.width75p{
  width: 75%;
}

.width70p{
  width: 70%;
}

.width50p{
  width: 50%;
}

.width40p{
  width: 40%;
}

.width25p{
  width: 25%;
}

.width20p{
  width: 20%;
}

.width2p{
  width: 2%;
}

.width50{
  width: 48px !important;
}

.width300{
  width: 300px;
}

.width330{
  width: 330px;
}

.width650{
  width: 650px;
}

.width600{
  width: 600px;
}

.width540{
  width: 540px;
}

.width400{
  width: 400px;
}

.width320{
  width: 320px;
}

.width288{
  width: 288px;
}

.width280{
  width: 280px;
}

.width200{
  width: 200px;
}

.width400imp{
  width: 400px !important;
}

.width180{
  width: 180px;
}

.width175{
  width: 175px;
}

.width150{
  width: 150px;
}

.width140{
  width: 140px;
}

.width125{
  width: 125px;
}

.width120{
  width: 120px !important;
}

.width110{
  width: 110px;
}

.width100{
  width: 100px !important;
}

.width90{
  width: 90px !important;
}

.width80{
  width: 80px !important;
}

.width75{
  width: 75px !important;
}

.width74{
  width: 74px !important;
}

.width45{
  width: 45px !important;
}

.minwidth140{
  min-width: 240px;
}

.minwidth320{
  min-width: 320px;
}

.maxwidth130{
  max-width: 130px;
}

.maxwidth230{
  max-width: 230px;
}

.maxwidth250{
  max-width: 250px;
}

.maxwidth315{
  max-width: 315px;
}

.maxwidth500{
  max-width: 500px;
}

.maxcontent{
  max-width: max-content;
}

.widthcontent{
  width: max-content;
}

.mincontent{
  min-width: min-content;
}

.opacity20{
  opacity: 0.2;
}

.opacity30{
  opacity: 0.3;
}

.opacity45{
  opacity: 0.45;
}

.opacity50{
  opacity: 0.5;
}

.opacity60{
  opacity: 0.6;
}

.underline{
  text-decoration: underline;
}

.zindex1{
  z-index: 1;
}

.zindex2{
  z-index: 2;
}

.zindex3{
  z-index: 3;
}

.bg-transparent{
  background-color: transparent;
}

.bg-black{
  background-color: #000000;
}

.bg-white{
  background-color: #FFFFFF;
}

.bg-grey{
  background-color: #8E8E90 !important;
}

.bg-light-grey{
  background-color: #B1B2BE;
}

.bg-very-light-grey{
  background-color: #8E8E90;
  opacity: 0.5;
}

.bg-smoke-grey{
  background-color: #CECCCC;
}

.bg-dark-grey{
  background-color: #777777;
}

.bg-blue{
  background-color: #0088C7;
}

.bg-purple{
  background-color: #9E5FFF;
}

.bg-light-blue{
  background-color: #EFF6F9;
}

.bg-darkblue{
  background-color: #050A2F;
}

.bg-green{
  background-color: #00C77A !important;
}

.bg-red{
  background-color: #E21313;
}

.bg-orange{
  background-color: #F57C12;
}

.bg-light-orange{
  background-color: #FFC107;
}

.bg-yellow{
  background-color: #FABD05;
}

.bg-turquoise{
  background-color: #26A599;
}

.black{
  color: #000000;
}

.white{
  color: #FFFFFF;
  -webkit-text-fill-color: #FFFFFF;
}

.grey{
  color: #8E8E90;
}

.light-grey{
  color: #B1B2BE;
}

.very-light-grey{
  color: #8E8E90;
  opacity: 0.5;
}

.smoke-grey{
  color: #CECCCC;
}

.dark-grey{
  color: #777777;
}

.blue{
  color: #0088C7;
}

.purple{
  color: #9E5FFF;
}

.darkblue{
  color: #050A2F;
}

.green{
  color: #00C77A;
}

.darkgreen{
  color: #5FAD74;
}

.red{
  color: #E21313;
}

.orange{
  color: #F57C12;
}

.orange-light{
  color: #FFC107;
}

.yellow{
  color: #FABD05;
}

.turquoise{
  color: #26A599;
}

.fs40{
  font-size: 40px;
}

.fs25{
  font-size: 25px;
}

.fs22{
  font-size: 22px;
}

.fs20{
  font-size: 20px;
}

.fs18{
  font-size: 18px;
}

.fs16{
  font-size: 16px;
}

.fs15{
  font-size: 15px;
}

.fs14{
  font-size: 14px;
}

.fs13{
  font-size: 13px;
}

.fs12{
  font-size: 12px;
}

.fs12imp{
  font-size: 12px !important;
}

.fs11{
  font-size: 11px;
}

.fs10{
  font-size: 10px;
}

.fs8{
  font-size: 8px;
}

.bold{
  font-weight: bold;
}

.italic{
  font-style: italic;
}

.line-height20{
  line-height: 20px;
}

.line-height25{
  line-height: 25px;
}

.line-height30{
  line-height: 30px;
}

.shadow-none{
  box-shadow: none;
}

.brd{
  border: 1px solid #333333;
}

.brd-none{
  border: none;
}

.brd-bottom{
  border-bottom: 1px solid #B1B2BE;
}

.brd-left{
  border-left: 1px solid #B1B2BE;
}

.brd-left-blue{
  border-left: 6px solid #0088C7;
  height: 34px;
  padding-right: 10px;
}

.brd-left-yellow{
  border-left: 6px solid #FABD05;
  height: 34px;
  padding-right: 10px;
}

.brd-left-green{
  border-left: 6px solid #00C77A;
  height: 34px;
  padding-right: 10px;
}

.brd-left-red{
  border-left: 6px solid #E21313;
  height: 34px;
  padding-right: 10px;
}

.brd-left-grey{
  border-left: 6px solid #8E8E90;
  height: 34px;
  padding-right: 10px;
}

.brd-left-verylightgrey{
  border-left: 6px solid #D5D5D7;
  height: 34px;
  padding-right: 10px;
}

.brd-verylightgrey{
  border: 1px solid #D5D5D7;
}

.brd-left-turquoise{
  border-left: 6px solid #26A599;
  height: 34px;
  padding-right: 10px;
}

.brd-comments{
  border-left: 1px solid #D5D5D7;
}

.brd-timetracking{
  border: 1px solid #B3DCEE;
  border-radius: 15px;
  padding: 4px 30px 4px 30px;
}

.brd-dashed-timetracking{
  border: 1px dashed #B3DCEE;
  border-radius: 15px;
  opacity: 0.5;
  padding: 4px 30px 4px 30px;
}

.brd-blue{
  border: 1px solid #CCE7F4;
}

.brd-blue-radius{
  border: 1px solid #CCE7F4;
  border-radius: 5px;
  margin: 8px;
  padding: 5px;
}

.brd-shadow-turquoise{
  box-shadow: 0px 10px 15px #DCF1EF;
  padding-top: 20px;
  padding-bottom: 20px;
}

.brd-shadow-blue{
  box-shadow: 0px 10px 15px #D6ECF6;
  padding-top: 20px;
  padding-bottom: 20px;
}

.brd-radius{
  border-radius: 13px;
}

.brd-radius6{
  border-radius: 6px;
}

.brd-radius30{
  border-radius: 30px !important;
}

.brd-dashed{
  border: 1px dashed #8E8E90;
  border-radius: 13px;
  opacity: 0.5;
}

.brd-dashed-lightgrey{
  border: 2px dashed #D5D5D7;
  border-radius: 13px;
}

.brd-lightgrey{
  border: 1px solid #D5D5D7;
  border-radius: 13px;
}

.brd-selected{
  border: 2px dashed #0088C7;
  border-radius: 20px;
  padding: 5px;
}

.filterStatus{
  height: 15px;
  width: 65px;
  /* margin: auto; */
}

.height-content{
  height: min-content;
}

.height400{
  max-height: 400px;
}

.width-content{
  width: min-content;
}

/* Images */
.images{
  display: inline-block;
  vertical-align: middle;
  mask-repeat: no-repeat !important;
}

.bigImages{
  height: 40%;
  width: 40%;
  margin-left: 30%;
  margin-right: 30%;
}

/* .imageHomePage{
  background: #050A2F;
  mask: url('../Images/HomePage.svg');
} */

.imageHomePage{
  height: 80%;
  width: 80%;
}

/* ---------- Icons ---------- */
.extrasmallIcons, .verysmallIcons, .icons, .smallIcons, .mediumIcons, .bigIcons, .veryBigIcons, .iconsLoginLanguage, .iconsBlocks, .iconsCard, .iconsDashboard, .iconsMinicard, .iconsMinicardKanban, .iconsDatepicker, .iconsTitle, .iconsFilter, .iconsViews, .iconsViewsActions, .iconsViewType, .iconsPopover, .iconsPopup, .iconsLanguage, .iconsNav, .iconsFavorite, .iconsRating, .iconsPath, .iconsMiniblock, .iconsFilterMiniblock, .iconsEmptyBlock, .iconsTemplateHeader, .iconsTable, .iconsGantt, .iconsChartParameters, .iconsChartMode, .iconsChartModel, .iconsCircle, .iconsHelp, .iconsMeteo, .iconsRectangle, .iconsRectangleMiniTable, .iconsShape, .iconsSlide, .iconsBigSlides, .iconsSlideParameters, .iconsSlideFontSize, .iconsTimeTracking, .iconsTimeTrackingHoliday, .iconsTimeTrackingWeekendHoliday, .iconsTimeTrackingBankHoliday, .iconsLegend, .iconsUser{
  display: inline-block;
  vertical-align: middle;
  mask-repeat: no-repeat !important;
}

.nav.icon{
  display: inline-block;
  mask-size: contain;
  mask-repeat: no-repeat;
}

/* Sizes */
.extrasmallIcons{
  height: 12px;
  width: 12px;
}

.verysmallIcons{
  height: 17px;
  width: 17px;
}

.icons{
  height: 23px;
  width: 23px;
}

.smallIcons{
  width: 24px;
  height: 19px;
  margin-right: 5px;
}

.mediumIcons{
  height: 30px;
  width: 30px;
}

.bigIcons{
  height: 50px;
  width: 50px;
}

.veryBigIcons{
  height: 60px;
  width: 60px;
}

.iconsLoginLanguage{
  height: 25px;
  width: 25px;
  padding: 1px;
}

.iconsBlocks{
  height: 25px;
  width: 25px;
  margin-top: -10px;
  margin-right: 5px;
}

.iconsCard{
  height: 28px;
  width: 28px;
}

.iconsDashboard{
  height: 23px;
  width: 23px;
  min-width: 23px;
}

.iconsMinicard{
  height: 18px;
  width: 18px;
}

.iconsMinicardKanban{
  height: 20px;
  width: 20px;
}

.iconsDatepicker{
  height: 16px;
  width: 16px;
}

.iconsTitle{
  height: 20px;
  width: 20px;
  margin-top: -5px;
  margin-right: 5px;
}

.iconsFilter{
  height: 24px;
  width: 24px;
}

.iconsViews{
  height: 16px;
  width: 16px;
}

.iconsViewsActions{
  height: 12px;
  width: 12px;
}

.iconsViewType{
  height: 30px;
  width: 30px;
}

.iconsPopover{
  height: 20px;
  width: 20px;
}

.iconsPopup{
  height: 24px;
  width: 24px;
}

.iconsLanguage{
  height: 20px;
  width: 20px;
  padding: 1px;
}

.iconsNav{
  width: 20px;
  height: 20px;
}

.iconsFavorite{
  height: 20px;
  width: 18px;
}

.iconsRating{
  height: 20px;
  width: 20px;
}

.iconsPath{
  width: 15px;
  height: 15px;
}

.iconsMiniblock{
  height: 20px;
  width: 20px;
}

.iconsFilterMiniblock{
  height: 14px;
  width: 14px;
}

.iconsTemplateHeader{
  height: 14px;
  width: 14px;
}

.iconsEmptyBlock{
  height: 100px;
  width: 100px;
}

.iconsTable{
  height: 22px;
  width: 22px;
}

.iconsGantt{
  height: 16px;
  width: 16px;
}

.iconsChartParameters{
  height: 20px;
  width: 20px;
}

.iconsChartMode{
  height: 20px;
  width: 20px;
}

.iconsChartModel{
  display: inline-block;
  height: 104px;
  width: 160px;
  vertical-align: top;
}

.iconsCircle{
  width: 13px;
  height: 13px;
}

.iconsHelp{
  height: 24px;
  width: 24px;
}

.iconsMeteo{
  height: 35px;
  width: 35px;
}

.iconsRectangle{
  height: 30px;
  width: 50px;
}

.iconsRectangleMiniTable{
  height: 26px;
  width: 44px;
}

.iconsShape{
  height: 20px;
  width: 20px;
}

.iconsSlide{
  display: inline-block;
  height: 85px;
  width: 190px;
  vertical-align: top;
}

.iconsBigSlides{
  display: inline-block;
  height: 300px;
  width: 720px;
  vertical-align: top;
}

.iconsSlideParameters{
  height: 20px;
  width: 20px;
}

.iconsSlideFontSize{
  height: 28px;
  width: 28px;
}

.iconsTimeTracking{
  height: 32px;
  width: 32px;
}

.iconsTimeTrackingHoliday{
  height: 28px;
  width: 28px;
}

.iconsTimeTrackingWeekendHoliday{
  height: 28px;
  width: 28px;
}

.iconsTimeTrackingBankHoliday{
  height: 28px;
  width: 28px;
}

.iconsLegend{
  height: 25px;
  width: 25px;
}

.iconsUser{
  width: 40px;
  height: 40px;
}


/* Navbar Icons */
.iconTabstersLogoWhite{
  background: #FFFFFF;
  mask: url('../Images/Icons/LogoTabsters.svg');
}

.iconSearch{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconSearch.svg');
}

.iconSearch:hover{
  background: #050A2F;
}

.iconActivity{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconActivity.svg');
}

.iconActivity:hover{
  background: #050A2F;
}

.iconTimeTracking{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconTimeTracking.svg');
}

.iconTimeTracking:hover{
  background: #050A2F;
}

.iconReportsLight{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconReports.svg');
}

.iconReportsLight:hover{
  background: #050A2F;
}

.iconUserNav{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconUser.svg');
}

.iconUserNav:hover{
  background: #050A2F;
}

.iconUserNavAccount{
  background: #050A2F;
  mask: url('../Images/Icons/IconUser.svg');
}

.iconNotifications{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconNotificationBell.svg');
}

.iconNotifications:hover{
  background: #050A2F;
}

.iconNotificationsAccount{
  background: #050A2F;
  mask: url('../Images/Icons/IconNotificationBell.svg');
}

.iconNotificationsDarkBlue{
  background: #050A2F;
  mask: url('../Images/Icons/IconNotificationBell.svg');
}

.iconLockDarkBlue{
  background: #050A2F;
  mask: url('../Images/Icons/IconLock.svg');
}

.iconAdministration{
  background: #050A2F;
  mask: url('../Images/Icons/IconAdministration.svg');
}

.iconAdministrationWhite{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconAdministration.svg');
}

.iconArchived{
  background: #050A2F;
  mask: url('../Images/Icons/IconArchived.svg');
}

.iconArchivedLight{
  background: #ffffff;
  mask: url('../Images/Icons/IconArchived.svg');
}

.iconEnvironmentNav{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconEnvironment.svg');
}

.iconEnvironmentNav:hover{
  background: #050A2F;
}

.iconEnvironment{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconEnvironment.svg');
}

.iconEnvironmentBlue{
  background: #0088C7;
  mask: url('../Images/Icons/IconEnvironment.svg');
}

.iconMap{
  background: #0088C7;
  mask: url('../Images/Icons/IconAction.svg');
}

.iconNewProjetEmptyTemplate{
  background: #0088C7;
  mask: url('../Images/Icons/IconEnvironment.svg');
}

.iconNewProjetTemplate{
  background: #0088C7;
  mask: url('../Images/Icons/IconNewProjetTemplate.svg');
}

.iconHelp{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconHelp.svg');
}

.iconHelp:hover{
  background: #050A2F;
}

.iconHelpAccount{
  background: #050A2F;
  mask: url('../Images/Icons/IconHelp.svg');
}

.iconLogout{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconLogout.svg');
}

.iconLogout:hover{
  background: #050A2F;
}

.iconLogoutAccount{
  background: #F57C12;
  mask: url('../Images/Icons/IconLogout.svg');
}

/* .iconCards{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconCards.svg');
}

.iconCards:hover{
  background: #050A2F;
}

.iconCardsLight{
  background: #B4B5C0;
  mask: url('../Images/Icons/IconCards.svg');
} */

/* .iconStar{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconStar.svg');
}

.iconStar:hover{
  background: #050A2F;
} */


/* Objects Icons */
.iconAgileBoard{
  background: #F57C12;
  mask: url('../Images/Icons/IconAgileBoard.svg');
}

.iconAgileBoardBlue{
  background: #0088C7;
  mask: url('../Images/Icons/IconAgileBoard.svg');
}

.iconAgileBoardWhite{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconAgileBoard.svg');
}

.iconSprint{
  background: #F57C12;
  mask: url('../Images/Icons/IconSprint.svg');
}

.iconSprintBlue{
  background: #0088C7;
  mask: url('../Images/Icons/IconSprint.svg');
}

.iconSprintWhite{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconSprint.svg');
}

.iconBusinessLine{
  background: #0088C7;
  mask: url('../Images/Icons/IconBusinessLine.svg');
}

.iconBusinessLineWhite{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconBusinessLine.svg');
}

.iconBusinessLineDark{
  background: #050A2F;
  mask: url('../Images/Icons/IconBusinessLine.svg');
}

.iconProject{
  background: #0088C7;
  mask: url('../Images/Icons/IconProject.svg');
}

.iconProjectWhite{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconProject.svg');
}

.iconProjectDark{
  background: #050A2F;
  mask: url('../Images/Icons/IconProject.svg');
}

.iconProjectGrey{
  background: #8E8E90;
  mask: url('../Images/Icons/IconProject.svg');
}

.iconWorkpackage{
  background: #0088C7;
  mask: url('../Images/Icons/IconWorkpackage.svg');
}

.iconWorkpackageWhite{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconWorkpackage.svg');
}

.iconWorkpackageDark{
  background: #050A2F;
  mask: url('../Images/Icons/IconWorkpackage.svg');
}

.iconAction{
  background: #0088C7;
  mask: url('../Images/Icons/IconAction.svg');
}

.iconActionWhite{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconAction.svg');
}

.iconActionDark{
  background: #050A2F;
  mask: url('../Images/Icons/IconAction.svg');
}

.iconTask{
  background: #0088C7;
  mask: url('../Images/Icons/IconTask.svg');
}

.iconTaskWhite{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconTask.svg');
}

.iconTaskDark{
  background: #050A2F;
  mask: url('../Images/Icons/IconTask.svg');
}

.iconTaskGrey{
  background: #777777;
  mask: url('../Images/Icons/IconTask.svg');
}

.iconEntity{
  background: #26A599;
  mask: url('../Images/Icons/IconEntity.svg');
}

.iconEntityBlue{
  background: #0088C7;
  mask: url('../Images/Icons/IconEntity.svg');
}

.iconEntityWhite{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconEntity.svg');
}

.iconEntityDark{
  background: #050A2F;
  mask: url('../Images/Icons/IconEntity.svg');
}

.iconResource{
  background: #0088C7;
  mask: url('../Images/Icons/IconResource.svg');
}

.iconResourceWhite{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconResource.svg');
}

.iconResourceDark{
  background: #050A2F;
  mask: url('../Images/Icons/IconResource.svg');
}

.iconBudgetCode{
  background: #0088C7;
  mask: url('../Images/Icons/IconBudgetCode.svg');
}

.iconBudgetCodeDark{
  background: #050A2F;
  mask: url('../Images/Icons/IconBudgetCode.svg');
}

.iconBudgetCodeGrey{
  background: #777777;
  mask: url('../Images/Icons/IconBudgetCode.svg');
}

.iconBudgetCodeLight{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconBudgetCode.svg');
}

.iconLink{
  background: #0088C7;
  mask: url('../Images/Icons/IconLink.svg');
}

.iconAxe{
  background: #8E8E90;
  mask: url('../Images/Icons/IconFilterStatus.svg');
}

.iconMeeting{
  background: #0088C7;
  mask: url('../Images/Icons/IconMeetings.svg');
}

.iconMeetingLight{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconMeetings.svg');
}

.iconMeetingDark{
  background: #050A2F;
  mask: url('../Images/Icons/IconMeetings.svg');
}

.iconAxeLight{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconFilterStatus.svg');
}

.iconUser{
  background: #0088C7;
  mask: url('../Images/Icons/IconUser.svg');
}

.iconUserLight{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconUser.svg');
}

.iconUserDark{
  background: #050A2F;
  mask: url('../Images/Icons/IconUser.svg');
}

.iconUserTurquoise{
  background: #26A599;
  mask: url('../Images/Icons/IconUser.svg');
}

.iconUserGroup{
  background: #26A599;
  mask: url('../Images/Icons/IconUserGroup.svg');
}

.iconTeam{
  background: #0088C7;
  mask: url('../Images/Icons/IconTeam.svg');
}

.iconTeamLight{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconTeam.svg');
}

.iconTeamDark{
  background: #050A2F;
  mask: url('../Images/Icons/IconTeam.svg');
}

.iconTree{
  background: #0088C7;
  mask: url('../Images/Icons/IconTree.svg');
}


/* Block Icons */
.iconBudget{
  background: #0088C7;
  /* opacity: 0.6; */
  mask: url('../Images/Icons/IconBudgetCode.svg');
}

.iconBurned{
  background: #0088C7;
  /* opacity: 0.6; */
  mask: url('../Images/Icons/IconBurned.svg');
}

.iconCommunication{
  background: #0088C7;
  /* opacity: 0.6; */
  mask: url('../Images/Icons/IconCommunication.svg');
}

.iconDecisions{
  background: #0088C7;
  /* opacity: 0.6; */
  mask: url('../Images/Icons/IconDecisions.svg');
}

.iconDecisionsWhite{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconDecisions.svg');
}

.iconDecisionsGrey{
  background: #777777;
  mask: url('../Images/Icons/IconDecisions.svg');
}

.iconDependencies{
  background: #0088C7;
  /* opacity: 0.6; */
  mask: url('../Images/Icons/IconDependencies.svg');
}

.iconDetails{
  background: #0088C7;
  /* opacity: 0.6; */
  mask: url('../Images/Icons/IconDetails.svg');
}

.iconEarnings{
  background: #0088C7;
  /* opacity: 0.6; */
  mask: url('../Images/Icons/IconEarnings.svg');
}

.iconIssues{
  background: #0088C7;
  /* opacity: 0.6; */
  mask: url('../Images/Icons/IconIssues.svg');
}

.iconIssuesWhite{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconIssues.svg');
}

.iconIssuesGrey{
  background: #777777;
  mask: url('../Images/Icons/IconIssues.svg');
}

.iconHolidays{
  background: #26A599;
  mask: url('../Images/Icons/IconHolidays.svg');
}

.iconHolidaysBlue{
  background: #0088C7;
  /* opacity: 0.6; */
  mask: url('../Images/Icons/IconHolidays.svg');
}

.iconLinks{
  background: #0088C7;
  /* opacity: 0.6; */
  mask: url('../Images/Icons/IconLinks.svg');
}

.iconMeetings{
  background: #0088C7;
  /* opacity: 0.6; */
  mask: url('../Images/Icons/IconMeetings.svg');
}

.iconMeetingsWhite{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconMeetings.svg');
}

.iconMeetingsGrey{
  background: #777777;
  mask: url('../Images/Icons/IconMeetings.svg');
}

.iconQualitativeData{
  background: #0088C7;
  /* opacity: 0.6; */
  mask: url('../Images/Icons/IconQualitativeData.svg');
}

.iconResourceBlueLight{
  background: #0088C7;
  /* opacity: 0.6; */
  mask: url('../Images/Icons/IconResource.svg');
}

.iconRights{
  background: #0088C7;
  /* opacity: 0.6; */
  mask: url('../Images/Icons/IconRights.svg');
}

.iconRisks{
  background: #0088C7;
  /* opacity: 0.6; */
  mask: url('../Images/Icons/IconWarning.svg');
}

.iconRisksWhite{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconWarning.svg');
}

.iconRisksGrey{
  background: #777777;
  mask: url('../Images/Icons/IconWarning.svg');
}

.iconRoadMap{
  background: #0088C7;
  /* opacity: 0.6; */
  mask: url('../Images/Icons/IconRoadmap.svg');
}

.iconWarning{
  background: #0088C7;
  /* opacity: 0.6; */
  mask: url('../Images/Icons/IconWarning.svg');
}

.iconWarningWhite{
  background: #FFFFFF;
  /* opacity: 0.6; */
  mask: url('../Images/Icons/IconWarning.svg');
}

.iconWhatsUp{
  background: #0088C7;
  /* opacity: 0.6; */
  mask: url('../Images/Icons/IconWhatsUp.svg');
}

.iconWorkload{
  background: #0088C7;
  /* opacity: 0.6; */
  mask: url('../Images/Icons/IconWorkload.svg');
}

/* View Icons */
.iconViewTypeTable{
  background: #0088C7;
  mask: url('../Images/Icons/IconViewTypeTable.svg');
}

.iconViewTypeTableGrey{
  background: #777777;
  mask: url('../Images/Icons/IconViewTypeTable.svg');
}

.iconViewTypePlanning{
  background: #0088C7;
  mask: url('../Images/Icons/IconViewTypePlanning.svg');
}

.iconViewTypePlanningGrey{
  background: #777777;
  mask: url('../Images/Icons/IconViewTypePlanning.svg');
}

.iconViewTypeKanban{
  background: #0088C7;
  mask: url('../Images/Icons/IconViewTypeKanban.svg');
}

.iconViewTypeKanbanGrey{
  background: #777777;
  mask: url('../Images/Icons/IconViewTypeKanban.svg');
}

.iconViewTypePivot{
  background: #0088C7;
  mask: url('../Images/Icons/IconViewTypePivot.svg');
}

.iconViewTypePivotGrey{
  background: #777777;
  mask: url('../Images/Icons/IconViewTypePivot.svg');
}

.iconViewTypeChart{
  background: #0088C7;
  mask: url('../Images/Icons/IconViewTypeChart.svg');
}

.iconViewTypeChartGrey{
  background: #777777;
  mask: url('../Images/Icons/IconViewTypeChart.svg');
}

.iconViewTypeMap{
  background: #0088C7;
  mask: url('../Images/Icons/IconMap.svg');
}

.iconViewTypeMapGrey{
  background: #777777;
  mask: url('../Images/Icons/IconMap.svg');
}

.iconViewTypeList{
  background: #0088C7;
  mask: url('../Images/Icons/IconViewTypeList.svg');
}

.iconViewTypeListGrey{
  background: #777777;
  mask: url('../Images/Icons/IconViewTypeList.svg');
}

.iconViewTypeScheduler{
  background: #0088C7;
  mask: url('../Images/Icons/IconViewTypeScheduler.svg');
}

.iconViewTypeSchedulerGrey{
  background: #777777;
  mask: url('../Images/Icons/IconViewTypeScheduler.svg');
}

.iconViewTypeDashboard{
  background: #0088C7;
  mask: url('../Images/Icons/IconDashboard.svg');
}

.iconViewTypeDashboardGrey{
  background: #777777;
  mask: url('../Images/Icons/IconDashboard.svg');
}

.iconViewTypeDashboardBlack{
  background: #000000;
  mask: url('../Images/Icons/IconDashboard.svg');
}

/* Icons Filters Operator */
.iconFilterOperatorEqual{
  background: #777777;
  mask: url('../Images/Icons/IconFilterOperatorEqual.svg');
}

.iconFilterOperatorNotEqual{
  background: #777777;
  mask: url('../Images/Icons/IconFilterOperatorNotEqual.svg');
}

.iconFilterOperatorGreaterThan{
  background: #777777;
  mask: url('../Images/Icons/IconFilterOperatorGreaterThan.svg');
}

.iconFilterOperatorGreaterOrEqualThan{
  background: #777777;
  mask: url('../Images/Icons/IconFilterOperatorGreaterOrEqualThan.svg');
}

.iconFilterOperatorLessThan{
  background: #777777;
  mask: url('../Images/Icons/IconFilterOperatorLessThan.svg');
}

.iconFilterOperatorLessOrEqualThan{
  background: #777777;
  mask: url('../Images/Icons/IconFilterOperatorLessOrEqualThan.svg');
}

.iconFilterOperatorCustom{
  background: #777777;
  mask: url('../Images/Icons/IconFilterOperatorCustom.svg');
}

.iconFilterOperatorContains{
  background: #777777;
  mask: url('../Images/Icons/IconFilterOperatorContains.svg');
}

.iconFilterOperatorNotContains{
  background: #777777;
  mask: url('../Images/Icons/IconFilterOperatorNotContains.svg');
}

.iconFilterOperatorStartWith{
  background: #777777;
  mask: url('../Images/Icons/IconFilterOperatorStartWith.svg');
}

.iconFilterOperatorEndWith{
  background: #777777;
  mask: url('../Images/Icons/IconFilterOperatorEndWith.svg');
}

.iconFilterOperatorNull{
  background: #777777;
  mask: url('../Images/Icons/IconFilterOperatorNull.svg');
}

.iconFilterOperatorNotNull{
  background: #777777;
  mask: url('../Images/Icons/IconFilterOperatorNotNull.svg');
}

.iconFilterOperatorBetween{
  background: #777777;
  mask: url('../Images/Icons/IconFilterOperatorBetween.svg');
}

/* Icon Font Size Setting */
.iconFontSizeSetting{
  background: #000000;
  mask: url('../Images/Icons/IconFontSizeSetting.svg');
}

.iconFontSize1{
  background: #000000;
  mask: url('../Images/Icons/IconFontSize1.svg');
}

.iconFontSize2{
  background: #000000;
  mask: url('../Images/Icons/IconFontSize2.svg');
}

.iconFontSize3{
  background: #000000;
  mask: url('../Images/Icons/IconFontSize3.svg');
}

.iconFontSize4{
  background: #000000;
  mask: url('../Images/Icons/IconFontSize4.svg');
}

.iconFontSize5{
  background: #000000;
  mask: url('../Images/Icons/IconFontSize5.svg');
}

/* Icons Slides */
.iconSlideTitle{
  background: #0088C7;
  mask: url('../Images/Icons/IconSlideTitle.svg');
}

.iconSlideTitleDark{
  background: #050A2F;
  mask: url('../Images/Icons/IconSlideTitle.svg');
}

.iconGlobal{
  background: #050A2F;
  mask: url('../Images/Icons/IconEnvironment.svg');
}

.iconArrayFields{
  background: #050A2F;
  mask: url('../Images/Icons/IconViewTypeTable.svg');
}

.iconArrayFieldsGrey{
  background: #8E8E90;
  mask: url('../Images/Icons/IconViewTypeTable.svg');
}

.iconIndicators{
  background: #050A2F;
  mask: url('../Images/Icons/IconViewTypePivot.svg');
}

.iconIndicatorsGrey{
  background: #8E8E90;
  mask: url('../Images/Icons/IconViewTypePivot.svg');
}

.iconSort{
  background: #050A2F;
  mask: url('../Images/Icons/IconSort.svg');
}

.iconSortBlue{
  background: #0088C7;
  mask: url('../Images/Icons/IconSort.svg');
}

.iconSortGrey{
  background: #8E8E90;
  mask: url('../Images/Icons/IconSort.svg');
}

.iconSortAscending{
  background: #000000;
  mask: url('../Images/Icons/IconSortAscending.svg');
}

.iconSortAscendingGrey{
  background: #777777;
  mask: url('../Images/Icons/IconSortAscending.svg');
}

.iconSortDescending{
  background: #000000;
  mask: url('../Images/Icons/IconSortDescending.svg');
}

.iconSortDescendingGrey{
  background: #777777;
  mask: url('../Images/Icons/IconSortDescending.svg');
}

.iconColumnFilter{
  background: #000000;
  mask: url('../Images/Icons/IconFilter.svg');
}

.iconColumnFilterGrey{
  background: #777777;
  mask: url('../Images/Icons/IconFilter.svg');
}

.iconColumnActions{
  background: #000000;
  mask: url('../Images/Icons/IconColumnActions.svg');
}

.iconColumnActionsGrey{
  background: #777777;
  mask: url('../Images/Icons/IconColumnActions.svg');
}

.iconFilters{
  background: #050A2F;
  mask: url('../Images/Icons/IconFilter.svg');
}

.iconFiltersBlue{
  background: #0088C7;
  mask: url('../Images/Icons/IconFilter.svg');
}

.iconFiltersGrey{
  background: #8E8E90;
  mask: url('../Images/Icons/IconFilter.svg');
}

.iconConditionalFormatting{
  background: #050A2F;
  mask: url('../Images/Icons/IconConditionalFormatting.svg');
}

.iconConditionalFormattingBlue{
  background: #0088C7;
  mask: url('../Images/Icons/IconConditionalFormatting.svg');
}

.iconConditionalFormattingGrey{
  background: #8E8E90;
  mask: url('../Images/Icons/IconConditionalFormatting.svg');
}

.iconAutofit{
  background: #050A2F;
  mask: url('../Images/Icons/IconAutofit.svg');
}

.iconAutofitGrey{
  background: #8E8E90;
  mask: url('../Images/Icons/IconAutofit.svg');
}

.iconDateRange{
  background: #050A2F;
  mask: url('../Images/Icons/IconDateRange.svg');
}

.iconDateRangeGrey{
  background: #8E8E90;
  mask: url('../Images/Icons/IconDateRange.svg');
}

.iconAxeSlide{
  background: #050A2F;
  mask: url('../Images/Icons/IconFilterStatus.svg');
}

.iconAxeSlideGrey{
  background: #8E8E90;
  mask: url('../Images/Icons/IconFilterStatus.svg');
}

.iconAxeBlue{
  background: #0088C7;
  mask: url('../Images/Icons/IconFilterStatus.svg');
}

.iconAxeDark{
  background: #050A2F;
  mask: url('../Images/Icons/IconFilterStatus.svg');
}

.iconChartType{
  background: #050A2F;
  mask: url('../Images/Icons/IconChartType.svg');
}

.iconChartRow{
  background: #050A2F;
  mask: url('../Images/Icons/IconChartRow.svg');
}

.iconChartColumn{
  background: #050A2F;
  mask: url('../Images/Icons/IconChartColumn.svg');
}

.iconChartIndicators{
  background: #050A2F;
  mask: url('../Images/Icons/IconChartIndicators.svg');
}

.iconChartLegend{
  background: #050A2F;
  mask: url('../Images/Icons/IconChartLegend.svg');
}

.iconChartLabel{
  background: #050A2F;
  mask: url('../Images/Icons/IconChartLabel.svg');
}

.iconParameters{
  background: #050A2F;
  mask: url('../Images/Icons/IconParameters.svg');
}

.iconParametersBlue{
  background: #0088C7;
  mask: url('../Images/Icons/IconParameters.svg');
}

.iconParametersGrey{
  background: #8E8E90;
  mask: url('../Images/Icons/IconParameters.svg');
}

.iconAudit{
  background: #050A2F;
  mask: url('../Images/Icons/IconAudit.svg');
}

.iconViewsActions{
  background: #000000;
  mask: url('../Images/Icons/IconColumnActions.svg');
}

/* Icons */
.iconOkta{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconOkta.svg');
}

.iconMonitor{
  background: #0088C7;
  mask: url('../Images/Icons/IconMonitor.svg');
}

.iconActivityLight{
  background: #B4B5C0;
  mask: url('../Images/Icons/IconActivity.svg');
}

.iconRightsOrangeLight{
  background: #FABD05;
  opacity: 0.6;
  margin-right: 10px;
  mask: url('../Images/Icons/IconRights.svg');
}

.iconTimeTrackingBlue{
  background: #0088C7;
  mask: url('../Images/Icons/IconTimeTracking.svg');
}

.iconTimeTrackingBlueLight{
  background: #0088C7;
  opacity: 0.6;
  mask: url('../Images/Icons/IconTimeTracking.svg');
}

.iconTimeTrackingLight{
  background: #B4B5C0;
  mask: url('../Images/Icons/IconTimeTracking.svg');
}

.iconTimeTrackingYellow{
  background: #FABD05;
  mask: url('../Images/Icons/IconTimeTracking.svg');
}

.iconCalendar{
  background: #0088C7;
  mask: url('../Images/Icons/IconCalendar.svg');
}

.iconCalendarDarkBlue{
  background: #050A2F;
  mask: url('../Images/Icons/IconCalendar.svg');
}

.iconDepencyPathDarkBlue {
    background: #050A2F;
    mask: url('../Images/Icons/DependencyPath.svg');
}

.iconCalendarGrey{
  background: #777777;
  mask: url('../Images/Icons/IconCalendar.svg');
}

.iconCalendarYellow{
  background: #FABD05;
  mask: url('../Images/Icons/IconCalendar.svg');
}

.iconColumns{
  background: #0088C7;
  mask: url('../Images/Icons/IconColumns.svg');
}

.iconWarningWhite{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconWarning.svg');
  margin-top: -3px;
  margin-left: 5px;
  margin-right: 5px;
}

.iconWarningRed{
  background: #E21313;
  mask: url('../Images/Icons/IconWarning.svg');
  margin-top: -3px;
  margin-left: 5px;
  margin-right: 5px;
}

.iconWarningGrey{
  background: #777777;
  mask: url('../Images/Icons/IconWarning.svg');
  margin-top: -3px;
  margin-left: 5px;
  margin-right: 5px;
}

.iconControles{
  background: #0088C7;
  opacity: 0.6;
  mask: url('../Images/Icons/IconControles.svg');
}

.iconPerf{
  background: #0088C7;
  opacity: 0.6;
  mask: url('../Images/Icons/IconPerf.svg');
}

.iconPlanning{
  background: #0088C7;
  opacity: 0.6;
  mask: url('../Images/Icons/IconPlanning.svg');
}

.iconReports{
  background: #0088C7;
  mask: url('../Images/Icons/IconReports.svg');
  opacity: 0.6;
}

.iconCalculator{
  background: #8E8E90;
  mask: url('../Images/Icons/IconCalculator.svg');
}

.iconCopy{
  background: #0088C7;
  mask: url('../Images/Icons/IconCopy.svg');
}

.iconDelete{
  background: #E21313;
  mask: url('../Images/Icons/IconDelete.svg');
}

.iconQRCode{
  background: #0088C7;
  mask: url('../Images/Icons/IconQRCode.svg');
}

.iconOpenFile{
  background: #0088C7;
  mask: url('../Images/Icons/IconOpenFile.svg');
}

.iconNoRights{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconLock.svg');
}

.iconLock{
  background: #0088C7;
  mask: url('../Images/Icons/IconLock.svg');
}

.iconHelpWhite{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconHelp.svg');
}

.iconIdentity{
  background: #0088C7;
  mask: url('../Images/Icons/IconCardID.svg');
}

.iconMail{
  background: #0088C7;
  mask: url('../Images/Icons/IconMail.svg');
}

.iconHourglass{
  background: #0088C7;
  mask: url('../Images/Icons/IconHourglass.svg');
}

.iconExternal{
  background: #0088C7;
  mask: url('../Images/Icons/IconExternal.svg');
}

.iconInternal{
  background: #0088C7;
  mask: url('../Images/Icons/IconInternal.svg');
}

.iconHighlighted{
  background: #F57C12;
  mask: url('../Images/Icons/IconHighlighted.svg');
}

.iconNotHighlighted{
  background: #0088C7;
  mask: url('../Images/Icons/IconNotHighlighted.svg');
}

.iconFile{
  background: #283C82;
  mask: url('../Images/Icons/IconFile.svg');
}

.iconExcel{
  background: #00C77A;
  mask: url('../Images/Icons/IconExcel.svg');
}

.iconPDF{
  background: #E21313;
  mask: url('../Images/Icons/IconPDF.svg');
}

.iconPPT{
  background: #E21313;
  mask: url('../Images/Icons/IconPPT.svg');
}

.iconWord{
  background: #283C82;
  mask: url('../Images/Icons/IconWord.svg');
}

.iconPPTLight{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconPPT.svg');
}

.iconPlus{
  background: #0088C7;
  mask: url('../Images/Icons/IconPlus.svg');
}

.iconPlusGrey{
  background: #777777;
  mask: url('../Images/Icons/IconPlus.svg');
}

.iconMinusGrey{
  background: #777777;
  mask: url('../Images/Icons/IconMinus.svg');
}

.iconButtonPlus{
  background: #0088C7;
  mask: url('../Images/Icons/IconButtonPlus.svg');
}

.iconButtonPlusGreen{
  background: #00C77A;
  mask: url('../Images/Icons/IconButtonPlus.svg');
}

.iconDisplayViews{
  background: #0088C7;
  mask: url('../Images/Icons/IconDisplayViews.svg');
}

.iconHideViews{
  background: #0088C7;
  mask: url('../Images/Icons/IconHideViews.svg');
}

.iconRemove{
  background: #FABD05;
  mask: url('../Images/Icons/IconRemove.svg');
}

.iconDownload{
  /* position: fixed; */
  background: #0088C7;
  mask: url('../Images/Icons/IconDownload.svg');
}

.iconSave{
  background: #8E8E90;
  mask: url('../Images/Icons/IconSave.svg');
}

.iconDuplicate{
  background: #8E8E90;
  mask: url('../Images/Icons/IconDuplicate.svg');
}

.iconSettings{
  background: #050A2F;
  mask: url('../Images/Icons/IconSettings.svg');
}

.iconShield{
  background: #0088C7;
  mask: url('../Images/Icons/IconShield.svg');
}

.iconShieldGreen{
  background: #00C77A;
  mask: url('../Images/Icons/IconShield.svg');
}

.iconShieldGrey{
  background: #8E8E90;
  mask: url('../Images/Icons/IconShield.svg');
}

.iconActiveTrue{
  background: #00C77A;
  mask: url('../Images/Icons/IconActive.svg');
}

.iconActiveFalse{
  background: #E21313;
  mask: url('../Images/Icons/IconActive.svg');
}

.iconShieldCheck{
  mask: url('../Images/Icons/IconShieldCheck.svg');
  mask-size: cover;
}

.iconArrowUp{
  background: #000000;
  mask: url('../Images/Icons/IconArrowUp.svg');
}

.iconArrowUpGrey{
  background: #777777;
  mask: url('../Images/Icons/IconArrowUp.svg');
}

.iconArrowDown{
  background: #000000;
  mask: url('../Images/Icons/IconArrowDown.svg');
}

.iconArrowDownGrey{
  background: #777777;
  mask: url('../Images/Icons/IconArrowDown.svg');
}

.iconArrowRight{
  background: #777777;
  mask: url('../Images/Icons/IconArrowRight.svg');
}

.iconArrowRightBlue{
  background: #0088C7;
  mask: url('../Images/Icons/IconArrowRight.svg');
}

.iconChevronUp{
  background: #0088C7;
  /* opacity: 0.6; */
  mask: url('../Images/Icons/IconChevronUp.svg');
}

.iconChevronUpYellow{
  background: #FABD05;
  mask: url('../Images/Icons/IconChevronUp.svg');
}

.iconChevronDown{
  background: #0088C7;
  /* opacity: 0.6; */
  mask: url('../Images/Icons/IconChevronDown.svg');
}

.iconChevronDownGrey{
  background: #8E8E90;
  mask: url('../Images/Icons/IconChevronDown.svg');
}

.iconChevronDownGreen{
  background: #5FAD74;
  mask: url('../Images/Icons/IconChevronDown.svg');
}

.iconChevronDownRed{
  background: #E21313;
  mask: url('../Images/Icons/IconChevronDown.svg');
}

.iconChevronDownYellow{
  background: #FABD05;
  mask: url('../Images/Icons/IconChevronDown.svg');
}

.iconChevronDownWhite{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconChevronDown.svg');
}

.iconChevronRight{
  background: #0088C7;
  mask: url('../Images/Icons/IconChevronRight.svg');
}

.iconChevronLeft{
  background: #0088C7;
  mask: url('../Images/Icons/IconChevronLeft.svg');
}

.iconNext{
  background: #8E8E90;
  mask: url('../Images/Icons/IconNext.svg');
}

.iconNextYellow{
  background: #FABD05;
  mask: url('../Images/Icons/IconNext.svg');
}

.iconPrevious{
  background: #8E8E90;
  mask: url('../Images/Icons/IconPrevious.svg');
}

.iconPreviousYellow{
  background: #FABD05;
  mask: url('../Images/Icons/IconPrevious.svg');
}

.iconTrendUp{
  background: #00C77A;
  /* opacity: 0.6; */
  mask: url('../Images/Icons/IconTrendUp.svg');
}

.iconTrendDown{
  background: #E21313;
  /* opacity: 0.6; */
  mask: url('../Images/Icons/IconTrendDown.svg');
}

.iconTrendRight{
  background: #8E8E90;
  mask: url('../Images/Icons/IconTrendRight.svg');
}

.iconSun{
  background: #FABD05;
  mask: url('../Images/Icons/IconSun.svg');
}

.iconCloud{
  background: #8E8E90;
  mask: url('../Images/Icons/IconCloud.svg');
}

.iconRain{
  background: #0088C7;
  mask: url('../Images/Icons/IconRain.svg');
}

.iconThunder{
  background: #050A2F;
  mask: url('../Images/Icons/IconThunder.svg');
}

.iconMoon{
  background: #FABD05;
  mask: url('../Images/Icons/IconMoon.svg');
}

.iconEmpty{
  background: #8E8E90;
  mask: url('../Images/Icons/IconEmpty.svg');
}

.iconRating{
  background: #FABD05;
  mask: url('../Images/Icons/IconStarYellow.svg');
}

.iconRatingEmpty{
  background: #8E8E90;
  mask: url('../Images/Icons/IconStar.svg');
}

.iconStarDarkBlue{
  background: #050A2F;
  mask: url('../Images/Icons/IconStar.svg');
}

.iconStarGrey{
  background: #8E8E90;
  mask: url('../Images/Icons/IconStar.svg');
}

.iconStarYellow{
  background: #FABD05;
  mask: url('../Images/Icons/IconStarYellow.svg');
}

.iconFilterKanban{
  background: #0088C7;
  mask: url('../Images/Icons/IconFilterKanban.svg');
}

.iconFilterStatus{
  background: #0088C7;
  mask: url('../Images/Icons/IconFilterStatus.svg');
}

.iconExport{
  background: #0088C7;
  mask: url('../Images/Icons/IconExport.svg');
}

.iconImport{
  background: #0088C7;
  mask: url('../Images/Icons/IconImport.svg');
}

.iconClear{
  background: #8E8E90;
  mask: url('../Images/Icons/IconClear.svg');
}

.iconClearRed{
  background: #E21313;
  mask: url('../Images/Icons/IconClear.svg');
}

.iconClearBlue{
  background: #0088C7;
  mask: url('../Images/Icons/IconClear.svg');
}

.iconClearYellow{
  background: #FABD05;
  mask: url('../Images/Icons/IconClear.svg');
}

.iconClose{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconClear.svg');
}

.iconComments{
  background: #0088C7;
  mask: url('../Images/Icons/IconComments.svg');
}

.iconCircle{
  mask: url('../Images/Icons/IconCircleFin.svg');
  /* height: 37px; */
  /* width: 37px; */
}

.iconCircleGrey{
  background: #8E8E90;
  mask: url('../Images/Icons/IconCircleFin.svg');
}

.iconFullCircle{
  mask: url('../Images/Icons/IconFullCircle.svg');
}

.iconFullCircleGreen{
  background: #00C77A;
  mask: url('../Images/Icons/IconFullCircle.svg');
}

.iconFullCircleOrange{
  background: #F57C12;
  mask: url('../Images/Icons/IconFullCircle.svg');
}

.iconFullCircleRed{
  background: #E21313;
  mask: url('../Images/Icons/IconFullCircle.svg');
}

.iconFullCircleDarkRed{
  background: #9A0C0C;
  mask: url('../Images/Icons/IconFullCircle.svg');
}

.iconShowComponent{
  background: #0088C7;
  mask: url('../Images/Icons/IconShowComponent.svg');
}

.iconShowDoughnut{
  background: #0088C7;
  mask: url('../Images/Icons/IconShowBudgetDoughtnut.svg');
}

.iconHideComponent{
  background: #0088C7;
  mask: url('../Images/Icons/IconHideComponent.svg');
}

.iconShowBothComponent{
  background: #0088C7;
  mask: url('../Images/Icons/IconShowBothComponent.svg');
}

.iconShowHeatmap{
  background: #0088C7;
  mask: url('../Images/Icons/iconShowHeatmapComponent.svg');
}

.iconShowGraph{
  background: #0088C7;
  mask: url('../Images/Icons/iconShowGraphComponent.svg');
}

.iconFullCircleBlue{
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0088C7;
  border: 2px solid #0088C7;
  mask: url('../Images/Icons/IconFullCircle.svg');
  height: 30px;
  width: 30px;
  opacity: 0.6;
}

.iconFullCircleBlueDark{
  display: flex;
  align-items: center;
  justify-content: center;
  background: #050A2F;
  border: 2px solid #050A2F;
  mask: url('../Images/Icons/IconFullCircle.svg');
  height: 30px;
  width: 30px;
  opacity: 0.8;
}

.iconFullCircleUser{
  background: #0088C7;
  mask: url('../Images/Icons/IconFullCircle.svg');
  opacity: 0.6;
  text-transform: uppercase;
  height: 60px;
  width: 60px;
}

.iconHalfCircle{
  mask: url('../Images/Icons/IconHalfCircle.svg');
}

.iconHollowCircle{
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #0088C7;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  opacity: 0.4;
}

.iconGrayCircle{
  background-color: #E8E8E8;
  border-radius: 50%;
  opacity: 0.6;
  height: 80px;
  width: 80px;
}

.iconResourceTable{
  display: flex !important;
  align-items: center;
  justify-content: center;
  background: #0088C7;
  border: 2px solid #0088C7;
  mask: url('../Images/Icons/IconFullCircle.svg');
  height: 26px;
  width: 26px;
  opacity: 0.6;
}

.iconEmptyResourceTable{
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: 1px dashed #0088C7;
  border-radius: 50%;
  height: 26px;
  width: 26px;
  opacity: 0.4;
}

.iconDependencyEndToStart{
  background: #8E8E90;
  mask: url('../Images/Icons/IconDependencyEndToStart.svg');
}

.iconDependencyEndToEnd{
  background: #8E8E90;
  mask: url('../Images/Icons/IconDependencyEndToEnd.svg');
}

.iconDependencyStartToStart{
  background: #8E8E90;
  mask: url('../Images/Icons/IconDependencyStartToStart.svg');
}

.iconDependencyMirror{
  background: #8E8E90;
  mask: url('../Images/Icons/IconDependencyMirror.svg');
}

.iconShapeAssigned{
  background: #8E8E90;
  mask: url('../Images/Icons/IconShapeAssigned.svg');
}

.iconShapeBaseline{
  background: #8E8E90;
  mask: url('../Images/Icons/IconShapeBaseline.svg');
}

.iconShapeBudget{
  background: #8E8E90;
  mask: url('../Images/Icons/IconShapeBudget.svg');
}

.iconShapeBurned{
  background: #0088C7;
  mask: url('../Images/Icons/IconShapeBurned.svg');
}

.iconShapeCancelled{
  background: #F57C12;
  mask: url('../Images/Icons/IconShapeCancelled.svg');
}

.iconShapeCompleted{
  background: #00C77A;
  mask: url('../Images/Icons/IconShapeCompleted.svg');
}

.iconShapeEnclosed{
  background: #0088C7;
  mask: url('../Images/Icons/IconShapeEnclosed.svg');
}

.iconShapeInformation{
  background: #8E8E90;
  mask: url('../Images/Icons/IconShapeInformation.svg');
}

.iconShapeMovedForward{
  background: #0088C7;
  mask: url('../Images/Icons/IconShapeMovedForward.svg');
}

.iconShapeNewTask{
  background: #0088C7;
  mask: url('../Images/Icons/IconShapeNewTask.svg');
}

.iconShapeNewResource{
  background: #26A599;
  mask: url('../Images/Icons/IconShapeNewResource.svg');
}

.iconShapePerformance{
  background: #0088C7;
  mask: url('../Images/Icons/IconShapePerformance.svg');
}

.iconShapePostponed{
  background: #0088C7;
  mask: url('../Images/Icons/IconShapePostponed.svg');
}

.iconShapeQualitative{
  background: #0088C7;
  mask: url('../Images/Icons/IconShapeQualitative.svg');
}

.iconShapeRelease{
  background: #0088C7;
  mask: url('../Images/Icons/IconShapeRelease.svg');
}

.iconShapeScheduled{
  background: #8E8E90;
  mask: url('../Images/Icons/IconShapeScheduled.svg');
}

.iconCheck{
  background: #00C77A;
  mask: url('../Images/Icons/IconCheck.svg');
}

.iconPassKey{
  background: #000000;
  mask: url('../Images/Icons/IconPasskey.svg');
}

.iconRename{
  background: #8E8E90;
  mask: url('../Images/Icons/IconRename.svg');
}

.iconShare{
  background: #8E8E90;
  mask: url('../Images/Icons/IconShare.svg');
}

.iconPin{
  background: #8E8E90;
  mask: url('../Images/Icons/IconPin.svg');
}

.iconPinWhite{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconPin.svg');
}

.iconUnpin{
  background: #8E8E90;
  mask: url('../Images/Icons/IconUnpin.svg');
}

.iconBell{
  background: #0088C7;
  mask: url('../Images/Icons/IconBell.svg');
}

.iconBellLight{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconBell.svg');
}

.iconEdit{
  background: #8E8E90;
  mask: url('../Images/Icons/IconEdit.svg');
}

.iconMultipleModification{
  background: #8E8E90;
  mask: url('../Images/Icons/IconMultipleModification.svg');
}

.iconInfo{
  background: #0088C7;
  mask: url('../Images/Icons/IconInfo.svg');
}

.iconEye{
  background: #0088C7;
  mask: url('../Images/Icons/IconEye.svg');
}

.iconEyeGrey{
  background: #8E8E90;
  mask: url('../Images/Icons/IconEye.svg');
}

.iconMute{
  background: #0088C7;
  mask: url('../Images/Icons/IconMute.svg');
  opacity: 0.6;
}

.iconSend{
  background: #FFFFFF;
  mask: url('../Images/Icons/IconSend.svg');
}

.iconThreeDots{
  background: #8E8E90;
  mask: url('../Images/Icons/IconThreeDots.svg');
}

.iconHome{
  background: #0088C7;
  mask: url('../Images/Icons/IconHome.svg');
}

.check:after{
  content: url('../Images/Icons/IconCheck.svg');
  width: 30px;
  height: 35px;
  display: block;
}

/* ---------- Header ---------- */
.header{
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigationBar{
  height: 100vh;
  width: 55px;
  position: fixed;
  display: grid;
  align-content: space-between;
  z-index: 2;
}

.navigationBlocks{
  display: flex;
  /* height: 24px; */
  margin-top: 6px;
}

.navigationActive{
  display: flex;
  height: 23px;
  margin-top: 6px;
}

.navigationItem{
  font-size: 12px;
  margin-left: 10px;
  margin-right: 5px;
  /* padding-bottom: 7px !important; */
}

.navigationItemSelected{
  font-size: 12px;
  color: #000000 !important;
  border-bottom: 5px solid #000000;
  margin-left: 10px;
  margin-right: 5px;
  padding-bottom: 2px !important;
}

.navigationLink, .navigationLink:hover{
  color: #B1B2BE;
  font-weight: bold;
  text-decoration: none;
}

.navigationLinkSelected, .navigationLinkSelected:hover{
  color: #000000;
  font-weight: bold;
  text-decoration: none;
}

.indicatorsRights{
  display: flex;
  align-items: center;
  /* margin-right: 15px; */
  padding-right: 15px;
}

.rights{
  z-index: auto;
}

.qrCodePopup{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1001;
  top: 0;
  left: 0;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0, 0, 0, 0.3);
}

.qrCodeInnerPopup{
  height: auto;
  width: auto;
  background-color: rgb(255, 255, 255);
  border: 1px solid #888;
  border-radius: 12px;
  margin: auto;
  /* margin-top: 10%; */
  padding: 20px;
}

.qrCodeLabel{
  display: flex;
  align-items: center;
  color: #0088C7;
  font-size: 16px;
  font-weight: bold;
}

.qrCodeCopy{
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.qrCode{
  display: flex;
  height: 100%;
  max-height: 500px;
  width: 100%;
  max-width: 500px;
  margin: auto;
}

.loginLogo{
  max-height: 100%;
  max-width: 80%;
  /* margin-left: 10%; */
  /* height: 50px; */
  /* width: 250px; */
}

.miniLogoTabsters{
  height: 14px;
  width: 84px;
}

.poweredByTabsters{
  align-items: flex-end;
  color: #0088C7;
  font-size: 12px;
  font-weight: bold;
  margin-right: 10px;
}

.navbarLogo{
  height: 30px;
  width: 30px;
}

.linkNav{
  align-items: center;
}

.linkNav:hover{
  color: #050A2F;
}

.linkNav:hover > .nav.icon{
  background: #050A2F;
}

.linkAccount{
  display: flex;
  align-items: center;
}


/* ---------- Links ---------- */
.link{
  color: #0088C7;
  display: flex;
  align-items: center;
}

.link:hover{
  color:#0088C771;
}

.link:hover > .icon{
  background: #0088C771;
}

.link.icon{
  background: #0088C7;
}

.link.icon:hover, .link.icon:focus{
  background: #0088C771;
}

.linkMiniblock{
  display: flex;
  justify-content: flex-start;
  width: calc(100% - 55px);
}

.linkParents{
  display: flex;
  align-items: center;
  height: 100%;
  color: #000000;
  font-size: 10px;
}

.linkParents:hover{
  color: #000000;
  font-size: 10px;
}


/* ---------- Badges ---------- */
.badge{
  font-size: 12px;
  margin-top: -4px;
  margin-left: 5px;
  padding: 4px 15px 4px 15px;
}

.smallBadge{
  font-size: 12px;
  margin-top: 2px;
  padding: 7px 8px 4px 2px;
}

.bigBadge{
  font-size: 12px;
  padding: 7px 15px 7px 15px;
}

.badgeMiniblocks{
  font-size: 12px;
  margin-left: 0px;
  padding: 6px 14px 6px 14px;
}

.badgeTable{
  font-size: 12px;
  margin-top: 0px;
  margin-left: 0px;
  padding: 7px 2px 4px 2px;
}

.badgeWarning{
  font-size: 12px;
  margin-top: 5px;
  padding: 7px 8px 4px 2px;
  display:flex;
}


/* ---------- Buttons ---------- */
.bordered{
  border: 1px solid;
  border-radius: 13px;
  opacity: 1;
}

.btn-border{
  border: 2px solid;
  border-radius: 13px;
  opacity: 1;
}

.btn-PPT{
  color: #B1B2BE;
  text-align: start;
  font-size: 13px;
  font-weight: bold;
  background-color: #FFFFFF;
  border: none;
  box-shadow: none;
  margin-top: 2px;
  margin-bottom: 2px;
  padding: 1px;
  cursor: pointer;
}

.btn-PPT-selected{
  color: black;
  text-align: start;
  font-size: 13px;
  font-weight: bold;
  background-color: #FFFFFF;
  border: none;
  box-shadow: none;
  margin-top: 2px;
  margin-bottom: 2px;
  padding: 1px;
  cursor: pointer;
}

.btn-border-PPT:hover{
  color: #FFFFFF;
  background-color: #0088C7;
}

.btn-container{
  width: 250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-top: 5px;
  padding-bottom: 10px;
}

.btn-container .btn-view:first-child::after{
  margin-left: 50%;
  width: 50%;
}

.btn-container .btn-selected:first-child::after{
  margin-left: 50%;
  width: 50%;
}

.btn-container .btn-view:last-child::after{
  margin-right: 50%;
  width: 50%;
}

.btn-container .btn-selected:last-child::after{
  margin-right: 50%;
  width: 50%;
}

.btn-level{
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 100px;
}

.btn-view{
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-align: center;
}

.btn-view::after{
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background: #8E8E90;
  display: block;
  bottom: 3px;
}

.btn-view::before{
  content: '';
  display: block;
  z-index: 2;
  width: 7px;
  height: 7px;
  background: #0088C7;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  bottom: 0;
}

.btn-selected{
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-align: center;
}

.btn-selected::after{
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background: #8E8E90;
  display: block;
  bottom: 3px;
}

.btn-selected::before{
  content: '';
  display: block;
  z-index: 2;
  width: 7px;
  height: 7px;
  background: #050A2F;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  bottom: 0;
}

.btn-period{
  height: 26px;
  width: 80px;
  margin: 2px;
}

.btn-week{
  background-color: #FFFFFF !important;
  border: 1px solid #8E8E90 !important;
  color: #8E8E90 !important;
}

.btn-week:active{
  background-color: #8E8E90 !important;
  border: 1px solid #8E8E90 !important;
  color: #FFFFFF !important;
}

.btn-month{
  background-color: #FFFFFF !important;
  border: 1px solid #8E8E90 !important;
  color: #8E8E90 !important;
}

.btn-month:active{
  background-color: #8E8E90 !important;
  border: 1px solid #8E8E90 !important;
  color: #FFFFFF !important;
}

.btn-Communication{
  font-size: 11px;
  font-weight: bold;
  color: #FFFFFF;
  border-radius: 13px;
  margin-top: 3px;
  padding: 3px 6px;
}

.btn-Communication:hover{
  color: #FFFFFF;
}


/* ---------- Popup ---------- */
.popup{
  position: fixed;
  background-color: #000000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.popup_inner{
  position: absolute;
  background: #FFFFFF;
  margin: auto;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
}


/* ---------- Scrollbar ---------- */
.overflow-y{
  overflow-y: scroll;
}

.overflow-y-auto{
  overflow-y: auto;
}

.overflow-x{
  overflow-x: scroll;
}

.overflow-x-auto{
  overflow-x: auto;
}

.overflowscroll{
  overflow: scroll;
}

.overflowauto{
  overflow: auto;
}

.overflowhidden{
  overflow: hidden;
}

.overflowvisble{
  overflow: visible;
}

/* Chrome and safari */
.scrollbar-primary::-webkit-scrollbar{
  height: 6px;
  width: 0px;
  background-color: #FFFFFF;
  border-radius: 10px;
}
  
.scrollbar-primary::-webkit-scrollbar-thumb{
  background-color: #B1B2BE;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

/* Firefox */
@-moz-document url-prefix() {
  .scrollbar-primary{
    /* overflow-y: hidden !important; */
    scrollbar-width: thin;
    border-radius: 10px;
  }
}

/* Chrome and safari */
.scrollbar-x::-webkit-scrollbar{
  height: 6px;
  width: 0px;
  background-color: #FFFFFF;
  border-radius: 10px;
}

.scrollbar-x::-webkit-scrollbar-thumb{
  background-color: #B1B2BE;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

/* Firefox */
@-moz-document url-prefix() {
  .scrollbar-x{
    /* overflow-y: hidden !important; */
    scrollbar-width: thin;
    border-radius: 10px;
  }
}

/* Chrome and safari */
.scrollbar-y::-webkit-scrollbar{
  height: 0px;
  width: 6px;
  background-color: #FFFFFF;
  border-radius: 10px;
}
  
.scrollbar-y::-webkit-scrollbar-thumb{
  background-color: #B1B2BE;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

/* Firefox */
@-moz-document url-prefix() {
  .scrollbar-y{
    /* overflow-x: hidden !important; */
    scrollbar-width: thin;
    border-radius: 10px;
  }
}

/* Chrome and safari */
.scrollbar-miniblock::-webkit-scrollbar{
  height: 0px;
  width: 6px;
  background-color: #FFFFFF;
  border-radius: 10px;
}
  
.scrollbar-miniblock::-webkit-scrollbar-thumb{
  background-color: #B1B2BE;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

/* Firefox */
@-moz-document url-prefix() {
  .scrollbar-miniblock{
    width: 6px;
    /* overflow-x: hidden !important; */
    scrollbar-width: thin;
    border-radius: 10px;
  }
}

/* ---------- Switch ---------- */
.custom-switch .custom-control-label::before{
  left: -2.2rem;
  width: 3.9rem;
  pointer-events: all;
  border-radius: .7rem;
}

.custom-control-label::before{
  position: absolute;
  top: .25rem;
  left: -2.2rem;
  display: block;
  width: 3.9rem;
  height: 1.5rem;
  content: "";
  background-color: #FFFFFF;
  border: #adb5bd solid 1px;
}

.custom-switch .custom-control-label::after{
  top: calc(.3rem + 0.1px);
  left: calc(-2.9rem + 8px);
  width: calc(1.9rem - 7px);
  height: calc(1.9rem - 6px);
  background-color: #ADB5BD;
  border-radius: .6rem;
  transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;
  transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;
}

.custom-switch .custom-control-input:checked~.custom-control-label::after{
  background-color: #FFFFFF;
  -webkit-transform: translateX(.75rem);
  transform: translateX(2.5rem);
}

.custom-control-input:not(:disabled):active~.custom-control-label::before{
  color: #FFFFFF;
  background-color:#C7FCE5;
  border-color: #C7FCE5;
}

.custom-control-input:checked~.custom-control-label::before{
  color: #FFFFFF;
  border-color: #00C77A;
  background-color:#00C77A;
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before{
  background-color: #00C77A;
}


/* ---------- ---------- PAGES ---------- ---------- */
.content{
  width: 100%;
  padding-left: 55px;
  /* width: 96%; */
  /* padding-left: 40px; */
  /* padding-left: 25px; */
  margin: auto;
  letter-spacing: 0;
}

.breadcrumb{
  display: flex;
  font-size: 12px;
  background-color: #FFFFFF;
  margin-bottom: 0px;
  padding: 3px;
}

.pathIcon{
  /* background-color: #0088C7; */
  /* border-radius: 8px; */
  margin-right: 5px;
  /* padding: 5px 5px 8px 5px; */
}

.axe{
  display: inline-flex;
  font-size: 12px;
  font-weight: normal;
  justify-content: center;
  /* min-width: 60px; */
  max-width: max-content;
  padding: 4px 8px 4px 8px;
}

.axisTable{
  display: inline-flex;
  font-size: 12px;
  font-weight: normal;
  justify-content: center;
  max-width: max-content;
  margin-top: 2px;
  margin-bottom: 2px;
  padding: 4px 8px 4px 8px;
}

.hashtag{
  display: inline-flex;
  font-size: 12px;
  font-weight: normal;
  justify-content: center;
  max-width: max-content;
  margin-top: 2px;
  margin-bottom: 2px;
  padding: 4px 8px 4px 8px;
}

.location{
  display: inline-flex;
  font-size: 12px;
  font-weight: normal;
  justify-content: center;
  /* min-width: 60px; */
  max-width: max-content;
}

.multiResource{
  display: inline-flex;
  align-items: center;
  /* align-items: baseline; */
  font-size: 12px;
  max-width: max-content;
}

.detailsMultiResource{
  display: inline-flex;
  align-items: center;
  align-items: baseline;
  font-size: 12px;
  max-width: max-content;
}

.axeTable{
  display: inline-flex;
  font-size: 12px;
  font-weight: normal;
  justify-content: center;
  align-items: center;
  max-width: max-content;
  /* margin-top: 2px; */
  /* margin-bottom: 2px; */
  padding: 4px 8px 4px 8px;
}

.axePlanning{
  display: inline-flex;
  height: 26px;
  font-size: 12px;
  font-weight: normal;
  align-items: center;
  justify-content: center;
  max-width: max-content;
  /* margin-top: 2px; */
  /* margin-bottom: 2px; */
  padding: 4px 8px 4px 8px;
}

.axisTablePlanning{
  display: inline-flex;
  height: 26px;
  font-size: 12px;
  font-weight: normal;
  align-items: center;
  justify-content: center;
  max-width: max-content;
  margin-top: 2px;
  margin-bottom: 2px;
  padding: 4px 8px 4px 8px;
}

.hashtagPlanning{
  display: inline-flex;
  height: 26px;
  font-size: 12px;
  font-weight: normal;
  align-items: center;
  justify-content: center;
  max-width: max-content;
  margin-top: 2px;
  margin-bottom: 2px;
  padding: 4px 8px 4px 8px;
}

.locationPlanning{
  display: inline-flex;
  height: 26px;
  font-size: 12px;
  font-weight: normal;
  align-items: center;
  justify-content: center;
  max-width: max-content;
}

.axeKanban{
  font-size: 12px;
  justify-content: center;
  border-radius: 13px;
  padding: 4px 8px 4px 8px;
  opacity: 1;
}

.kanbanContent{
  height: 100%;
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
}

.kanbanColumn{
  padding-left: 5px;
  padding-right: 5px;
}

.kanbanMinicards{
  height: calc(100vh - 220px);
}

/* Tabsters WebApp Front Version */
.tabstersWebappVersion{
  display: flex;
  position: absolute;
  font-size: 14px;
  color: #0088C7;
  top: 10px;
  left: 20px;
}

.tabstersBlogLabel{
  display: flex;
  position: absolute;
  /* justify-content: center; */
  /* text-align: center; */
  font-size: 18px;
  color: #0088C7;
  top: 5%;
  left: 50%;
}

.tabstersBlogArticleLeft{
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  height: 320px;
  width: 50%;
  margin: auto;
  padding-left: 100px;
}

.tabstersBlogArticleRight{
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  height: 320px;
  width: 50%;
  margin: auto;
  padding-right: 100px;
}

.tabstersBlogImage{
  display: flex;
  height: 260px;
  width: 260px;
  /* height: 80%; */
  /* width: 100%; */
}

.tabstersBlogArticleName{
  display: flex;
  /* justify-content: center; */
  text-align: center;
  width: 260px;
  margin-top: 10px;
}

/* ---------- Login ---------- */
.loginForm{
  height: 100vh;
  width: 50%;
  /* padding-top: 4%; */
  /* margin: auto; */
}

.loginContent{
  height: 100vh;
  width: 50%;
  padding-top: 20px;
  /* padding-top: 4%; */
  /* margin: auto; */
}

.loginArticles{
  display: flex;
  flex-wrap: wrap;
  height: calc(100% - 25px);
  width: 100%;
  margin-top: 20px;
  margin-left: 0px;
  margin-right: 0px;
}

.loginImage{
  display: flex;
  align-items: center;
  height: calc(100% - 25px);
  width: 100%;
  margin-top: 20px;
}

.tabstersLoginImage{
  display: flex;
  align-items: center;
  width: 50%;
  position: absolute;
}

/* .tabstersLoginImage{
  width: 50%;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
} */

.loginLanguages{
  display: flex;
  position: absolute;
  top: 10px;
  right: 20px;
}

.forgotPasswordButton{
  color: #0088C7;
  /* margin-top: 15px; */
  padding-left: 0px;
}

.loginPasswordButtons{
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.loginOAuthButtons{
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
}

.loginButton{
  background: #0088C7 0% 0% no-repeat padding-box;
  border-radius: 26px;
  width: 160px;
  opacity: 1;
}

.loginSpinner{
  display: block;
  margin-top: 20px;
  margin-left: 10px;
}

.loginOAuthButton{
  background: #0088C7 0% 0% no-repeat padding-box;
  border-radius: 26px;
  width: 220px;
  opacity: 1;
}

.authenticationOAuthButton{
  color: #0088C7;
}

.authenticationPasswordButton{
  color: #0088C7;
}

.passwordExpiredPopup{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1001;
  top: 0;
  left: 0;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0, 0, 0, 0.3);
}

.passwordExpiredInnerPopup{
  width: 45%;
  background-color: rgb(255, 255, 255);
  border: 1px solid #888;
  border-radius: 12px;
  margin: auto;
  /* margin-top: 10%; */
  padding: 20px;
}

.popupPasswordExpired{
  max-height: 500px;
}

.passwordExpiredLabel{
  display: flex;
  align-items: center;
  color: #0088C7;
  font-size: 16px;
  font-weight: bold;
}

/* ---------- Change Password ---------- */
.changePasswordForm{
  height: 100vh;
  width: 50%;
  /* padding-top: 4%; */
  /* margin: auto; */
}

.changePaswordImages{
  height: 100vh;
  width: 50%;
  padding-top: 20px;
  /* padding-top: 4%; */
  /* margin: auto; */
}

.changePasswordButton{
  background: #0088C7 0% 0% no-repeat padding-box;
  border-radius: 26px;
  width: 160px;
  margin-top: 15px;
  opacity: 1;
}


/* ---------- Navbar ---------- */
.iconNotificationsWrapper{
  position: relative;
}

.notifications{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  min-width: 22px;
  min-height: 22px;
  border-radius: 50%;
  background-color: #E21313;
  color: #FFFFFF;
  font-size: 10px;
  left: 11px;
}

.notifications .tooltipNotifications{
  overflow: visible;
  height: 0;
  margin-top: -1em;
}

/* ---------- Home ---------- */
.homeTitle{
  display: flex;
  /* justify-content: end; */
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}

.homeLabel{
  font-size: 16px;
  font-weight: bold;
  color: #0088C7;
  margin-top: 10px;
  margin-bottom: 10px;
}

#searchHome{
  height: 70%;
  border-radius: 30px;
}

.favoriteLabel{
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 5px;
}

.homeLabelBeta{
  /* display: flex; */
  /* justify-content: center; */
  font-size: 14px;
  color: #0088C7;
  max-width: fit-content;
  transform: rotate(345deg);
  border: 1px #0088C7 dashed;
  border-radius: 10px;
  padding: 5px 10px;
}

.homeUserWeek{
  margin-top: 15px;
  margin-left: 10px;
}

.homeUserWeekLabel{
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: #0088C7;
}

.homeUserWeekInfo{
  margin-left: 5px;
  margin-bottom: 30px;
}

.homeUserWeekMessage{
  width: 80%;
  justify-content: center;
  font-size: 14px;
  font-style: italic;
  font-weight: bold;
  color: #777777;
}

.homeUserWeekTask{
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.homeUserWeekEvents{
  /* display: flex; */
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.homeUserWeekTaskLabel{
  display: flex;
  align-items: center;
  width: calc(100% - 200px);
}

.homeUserWeekTaskTimeline{
  width: 100%;
  max-width: 200px;
}

.homeDisplayAll{
  /* display: flex; */
  /* justify-content: flex-end; */
  max-width: max-content;
  font-size: 12px;
  color: #0088C7;
  cursor: pointer;
  margin-top: 10px;
  margin-right: 10px;
  padding: 4px 8px 4px 8px;
}

.badgeHome{
  font-size: 12px;
  margin-left: 10px;
  margin-right: 0px;
  padding: 6px 14px 6px 14px;
}

.progressTimeTrackingHome{
  height: 15px;
  width: 95%;
  background-color: #F5F5F5;
  border-radius: 4px;
  margin-left: 10px;
  margin-bottom: 0px;
}

.homeTimetrackingIndicators{
  margin-top: 10px;
  margin-left: 10px;
}

.container-fluid-nav-wrapper{
  margin-bottom: 90px;
}

/* ---------- Search ---------- */
.searchContent{
  width: 100%;
  /* margin: auto; */
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.searchTitle{
  display: flex;
  /* justify-content: end; */
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}

.searchEmpty{
  margin: auto;
  font-weight: bold;
}

.searchResults{ 
  display: flex;
  /* justify-content: end; */
  background-color: #FFFFFF;
  font-size: 13px;
  margin-left: 5px;
  margin-bottom: 30px;
}

.searchbar{
  display: flex;
  justify-content: center;
  background-color: #FFFFFF;
  height: 70px;
  margin-bottom: 0px;
  /* margin-bottom: 30px; */
  /* padding-left: 1.2%; */
}

#searchForm{
  height: 70%;
  width: 90%;
  border-radius: 30px;
  /* box-shadow: 0px 0px 30px #D5D5D76A; */
}

/* ---------- Minicard ---------- */
.card-body{
  padding: 5px;
}

.minicard{
  min-height: 160px;
  width: 280px;
  background: #FFFFFF no-repeat padding-box;
  /* box-shadow: 0px 0px 30px #5E6F774A; */
  border-radius: 6px;
  opacity: 1;
  margin: 5px;
  margin-bottom: 10px;
}

.minicard:hover{
  min-height: 160px;
  width: 280px;
  background: #FFFFFF no-repeat padding-box;
  /* box-shadow: 0px 0px 30px #5E6F774A; */
  border-color: #B1B2BE;
  border-radius: 6px;
  opacity: 1;
  margin: 5px;
  margin-bottom: 10px;
}

.minicardPresentation{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
  width: 200px;
  color: #000000;
  margin: 10px 10px 20px 10px;
  border-radius: 9px;
  opacity: 1;
}

.minicardPresentationSelected{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
  width: 200px;
  color: #000000;
  margin: 10px 10px 20px 10px;
  border: 2px dashed #E21313;
  border-radius: 9px;
  opacity: 1;
}

.minicardsWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.minicardHeader{
  display: flex;
  /* align-items: center; */
  min-height: 40px;
  background-color: #FFFFFF;
  border-bottom: none;
  /* margin-top: 10px; */
  padding: 10px 10px 5px 10px;
}

.minicardHeader:first-child{
  border-radius: 6px;
}

.minicardTitle{
  height: 40px;
  overflow: hidden;
  /* align-items: center; */
  /* position: absolute; */
  color: #0088C7;
  font-size: 13px;
  font-weight: bold;
  line-height: 20px;
  white-space: pre-line;
  word-wrap: break-word;
}

.minicardIcon{
  margin-right: 8px;
}

.minicardIconBlue{
  background-color: #0088C7;
  border-radius: 8px;
  margin-right: 10px;
  padding: 8px;
}

.minicardIconOrange{
  background-color: #F57C12;
  border-radius: 8px;
  margin-right: 10px;
  padding: 8px;
}

.minicardIconTurquoise{
  background-color: #26A599;
  border-radius: 8px;
  margin-right: 10px;
  padding: 8px;
}

.minicardIconGrey{
  background-color: #8E8E90;
  border-radius: 8px;
  margin-right: 10px;
  padding: 8px;
}

.minicardIconRed{
  background-color: #E21313;
  border-radius: 8px;
  margin-right: 10px;
  padding: 8px;
}

.minicardBody{
  min-height: 80px;
  color: #050A2F;
  /* margin-bottom: 5px; */
  padding: 10px;
}

.minicardContent{
  /* display: flex; */
  justify-content: space-between;
  height: 30px;
  margin-bottom: 20px;
}

.minicardContent>.col-md-6, .minicardContent>.col-md-4{
  padding-left: 5px;
  padding-right: 5px;
}

.minicardFooter{
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 13px;
}

.minicardFooter>.col-md-6{
  padding-left: 5px;
  padding-right: 5px;
}

.minicardFooter>.col-md-4{
  /* padding-left: 5px; */
  /* padding-right: 5px; */
}

.minicardDates{
  display: flex;
  justify-content: space-between;
  min-height: 16px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.datepicker{
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}

.minicardDatepicker{
  display: inline-flex;
  align-items: center;
}

.minicardDate{
  font-size: 12px;
  color: #777777;
  margin-right: 5px;
}

#start{
  float: right;
  font-size: 11px;
}

#end{
  float: left;
  font-size: 11px;
}

.minicardStatus{
  display: flex;
  font-size: 11px;
  justify-content: center;
  padding: 4px 8px 4px 8px;
  /* padding: 5%; */
  width: max-content;
  /* width: 80px; */
}

.minicardPriority{
  font-size: 12px;
  /* font-weight: bolder; */
  margin-left: -10px;
}

.progressTimelineMinicard{
  position: relative;
  overflow: hidden;
  height: 20px;
  width: 90%;
  /* max-width: 90%; */
  max-width: 200px;
  border-radius: 13px;
  background-color: #E7E7E7;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
  /* margin: 0 auto; */
  /* margin-bottom: 20px; */
}

.minicardWarning{
  font-size: 11px;
  justify-content: center;
}

/* ---------- Minicard Kanban ---------- */
.minicardKanban{
  /* min-height: 160px; */
  width: calc(100% - 10px);
  /* width: 280px; */
  background: #FFFFFF no-repeat padding-box;
  /* box-shadow: 0px 0px 30px #5E6F774A; */
  border-radius: 6px;
  opacity: 1;
  margin: 5px;
  margin-bottom: 10px;
}

.minicardKanban:hover{
  /* min-height: 160px; */
  width: calc(100% - 10px);
  /* width: 280px; */
  background: #FFFFFF no-repeat padding-box;
  /* box-shadow: 0px 0px 30px #5E6F774A; */
  border-color: #B1B2BE;
  border-radius: 6px;
  opacity: 1;
  margin: 5px;
  margin-bottom: 10px;
}

.minicardKanbanHeader{
  display: flex;
  /* align-items: center; */
  /* min-height: 40px; */
  background-color: #FFFFFF;
  border-bottom: none;
  /* margin-top: 10px; */
  padding: 10px 10px 5px 10px;
}

.minicardKanbanHeader:first-child{
  border-radius: 6px;
}

.minicardKanbanIcon{
  margin-right: 5px;
}

.minicardKanbanTitle{
  /* height: 40px; */
  overflow: hidden;
  /* align-items: center; */
  /* position: absolute; */
  color: #0088C7;
  font-size: 12px;
  font-weight: bold;
  line-height: 20px;
  white-space: pre-line;
  word-wrap: break-word;
}

.minicardKanbanBody{
  display: flex;
  min-height: 20px;
  color: #050A2F;
  /* margin-bottom: 5px; */
  padding: 5px 10px;
}

.minicardKanbanContent{
  display: flex;
  flex-wrap: wrap;
  height: fit-content;
  width: 100%;
  max-width: 100%;
  margin-left: 0px;
  margin-right: 0px;
}

.minicardKanbanField{
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-bottom: 5px;
}

.minicardKanbanDouble{
  display: inline-flex;
  font-size: 12px;
  font-weight: normal;
  justify-content: center;
  max-width: max-content;
  /* background-color: #E7E7E7; */
  /* color: #000000; */
  border-radius: 5px;
  padding: 4px 8px 4px 8px;
}

.minicardKanbanProgress{
  display: flex;
  align-items: center;
  min-width: 80px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.minicardKanbanTimeline{
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 160px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.progressTimelineKanban{
  position: relative;
  overflow: hidden;
  height: 20px;
  width: 100%;
  /* max-width: 90%; */
  max-width: 200px;
  border-radius: 13px;
  background-color: #E7E7E7;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
  /* margin: 0 auto; */
  /* margin-bottom: 20px; */
}

/* ---------- Card ---------- */
.cardHeader{
  height: 100%;
  max-height: 60px;
  width: 100%;
  /* margin: auto; */
  /* padding-top: 5px; */
}

.blockTitle{
  display: flex;
  align-items: center;
  height: 59px;
  margin-left: 10px;
}

.cardName{
  font-size: 18px;
  font-weight: bold;
  /* background-color: #0088C7; */
  /* color: #FFFFFF; */
  color: #000000;
  /* border-radius: 10px; */
  /*padding: 10px;*/
  padding: 0px 10px 0px 10px; 
}

.labelIn{
  font-size: 12px;
  color: #8E8E90;
  margin-right: 10px;
}

.cardParents{
  display: flex;
  align-items: center;  
  max-width: 380px;
  max-height: 25px;
}

.cardBody{
  display: flex;
  height: calc(100% - 65px);
  width: 100%;
  padding: 10px 10px 10px 10px;
}

.cardContent{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.cardContentViews{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100% - 270px);
  padding-left: 15px;
  padding-right: 10px;
}

.cardComponent{
  height: 100%;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.cardDashboard{
  height: 100%;
  width: 100%;
}

.cardIcon{
  display: flex;
  margin-right: 5px;
}

.cardLink{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
}

.cardMenuAction{
  display: inline-flex;
  align-items: center;
  min-height: 30px;
  font-size: 12px;
  padding: 5px;
}

.cardMenuAction:hover{
  background-color: #D7D7D7;
  border-radius: 5px;
}

.popoverCardMenu{
  height: auto;
  min-width: fit-content;
  padding: 5px;
}

.cardIconBlue{
  background-color: #0088C7;
  border-radius: 10px;
  padding: 10px;
}

.cardIconOrange{
  background-color: #F57C12;
  border-radius: 10px;
  padding: 10px;
}

.cardIconTurquoise{
  background-color: #26A599;
  border-radius: 10px;
  padding: 10px;
}

.cardIconGrey{
  background-color: #8E8E90;
  border-radius: 10px;
  padding: 10px;
}

.cardIconBlack{
  background-color: #000000;
  border-radius: 10px;
  padding: 10px;
}

.cardFooter:last-child{
  min-height: 40px;
}

.minh40{
  min-height: 40px;
}

.minh170{
  min-height: 170px;
}

.cardSummary{
  min-height: 30px;
  border: none;
  box-shadow: none;
  color: #777777;
  font-size: 12px;
  text-align: center;
  margin-left: -5px;
  padding: 5px;
}

.cardDetails{
  border: none;
  width: 90%;
  height: 30px;
  margin-left: -5px;
  padding: 5px;
  box-shadow: none;
}

.cardStatus{
  display: flex;
  letter-spacing: 0;
  opacity: 1;
  font-size: 11px;
  justify-content: center;
  padding: 3px 6px 3px 6px;
  width: 100px;
}

#status{
  float: right;
  position: relative;
  top: -50px;
  right: 50px;
}

.fixedHeader{
  width: calc(96% - 55px);
  position: fixed;
  background-color: #FFFFFF;
  z-index: 3;
}

.addPanelPopup{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1001;
  top: 0;
  left: 0;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0, 0, 0, 0.3);
}

.addPanelInnerPopup{
  height: fit-content;
  width: 80%;
  background-color: rgb(255, 255, 255);
  border: 1px solid #888;
  border-radius: 12px;
  margin: auto;
  /* margin-top: 5%; */
  padding: 20px;
}

.editPanelPopup{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1001;
  top: 0;
  left: 0;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0, 0, 0, 0.3);
}

.editPanelInnerPopup{
  height: 80%;
  width: 80%;
  background-color: rgb(255, 255, 255);
  border: 1px solid #888;
  border-radius: 12px;
  margin: auto;
  /* margin-top: 5%; */
  padding: 20px;
}

.popupAddPanel{
  height: 100%;
  max-height: 100%;
}

.popupEditPanel{
  display: flex;
  flex-direction: column;
  height: 100%;
  /* max-height: 100%; */
}

/* ---------- MiniBlocks ---------- */
.miniblock{
  font-family: 'Montserrat', sans-serif !important;
  font-size: 14px;
  /* display: flex; */
  position: relative;
  /* align-items: baseline; */
  /* height: 160px; */
  /* width: 280px; */
  height : 100%;
  width: 100%;
  /* border: 0.5px solid #D4E2E7;
  border-radius: 10px; */
  margin: auto;
  /* padding: 12px 0px 5px 0px; */
  /* margin-top: 30px; */
}

.miniblockRoadmap{
  font-family: 'Montserrat', sans-serif !important;
  font-size: 14px;
  /* display: flex; */
  position: relative;
  /* align-items: baseline; */
  /* height: 530px; */
  /* width: 280px; */
  height: 100%;
  width: 100%;
  /* border: 0.5px solid #D4E2E7;
  border-radius: 10px; */
  margin: auto;
  /* padding: 12px 0px 5px 0px; */
  /* margin-top: 30px; */
}

.miniblockSize{
  width: 90%;
  margin: auto;
}

.miniblockHeader{
  height: 30px;
  width: 100%;
  display: inline-flex;
}

.panelBody{
  display: flex;
  height: calc(100% - 30px);
  width: 100%;
  padding-bottom: 10px;
} 

.panelViews{
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 220px;
  max-width: 220px;
  border-right: 1px solid #B1B2BE;
  padding-right: 10px;
}

.panelContent{
  display: flex;
  flex-direction: column;
  height: 100%;
  /* width: 100%; */
  width: calc(100% - 210px);
  padding-left: 15px;
}

.panelBlockHeader{
  height: 30px;
  display: inline-flex;
  align-items: center;
  margin-bottom: 10px;
}

.panelComponent{
  /* display: flex; */
  height: 100%;
  width: 100%;
  overflow: scroll;
}

.panelComponentNoScroll{
  height: calc(100% - 60px);
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  /* padding-top: 10px; */
  /* padding-bottom: 10px; */
}

.miniblockTotalRows{
  display: flex;
  font-size: 11px;
  color: #0088C7;
  margin-top: 5px;
}

.miniblockDisplayedRows{
  display: flex;
  font-size: 11px;
  margin-top: 5px;
}

.miniblockFilters{
  display: flex;
  font-size: 11px;
  color: #FFFFFF;
  margin-top: 5px;
  margin-left: 5px;
}

.miniblockFiltersNumber{
  height: 15px;
  width: 15px;
  background-color: #0088C7;
  border-radius: 20px;
  margin-left: 5px;
  padding: 1px 4px 1px 4px;
}

.miniblockContent{
  height: calc(100% - 5px);
  width: calc(100% - 30px);
  /* height: calc(100% - 60px); */
  /* width: calc(100% - 30px); */
  margin-left: 15px;
  margin-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.miniblockContentEmpty{
  height: 100%;
  /* max-height: calc(100% - 80px); */
  /* width: calc(100% - 30px); */
  margin-left: 15px;
  margin-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.miniblockRoadmapTimeline{
  height: 70px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.miniblockDetails{
  overflow: auto;
  height: calc(100% - 20px);
  width: calc(100% - 30px);
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 15px;
  margin-right: 15px;
  /* padding-top: 5px; */
  /* padding-bottom: 5px;  */
}

.miniblockDetailsCommunication{
  /* overflow: auto; */
  height: calc(100% - 20px);
  width: calc(100% - 30px);
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 15px;
  margin-right: 15px;
}

.miniChartWidget{
  overflow: auto;
  /* height: 100%; */
  height: calc(100% - 5px);
  width: 100%;
}

.miniblockMeteo{
  overflow: auto;
  height: calc(100% - 50px);
  /* width: calc(100% - 30px); */
  margin-left: 15px;
  margin-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.miniblockBudget{
  height: 100%;
  /* max-height: calc(100% - 80px); */
  /* width: calc(100% - 30px); */
  margin-left: 15px;
  margin-right: 15px;
  padding-top: 5px;
  padding-bottom: 10px;
}

.miniblockEarnings{
  height: 100%;
  /* max-height: calc(100% - 80px); */
  /* width: calc(100% - 30px); */
  margin-left: 15px;
  margin-right: 15px;
  padding-top: 5px;
  padding-bottom: 10px;
}

.miniblockResources{
  height: 100%;
  /* max-height: calc(100% - 80px); */
  /* width: calc(100% - 30px); */
  margin-left: 15px;
  margin-right: 15px;
  padding-top: 5px;
  padding-bottom: 10px;
}

.miniblockCommunication{
  height: 100%;
  /* max-height: calc(100% - 80px); */
  /* width: calc(100% - 30px); */
  margin-left: 15px;
  margin-right: 15px;
  padding-top: 5px;
  padding-bottom: 10px;
}

.miniblockBurned{
  height: 100%;
  width: 100%;
  /* width: calc(100% - 30px); */
  overflow: auto;
  /* margin-left: 15px; */
  /* margin-right: 15px; */
  padding-top: 5px;
  padding-bottom: 10px;
}

.miniblockWorkload{
  height: 100%;
  width: 100%;
  /* width: calc(100% - 30px); */
  overflow: auto;
  /* margin-left: 15px; */
  /* margin-right: 15px; */
  padding-top: 5px;
  padding-bottom: 10px;
}

.miniblockHolidays{
  height: calc(100% - 5px);
  margin-left: 15px;
  margin-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  /* height: calc(100% - 10px);
  width: calc(100% - 30px);
  margin-left: 15px;
  margin-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px; */
}

.miniblockFooter{
  height: 50px;
  /* height: 30%; */
  width: 100%;
  border-top: 0.5px solid #D4E2E7;
  padding-top: 10px;
}

.miniblockTitle{
  overflow: hidden;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  margin-top: 3px;
  text-transform: uppercase;
}

.dashboardContent{
  height: 100%;
  /* height: 85%; */
  width: 100%;
  overflow: scroll;
  margin-top: 10px;
  margin-bottom: 10px;
}

.availableMiniblocks{
  height: 100%;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.miniblocksType{
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 20px;
}

.addPanelLabel{
  display: flex;
  align-items: center;
  color: #0088C7;
  font-size: 16px;
  font-weight: bold;
}

.miniblocksTypeLabel{
  display: flex;
  align-items: center;
  color: #0088C7;
  font-size: 16px;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 15px;
}

.panelBlockTitle{
  width: 50%;
  margin-bottom: 0px;
}

.panelBlockLabel{
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #0088C7;
  width: 100%;
  border: none;
  box-shadow: none;
  resize: none;
  padding: 3px 10px;
  margin-right: 10px;
}

.panelBlockLabel:focus{
  display: flex;
  align-items: center;
  color: #0088C7;
  font-size: 16px;
  font-weight: bold;
}

.miniblockText{
  font-size: 11px;
  line-height: 14px;
  color: #777777;
}

.miniblockLabel{
  float: right;
  font-size: 8px;
  color: #0088C7;
  text-transform: uppercase;
  margin-top: -5px;
}

.miniblockEmptyText{
  font-size: 10px;
  font-style: italic;
  color: #0088C7;
  line-height: 14px;
  position: relative;
  margin-left: 10px;
}

.miniblockContainer{
  text-align: center;
  width: 248px;
  margin-left: 15px;
  margin-right: 15px;
}

.miniblockCenter{
  display: inline-block;
  text-align: left;
  margin: 0 auto;
}

.miniblockAxe{
  width: max-content;
  font-size: 11px;
  color: #777777;
  border: 1px solid #777777;
  border-radius: 13px;
  padding: 3px 6px 3px 6px;
}

.miniblockWrap{
  display: inline-flex;
  flex-wrap: wrap;
}

.miniblockAxisTable{
  width: max-content;
  font-size: 11px;
  color: #777777;
  border: 1px solid #777777;
  border-radius: 13px;
  padding: 3px 6px 3px 6px;
  margin-bottom: 2px;
}

.miniblockBadges{
  display: inline-block;
  text-align: center;
  width: 33%;
  /* margin: 0px 4px; */
}

.miniblockBudgetElement{
  width: 100%;
  line-height: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 10px;
}

.miniblockDates{
  display: inline-block;
  text-align: center;
  font-size: 10px;
  color: #777777;
  width: 20%;
  margin-left: 5px;
  margin-right: 5px;
}

.miniblockDetailsField{
  display: inline-flex;
  /* align-items: center; */
  min-height: 25px;
  width: 100%;
  margin-top: 3px;
  margin-bottom: 3px;
}

.miniblockDetailsLabel{
  font-size: 12px;
  font-weight: 500;
  color: #0088C7;
}

.miniblockDetailsValue{
  font-size: 12px;
  color: #777777;
}

.miniblockList{
  font-size: 10px;
  font-weight: bolder;
  color: #050A2F;
  margin-left: -5px;
}

.miniblockStatus{
  display: inline-flex;
  justify-content: center;
  /* width: max-content; */
  font-size: 11px;
  font-weight: normal;
  border-radius: 13px;
  padding: 4px 8px 4px 8px;
}

.projectManager{
  font-size: 12px;
}

.miniblockTable{
  height: 100%;
  width: 100%;
  /* overflow: scroll; */
  /* padding-bottom: 10px; */
  /* table-layout: fixed; */
}

.miniblockComponent{
  height: 100%;
  width: 100%;
  overflow: scroll;
}

.miniblockBudgetContent{
  height: 100%;
  overflow: auto;
  /* padding-bottom: 10px; */
  /* table-layout: fixed; */
}

.miniblockCommunicationList{
  height: 100%;
  overflow: auto;
  padding-bottom: 10px;
}

.miniblockRiskMatrix{
  height: 100%;
  width: 100%;
  /* overflow: scroll; */
}

.miniblockPresentation{
  font-size: 11px;
  padding: 5px;
}

.miniblockEmpty{
  display: flex;
  height: 100%;
  overflow: auto;
  padding-top: 10px;
}

.miniblockHeatmap{
  height: 100%;
  overflow: auto;
  padding-bottom: 10px;
}

.miniblockRoadmapTable{
  height: calc(100% - 70px);
  overflow: auto;
}

.miniblockTimeline{
  width: 100%;
  max-width: 350px;
  margin: 0px auto;
}

.dragAndDrop{
  background-color: #FFFFFF;
  height: 100%;
  margin-bottom: 100px;
}

.emptyblockDecisions{
  background: #0088C7;
  mask: url('../Images/Icons/EmptyBlockDecisions.svg');
}

.emptyblockIssues{
  /* background: #0088C7; */
  /* mask: url('../Images/Icons/EmptyBlockIssues.svg'); */
}

.emptyblockMeetings{
  background: #0088C7;
  mask: url('../Images/Icons/EmptyBlockMeetings.svg');
}

.emptyblockRisks{
  background: #0088C7;
  mask: url('../Images/Icons/EmptyBlockRisks.svg');
}

.emptyblockRoadmap{
  background: #0088C7;
  mask: url('../Images/Icons/EmptyBlockRoadmap.svg');
}


/* ---------- Blocks ---------- */
.blockContainer{
  height: 100vh;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
}

.block{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-left: 65px;
  padding-right: 10px;
}

.blockHeader{
  height: 100%;
  max-height: 60px;
  width: 100%;
  margin: auto;
  /* padding-top: 5px; */
}

.blockHeaderTitle{
  height: 100%;
  max-height: 60px;
  width: 100%;
  margin: auto;
  /* padding-top: 5px; */
}

.blockTitle{
  display: flex;
  align-items: center;
  height: 59px;
  margin-left: 10px;
}

.blockIndicators{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* height: 100%; */
  margin-right: 10px;
}

/* ----- ----- Popup ----- ----- */
.messagePopup{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1002;
  top: 0;
  left: 0;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0, 0, 0, 0.3);
}

.messageInnerPopup{
  height: fit-content;
  width: 500px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #888;
  border-radius: 12px;
  /* margin: auto; */
  padding: 20px;
}

.popupMessage{
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* ----- Enclosed Files ----- */
.filesPopup{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1001;
  top: 0;
  left: 0;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0, 0, 0, 0.3);
}

.filesInnerPopup{
  height: 80%;
  width: 60%;
  background-color: rgb(255, 255, 255);
  border: 1px solid #888;
  border-radius: 12px;
  margin: auto;
  /* margin-top: 5%; */
  padding: 20px;
}

.popupEditFiles{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.popupFiles{
  height: 100%;
  /* margin-bottom: 10px; */
}

.popupFilesList{
  height: 100%;
  width: 100%;
  overflow: auto;
  padding-bottom: 10px;
}

.filesTitle{
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
}

.filesLabel{
  display: flex;
  align-items: center;
  height: 30px;
  width: 100%;
  font-weight: bold;
  color: #0088C7;
  margin-bottom: 0px;
}

.filesLabelBlue{
  color: #0088C7;
  font-size: 16px;
  font-weight: bold;
}

.filesLabelYellow{
  color: #FABD05;
  font-size: 16px;
  font-weight: bold;
}

.loadFileReader{
  height: 100%;
  /* width: 100%; */
}

.loadFileButton{
  display: inline-flex;
  justify-content: flex-end;
  background-color: #0088C7;
  color: #FFFFFF;
  font-size: 12px;
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 20px;
  padding: 5px 10px;
}

.fileItem{
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
  padding: 5px 10px;
}

.fileIcon{
  
}

.fileName{
  font-size: 13px;
  font-weight: bold;
  width: 100%;
}

/* ----- Rights ----- */
.rightsPopup{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1001;
  top: 0;
  left: 0;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0, 0, 0, 0.3);
}

.rightsInnerPopup{
  height: 80%;
  width: 60%;
  background-color: rgb(255, 255, 255);
  border: 1px solid #888;
  border-radius: 12px;
  margin: auto;
  /* margin-top: 5%; */
  padding: 20px;
}

.popupEditRights{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.popoverRight{
  max-width: 380px;
  height: 60vh;
  padding: 10px;
}

.rightsList{
  display: flex;
  flex-direction: column;
  /* max-height: calc(60vh - 95px); */
  /* height: 350px; */
  height: 100%;
  overflow-y: auto;
}

.rightsListErrors{
  display: flex;
  flex-direction: column;
  /* max-height: calc(60vh - 115px); */
  /* height: 370px; */
  height: calc(100% + 20px);
  overflow-y: auto;
}

.rightsLabel{
  display: flex;
  align-items: center;
  height: 30px;
  width: 100%;
  font-size: 13px;
  font-weight: bold;
  color: #0088C7;
  margin-bottom: 0px;
}

.rightsResourceName{
  /* width: 150px; */
  font-size: 12px;
  color: #8E8E90;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.rightsSelect{
  /* display: flex; */
  width: 150px;
}

.rightsAdmin{
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
  color: #FFFFFF !important;
  background-color: #E21313 !important;
  border: 1px solid #B1B2BE;
  border-radius: 30px !important;
  padding: 6px 12px;
}

.rightsModify{
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
  color: #FFFFFF !important;
  background-color: #FFC107 !important;
  border: 1px solid #B1B2BE;
  border-radius: 30px !important;
  padding: 6px 12px;
}

.rightsView{
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
  color: #FFFFFF !important;
  background-color: #B1B2BE !important;
  border: 1px solid #B1B2BE;
  border-radius: 30px !important;
  padding: 6px 12px;
}

.rightsNone{
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
  color: #B1B2BE !important;
  border: 1px dashed #B1B2BE;
  border-radius: 30px !important;
  padding: 6px 12px;
}

.rightsValue{
  display: flex;
  width: 90%;
  justify-content: left;
  margin-left: 5px;
}

.rightsLabelBlue{
  color: #0088C7;
  font-size: 16px;
  font-weight: bold;
}

.rightsLabelYellow{
  color: #FFC107;
  font-size: 16px;
  font-weight: bold;
}

.tabRights, .tabRights:hover{
  color: #B1B2BE;
  /* font-weight: bold; */
  text-decoration: none;
  cursor: pointer;
}

.tabRightsSelected, .tabRightsSelected:hover{
  color: #000000;
  font-weight: bold;
  text-decoration: none;
  border-bottom: 2px solid #000000;
  cursor: pointer;
}

/* ----- Dependencies ----- */
.dependenciesPopup{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1001;
  top: 0;
  left: 0;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0, 0, 0, 0.3);
}

.dependenciesInnerPopup{
  height: 80%;
  min-height: 400px;
  width: 80%;
  background-color: rgb(255, 255, 255);
  border: 1px solid #888;
  border-radius: 12px;
  margin: auto;
  /* margin-top: 10%; */
  padding: 20px;
}

.popupEditDependencies{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.dependenciesLabel{
  display: flex;
  align-items: center;
  height: 30px;
  width: 100%;
  font-size: 13px;
  font-weight: bold;
  color: #0088C7;
  margin-bottom: 0px;
}

.dependenciesLabelBlue{
  color: #0088C7;
  font-size: 16px;
  font-weight: bold;
}

.dependenciesLabelYellow{
  color: #FFC107;
  font-size: 16px;
  font-weight: bold;
}

.dependenciesTables{
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}

.previousDependencies{
  margin-bottom: 40px;
}

.previousDependenciesTitle{
  display: flex;
  align-items: center;
}

.previousDependenciesLabel{
  color: #0088C7;
  font-size: 14px;
  font-weight: bold;
}

.nextDependencies{
  
}

.nextDependenciesTitle{
  display: flex;
  align-items: center;
}

.nextDependenciesLabel{
  color: #0088C7;
  font-size: 14px;
  font-weight: bold;
}

.slidetype, .slidetype:hover{
  color: #B1B2BE;
  /* font-weight: bold; */
  text-decoration: none;
  cursor: pointer;
}

.slidetypeSelected, .slidetypeSelected:hover{
  color: #000000;
  font-weight: bold;
  text-decoration: none;
  border-bottom: 2px solid #000000;
  cursor: pointer;
}

.popoverAccount{
  height: auto;
  min-width: fit-content;
  padding: 10px;
}

.accountTitle{
  color: #0088C7;
  font-weight: bold;
  margin-bottom: 0px;
}

.accountLabel{
  color: #050A2F;
  font-size: 12px;
  font-weight: bold;
  margin-left: 10px;
}

.accountLogout{
  color: #F57C12;
  font-size: 12px;
  font-weight: bold;
  margin-left: 10px;
}

.accountAction{
  display: inline-flex;
  align-items: center;
  min-height: 30px;
  font-size: 12px;
  padding: 5px;
}

.accountAction:hover{
  background-color: #D7D7D7;
  border-radius: 5px;
}

.cardTitle{
  display: flex;
  align-items: center;
  height: 100%;
  padding-top: 4px;
}

.blockBorder{
  margin: 0px 10px;
  border-bottom: 1px solid #777777;
}

.blockBorderBlue{
  margin: 5px 0px;
  border-bottom: 1px solid #0088C7;
}

.titleBorder{
  border-bottom: 1px solid #777777;
}

.popoverBorder{
  margin: 5px;
  border-bottom: 1px solid #777777;
}

.blockBody{
  display: flex;
  height: calc(100% - 60px);
  width: 100%;
  padding: 10px 10px 10px 10px;
}

.blockBodyColumn{
  display: flex;
  flex-direction: column;
  height: calc(100% - 60px);
  width: 100%;
  padding: 10px 10px 10px 10px;
}

.blockBodyEnvironmentRoadmap{
  display: flex;
  height: calc(100% - 90px);
  width: 100%;
  padding: 10px 10px 10px 10px;
}

.blockViews{
  display: flex;
  flex-direction: column;
  height: 100%;
  /* height: calc(100% - 75px); */
  min-width: 280px;
  /* position: fixed; */
  /* background-color: #B3DCEE; */
  border-right: 1px solid #B1B2BE;
  padding-right: 10px;
}

.blockContent{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.blockContentDetails{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-right: 10px;
}

.blockContentViews{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100% - 270px);
  padding-left: 15px;
  padding-right: 10px;
}

.blockContentTimetracking{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.blockContentTimetrackingViews{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100% - 270px);
  padding-left: 15px;
  padding-right: 10px;
}

.blockFilters{
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 35px;
  width: 100%;
  padding-top: 0px;
  padding-bottom: 5px;
}

.blockComponent{
  height: 100%;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.blockComponentDetails{
  height: 100%;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 10px;
}

.blockComponentTimetracking{
  display: flex;
  flex-direction: column;
  height: calc(100% - 0px);
  width: 100%;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.blockMessages{
  width: 35%;
  border-left: 1px solid #D5D5D7;
  padding-right: 10px;
}

.view{
  font-size: 12px;
  font-weight: normal;
  width: fit-content;
  margin: 3px 5px;
  padding: 4px 8px 4px 8px;
}

.viewsTitle{
  display: inline-flex;
  align-items: center;
  height: 35px;
  width: 100%;
  margin-bottom: 5px;
  /* padding: 5px 5px 5px 5px; */
}

.viewsLabel{
  width: 100%;
  font-size: 12px;
  font-weight: bold;
}

.viewsList{
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  margin-bottom: 10px;
}

.otherViewsList{
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}

.viewsIcons{
  display: inline-flex;
}

.viewItemSelected{
  display: flex;
  align-items: center;
  height: 28px;
  text-decoration: none;
  background-color: #CCE7F4;
  border-radius: 5px;
  margin-right: 5px;
  padding: 5px 10px 5px 10px;
}

.viewItem{
  display: flex;
  align-items: center;
  height: 28px;
  text-decoration: none;
  margin-right: 5px;
  padding: 5px 10px 5px 10px;
}

.viewItem:hover{
  /* width: fit-content; */
  text-decoration: none;
  background-color: #D7D7D7;
  border-radius: 5px;
  /* margin-right: 5px; */
}

.viewLink{
  display: inline-flex;
  align-items: center;
  height: 100%;
  width: calc(100% - 22px);
}

.viewLinkWithSave{
  display: inline-flex;
  align-items: center;
  height: 100%;
  width: calc(100% - 48px);
}

.viewName{
  /* height: 100%; */
  width: calc(100% - 26px);
  overflow: hidden;
  text-decoration: none;
  white-space: nowrap;
  color: #000000;
  font-size: 12px;
  /* font-weight: bold; */
  /* margin-left: 5px; */
}

.viewNameSelected{
  align-items: center;
  height: 18px;
  width: calc(100% - 25px);
  font-size: 12px;
  border: none;
  box-shadow: none;
  transition: none;
  padding: 0px 5px;
}

.currentViewName{
  display: inline-flex;
  align-items: center;
  min-height: 30px;
  background-color: #CCE7F4;
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 5px;
}

.viewAction{
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  padding: 5px;
}

.viewAction:hover{
  background-color: #D7D7D7;
  /* width: fit-content; */
  border-radius: 5px;
}

.viewRight{
  font-size: 12px;
  font-style: italic;
  color: #0088C7;
  padding: 5px;
}

.buttonPinView{
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #0088C7;
  border-radius: 15px;
  margin-right: 10px;
  padding: 3px;
}

/* .viewList{
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  padding: 5px;
} */

/* .viewList:hover{
  background-color: #D7D7D7;
  border-radius: 5px;
} */

.viewsPopup{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1001;
  top: 0;
  left: 0;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0, 0, 0, 0.3);
}

.viewsInnerPopup{
  height: 80%;
  width: 60%;
  background-color: rgb(255, 255, 255);
  border: 1px solid #888;
  border-radius: 12px;
  margin: auto;
  /* margin-top: 5%; */
  padding: 20px;
}

.popupAddView{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.popupViewsLabel{
  display: flex;
  align-items: center;
  height: 30px;
  width: 100%;
  font-size: 13px;
  font-weight: bold;
  color: #0088C7;
  margin-bottom: 0px;
}

.popupViewsLabelBlue{
  color: #0088C7;
  font-size: 16px;
  font-weight: bold;
}

.addViewContent{
  display: flex;
  height: calc(100% - 30px);
  width: 100%;
}

.viewTypes{
  width: 10%;
}

.viewType{
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin: 0px 0px 20px 0px;
}

.viewTypeBorder{
  border-right: 5px solid #0088C7;
}

.viewTypeLabel{
  color: #0088C7;
  font-size: 11px;
  font-weight: bold;
  margin: 5px auto 0px;
}

.dashboardViewsTables{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.viewsTables{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 90%;
  overflow-y: auto;
  margin-left: 20px;
}

.genericViews{
  margin-bottom: 20px;
}

.genericViewsTitle{
  display: flex;
  align-items: center;
}

.genericViewsLabel{
  color: #FFC107;
  font-size: 14px;
  font-weight: bold;
}

.userViews{
  /* margin-bottom: 40px; */
}

.userViewsTitle{
  display: flex;
  align-items: center;
}

.userViewsLabel{
  color: #FFC107;
  font-size: 14px;
  font-weight: bold;
}

.blockDetails{
  height: min-content;
  min-height: 100%;
  min-width: 90%;
  margin-bottom: 10px;
  margin-left: 15px;
  margin-right: 15px;
}

.detailsForm{
  height: calc(100vh - 140px);
  overflow-y: scroll;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 30px;
}

.userProfileForm{
  height: calc(100vh - 95px);
  overflow-y: scroll;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 30px;
}

.detailsNameRow{
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.detailsFieldRow{
  display: inline-flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  padding-left: 5px;
}

.detailsFieldList{
  display: inline-flex;
  width: 100%;
  padding-left: 5px;
}

.detailsFieldQRCode{
  display: inline-flex;
  width: 100%;
  padding-left: 5px;
}

.detailsFieldText{
  display: inline-flex;
  width: 100%;
  padding-left: 5px;
}

.detailsLabel{
  width: 33.33%;
  max-width: 250px;
  font-size: 13px;
  color: #0088C7;
  margin-bottom: 0px;
}

.detailsListLabel{
  width: 33.33%;
  max-width: 250px;
  font-size: 13px;
  color: #0088C7;
  margin-bottom: 0px;
  padding: 2px 0px;
}

.detailsTextAreaLabel{
  width: 33.33%;
  max-width: 250px;
  font-size: 13px;
  color: #0088C7;
  margin-bottom: 0px;
  padding: 6px 0px;
}

.detailsName{
  font-size: 18px;
  font-weight: bold;
  border: none;
  box-shadow: none;
  resize: none;
  /* margin-left: -5px; */
  padding-left: 5px;
}

.detailsAxe{
  display: inline-flex;
  align-items: center;
  width: 100%;
  min-width: 66.67%;
}

.detailsAxeNoLabel{
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.detailsField{
  width: 100%;
  min-width: 66.67%;
  font-size: 12px;
  border: none;
  box-shadow: none;
  resize: none;
  margin-left: -5px;
  padding-left: 5px;
}

.detailsFieldNoLabel{
  width: 100%;
  font-size: 12px;
  border: none;
  box-shadow: none;
  resize: none;
  margin-left: -5px;
  padding-left: 5px;
}

.detailsDate{
  width: 100%;
  min-width: 66.67%;
}

.detailsDateNoLabel{
  width: 100%;
}

.detailsFiles{
  display: inline-flex;
  align-items: center;
  width: 100%;
  min-width: 66.67%;
}

.detailsFilesNoLabel{
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.detailsHTML{
  width: 100%;
  min-width: 66.67%;
  font-size: 12px;
  padding: 6px 0px;
}

.detailsHTMLNoLabel{
  width: 100%;
  font-size: 12px;
  padding: 6px 0px;
}

.detailsProgress{
  width: 100%;
  min-width: 66.67%;
}

.detailsProgressNoLabel{
  width: 100%;
}

/* .detailsStatus{
  display: flex;
  justify-content: center;
  font-size: 12px;
  width: 120px;
  border: 2px solid;
  border-radius: 13px;
  padding: 3px 4px 3px 4px;
} */

.detailsComments{
  height: 100%;
  /* height: calc(100vh - 90px);
  overflow-y: scroll; */
  /* border-left: 1px solid #D5D5D7; */
  /* padding-left: 30px;
  padding-right: 30px; */
}

.detailsCommentsTitle{
  display: flex;
  align-items: center;
  /* text-align: center; */
  height: 35px;
  font-size: 14px;
  font-weight: bold;
  color: #0088C7;
  margin-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
}

.detailsCommentsMessages{
  height: calc(100vh - 280px);
  overflow-y: scroll;
  margin-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
}

.detailsCommentsMessagesGuest{
  height: calc(100vh - 150px);
  overflow-y: scroll;
  margin-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
}

.detailsCommentDateBorder{
  width: 100%;
  font-size: 13px;
  font-weight: bold;
  color: #0088C7;
  line-height: 0.1em;
  text-align: center;
  border-bottom: 1px solid #B1B2BE;
  margin: 20px 0px;
}

.detailsCommentDate{
  font-size: 13px;
  background: #FFFFFF;
  padding: 0px 10px;
}

.detailsComment{
  margin-top: 20px;
  margin-bottom: 20px;
}

.detailsCommentUser{
  font-size: 13px;
  font-weight: bold;
  color: #050A2F;
}

.detailsCommentDateTime{
  font-size: 12px;
  font-weight: bold;
  color: #B1B2BE;
  margin-left: 5px;
}

.detailsCommentMessage{
  font-size: 12px;
  /* margin-left: 20px; */
  padding: 6px 10px;
}

.detailsCommentEdit{
  font-size: 12px;
  border: none;
  box-shadow: none;
  resize: none;
  padding: 6px 10px;
}

.detailsCommentInput{
  margin-bottom: 20px;
  padding-left: 30px;
  padding-right: 10px;
}

.detailsCommentsTextArea{
  font-size: 12px;
  width: 100%;
  border-radius: 10px;
  box-shadow: none;
  resize: none;
}

.detailsCommentsButton{
  float: right;
  border-radius: 10px;
  margin-right: 10px;
  /* margin-bottom: 20px; */
}

.detailsStatusCancelled{
  color: #8E8E90 !important;
  padding-left: 5px;
}

.detailsStatusInProgress{
  color: #FFFFFF !important;
  background-color: #0088C7 !important;
  /* border-color: #0088C7 !important; */
  padding-left: 5px;
}

.detailsStatusToBeDone{
  color: #FFFFFF !important;
  background-color: #8E8E90 !important;
  /* border-color: #8E8E90 !important; */
  padding-left: 7px;
}

.detailsStatusCompleted{
  color: #FFFFFF !important;
  background-color: #00C77A !important;
  /* border-color: #00C77A !important; */
  padding-left: 7px;
}

.detailsStatusOnHold{
  color: #F57C12 !important;
  padding-left: 15px;
}

.detailsStatusToBeScheduled{
  color: #FFFFFF !important;
  background-color: #F57C12 !important;
  padding-left: 5px;
}

.meetingInfoHeader{
  background-color: #FFFFFF;
  padding: 8px 18px;
}

.meetingInfoHeaderContent{
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  padding: 5px 10px 5px;
}

.meetingInfoHeaderTitle{
  height: 22px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.48px;
}

.meetingInfoHeaderDates{
  height: 14px;
  font-size: 11px;
  letter-spacing: 0.33px;
}

.meetingInfoContent{
  display: inline-flex;
  padding: 8px 18px;
}

.meetingInfoFooter{
  display: block;
  justify-content: end;
  padding: 8px 18px;
}

.axesDetails{
  font-size: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  border: solid 1px;
  border-radius: 30px !important;
  color: #8E8E90 !important;
  max-width: 230px;
}

.axeNADetails{
  font-size: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  border: dashed 1px;
  border-radius: 30px !important;
  color: #8E8E90 !important;
  width: 80px !important;
  opacity: 0.5 !important;
}

.stylePMandRessourceDetails{
  padding: 0px 0px 0px 10px;
  box-shadow: none;
  border: none;
}

.optionBlockDetails{
  font-size: 12px;
  background-color: #ffffff;
  color: #000000;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control{
  background-color: #FFFFFF;
  color: #000000;
  /* border: none !important;
  box-shadow: none !important; */
  outline: none !important;
  /* border-width: 0; */
}

.detailsForm.form-control{
  background-color: transparent;
  color: #8E8E90;
  /* border: none !important; */
  /* box-shadow: none !important; */
  outline: none !important;
  /* border-width: 0; */
}

.archiveButton{
  height: 30px;
  width: fit-content;
  background: #0088C7 0% 0% no-repeat padding-box;
  font-size: 12px;
  border-radius: 26px;
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 4px 12px;
}

.addProjectPopup{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1001;
  top: 0;
  left: 0;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0, 0, 0, 0.3);
}

.addProjectInnerPopup{
  width: 50%;
  background-color: rgb(255, 255, 255);
  border: 1px solid #888;
  border-radius: 12px;
  margin: auto;
  /* margin-top: 10%; */
  padding: 20px;
}

.popupAddProject{
  display: flex;
  flex-direction: column;
  max-height: 500px;
}

.popupProjectTemplate{
  max-height: 480px;
}

.addProjectLabel{
  display: flex;
  align-items: center;
  color: #0088C7;
  font-size: 16px;
  font-weight: bold;
}

.newProjectTemplates{
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
  margin-bottom: 50px;
}

.newProjectEmptyTemplate{
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 200px;
  border: 1px dashed #9E9EA0;
  border-radius: 12px;
  padding: 20px;
}

.newProjectTemplate{
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 200px;
  border: 1px dashed #9E9EA0;
  border-radius: 12px;
  padding: 15px;
}

.projectTemplate{
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.projectTemplateSelected{
  display: flex;
  align-items: center;
  cursor: pointer;
  border: solid 3px #FABD05;
  background-color: #F5F5F5;
  border-radius: 9px;
  padding: 5px 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.selectFilePopup{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1001;
  top: 0;
  left: 0;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0, 0, 0, 0.3);
}

.selectFileInnerPopup{
  height: 20%;
  min-height: 180px;
  width: 40%;
  max-width: 400px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #888;
  border-radius: 12px;
  margin: auto;
  /* margin-top: 15%; */
  padding: 20px;
}

.mappingDataPopup{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1001;
  top: 0;
  left: 0;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0, 0, 0, 0.3);
}

.mappingDataInnerPopup{
  height: 60%;
  width: 60%;
  background-color: rgb(255, 255, 255);
  border: 1px solid #888;
  border-radius: 12px;
  margin: auto;
  /* margin-top: 10%; */
  padding: 20px;
}

.importFilePopup{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1001;
  top: 0;
  left: 0;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0, 0, 0, 0.3);
}

.importFileInnerPopup{
  height: 70%;
  max-height: 700px;
  width: 80%;
  background-color: rgb(255, 255, 255);
  border: 1px solid #888;
  border-radius: 12px;
  margin: auto;
  /* margin-top: 8%; */
  padding: 20px;
}

.jobStatusPopup{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1001;
  top: 0;
  left: 0;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0, 0, 0, 0.3);
}

.jobStatusInnerPopup{
  height: 20%;
  min-height: 180px;
  width: 40%;
  max-width: 400px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #888;
  border-radius: 12px;
  margin: auto;
  /* margin-top: 8%; */
  padding: 20px;
}

.popupJobStatus{
  height: 100%;
  max-height: 100%;
}

.jobStatusLabel{
  display: flex;
  align-items: center;
  color: #0088C7;
  font-size: 16px;
  font-weight: bold;
}

.jobProgress{
  display: inline-flex;
  align-items: center;
  /* height: 10px; */
  width: 100%;
  /* background-color: #F5F5F5; */
  margin-top: 10px;
}

.jobProgressLabel{
  display: flex;
  /* position: absolute; */
  /* justify-content: center; */
  width: 45px;
}

.jobProgressBar{
  display: flex;
  width: calc(100% - 45px);
}

.progressJob{
  height: 10px;
  width: 100%;
  /* margin-top: 2px; */
  margin-bottom: 0px;
}

.popupImportData{
  height: 100%;
  max-height: 100%;
}

.importDataLabel{
  display: flex;
  align-items: center;
  color: #0088C7;
  font-size: 16px;
  font-weight: bold;
}

.importLabelBlue{
  color: #0088C7;
  font-size: 16px;
  font-weight: bold;
}

.importLabelYellow{
  color: #FFC107;
  font-size: 16px;
  font-weight: bold;
}

.importHelp{
  display: flex;
  align-items: center;
  color: #777777;
  text-decoration: underline;
  /* margin-bottom: 10px; */
}

.importFileReader{
  height: 100%;
  width: 100%;
  margin-top: 10px;
  /* margin-bottom: 10px; */
}

.spinnerSelectFile{
  margin-top: 25px;
  margin-bottom: 76px;
}

.inputFileReader{
  font-size: 14px;
}

.importPopupContent{
  height: 100%;
  /* max-height: 750px; */
  width: 100%;
  overflow: scroll;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-right: 10px;
  /* padding-top: 10px; */
  /* padding-bottom: 10px; */
}

.importMappingHeader{
  font-size: 14px;
  font-weight: 700;
}

.importMappingLabel{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.importMappingValues{
  display: inline-flex;
  align-items: center;
  border-bottom: 1px solid #8E8E90;
  padding: 10px 0px;
}

.importTableContent{
  height: 100%;
  width: 100%;
  overflow: scroll;
  margin-top: 10px;
  margin-bottom: 10px;
}

.importFieldsSelect{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  /* color: #FFC107; */
  font-size: 12px;
  font-weight: bold;
  /* border: none; */
  /* box-shadow: none; */
  /* padding-left: 0px; */
}

/* ----- Pivot ----- */
.pivot{
  height: 100%;
  width: 100%;
}

/* ----- Charts ----- */
.chart{
  /* display: flex; */
  height: 100%;
  /* height: calc(100vh - 135px); */
  width: 100%;
}

.miniChart{
  height: 100%;
  width: 100%;
}

.miniChartComponent{
  height: 100%;
  width: 100%;
}

.chartDisplay{
  height: 100%;
  width: 66.66666667%;
  border-right: 1px solid #777777;
  padding-right: 20px;
}

.chartComponent{
  height: 100%;
  width: 100%;
}

.chartTable{
  /* height: 100%; */
  height: calc(100% - 32px);
  width: 100%;
}

.minichartTable{
  height: 100%;
  width: 100%;
}

.chartWithoutSettings{
  /* display: flex; */
  height: 100%;
  width: 100%;
}

.chartWithSettings{
  display: flex;
  height: 100%;
  width: 100%;
}

.chartSettings{
  display: flex;
  height: 100%;
  width: 33.33333333%;
  /* border-left: 1px solid #777777; */
  margin-left: 20px;
}

.chartSettingsContent{
  display: flex;
  flex-direction: column;
  /* padding: 10px 0px; */
  padding-right: 10px;
}

.chartSettingsTitle{
  display: inline-flex;
  max-width: 100%;
  margin-bottom: 10px;
}

.chartMode{
  display: flex;
  cursor: pointer;
  margin-right: 20px;
  padding: 8px 0px;
}

.chartModeBordered{
  display: flex;
  cursor: pointer;
  border: solid 3px #E21313;
  border-radius: 9px;
  margin-right: 20px;
  padding: 5px 10px;
}

.chartModelTitle{
  /* display: flex; */
  /* align-items: center; */
  font-size: 16px;
  font-weight: bold;
  color: #0088C7;
}

.chartModelType{
  font-size: 16px;
  font-weight: bold;
  color: #FABD05;
}

.chartModelValue{
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: #FABD05;
  margin-left: 10px;
}

.chartSettingsMode{
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.chartSettingsField{
  display: flex;
  /* align-items: center; */
  margin-top: 10px;
  margin-bottom: 10px;
}

.chartSettingsIcon{
  display: flex;
  align-items: center;
  height: 30px;
  padding: 5px 10px;
}

.chartSettingsLabel{
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
  /* white-space: wrap; */
  height: 30px;
  width: 30%;
  color: #0088C7;
  font-weight: bold;
  font-size: 12px;
  /* margin-left: 5px; */
}

.chartSettingsValue{
  display: flex;
  align-items: center;
  /* align-content: space-between; */
  cursor: pointer;
  height: 30px;
  width: 65%;
  /* color: #0088C7; */
  /* font-weight: bold; */
  font-size: 12px;
  border: 1px solid #777777;
  border-radius: 10px;
  padding: 5px 10px;
}

.chartSettingsBorder{
  margin: 5px;
  border-bottom: 1px solid #777777;
}

.chartSettingsIndicators{
  display: flex;
  flex-direction: column;
  width: 70%;
}

.chartSettingsAxes{
  display: flex;
  flex-direction: column;
  width: 70%;
}

.chartSettingsAxisLabel{
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 30px;
  width: 92%;
  /* color: #0088C7; */
  /* font-weight: bold; */
  font-size: 12px;
  border: 1px solid #777777;
  border-radius: 10px;
  margin-right: 5px;
  padding: 5px 10px;
}

.chartSettingsIndicator{
  display: flex;
  align-items: center;
  height: 30px;
  width: 100%;
  margin-bottom: 10px;
}

.chartSettingsIndicatorLabel{
  display: flex;
  align-items: center;
  cursor: pointer;
  min-height: 30px;
  width: 50%;
  /* color: #0088C7; */
  /* font-weight: bold; */
  font-size: 12px;
  border: 1px solid #777777;
  border-radius: 10px;
  padding: 5px 10px;
}

.chartSettingsAddIndicator{
  display: flex;
  width: 100%;
}

.chartSettingsNewIndicator{
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 92%;
  /* width: calc((100% - 30px) * 0.55); */
  color: #777777;
  /* font-weight: bold; */
  font-size: 12px;
  border: 1px dashed #777777;
  border-radius: 10px;
  padding: 5px 10px;
}

.chartSettingsIndicatorFunction{
  display: flex;
  align-items: center;
  cursor: pointer;
  min-height: 30px;
  width: 40%;
  /* color: #0088C7; */
  /* font-weight: bold; */
  font-size: 12px;
  border: 1px solid #777777;
  border-radius: 10px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px 10px;
}

.chartSettingsIndicatorDelete{
  display: flex;
  align-items: center;
  height: 30px;
  width: 5%;
  min-width: 17px;
  /* margin-left: 5px; */
}

.chartSettingsParameters{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.chartParameters{
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin-top: 10px;
  /* margin-bottom: 10px; */
}

.chartParametersLabel{
  display: flex;
  align-items: center;
  height: 30px;
  width: 150px;
  font-size: 12px;
  font-weight: bold;
  color: #B1B2BE;
  /* margin-left: 5px; */
}

.chartParametersSetting{
  display: flex;
  align-items: center;
  height: 30px;
  /* color: #0088C7; */
  /* font-weight: bold; */
  font-size: 12px;
  margin-bottom: 10px;
}

.chartParametersSettingInput{
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 30px;
  width: 70%;
  margin-bottom: 0px;
}

.chartParametersSettingText{
  font-size: 12px;
  border: 1px solid #777777;
  border-radius: 10px;
  resize: none;
  padding: 5px 10px;
}

.chartWidgetSerie{
  /* height: 100%; */
  /* width: 100%; */
}

.chartWidgetPoint{
  display: inline-flex;
  flex-direction: column;
  /* width: 100%; */
  margin: 5px 10px 30px 10px;
}

.minichartWidgetPoint{
  display: inline-flex;
  flex-direction: column;
  /* width: 100%; */
  margin: 0px 10px 20px 10px;
}

.chartModelModificationPopup{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1001;
  top: 0;
  left: 0;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0, 0, 0, 0.3);
}

.chartModelInnerModificationPopup{
  height: 65%;
  /* max-height: 530px; */
  width: 70%;
  background-color: rgb(255, 255, 255);
  border: 1px solid #888;
  border-radius: 12px;
  margin: auto;
  /* margin-top: 6%; */
  padding: 20px;
}

.popupChartModelModification{
  /* display: flex; */
  /* flex-direction: column; */
  height: 100%;
  max-height: 100%;
  /* max-height: 530px; */
  /* padding: 10px 0px; */
}

.chartModelModificationContent{
  height: 100%;
  width: 100%;
  overflow: scroll;
  /* margin-top: 10px; */
  margin-bottom: 10px;
}

.chartModel{
  display: inline-flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.chartModelModification{
  display: flex;
  cursor: pointer;
  margin: 5px 10px;
  padding: 8px 13px;
  /* padding: 10px 5px 10px 0px; */
}

.chartModelModificationBordered{
  display: flex;
  cursor: pointer;
  border: solid 3px #E21313;
  background-color: #F5F5F5;
  border-radius: 9px;
  margin: 5px 10px;
  /* margin-right: 10px; */
  padding: 5px 10px;
}

.chartModelImageBorder{
  border: solid 1px #8E8E90;
  border-radius: 3px;
}

.chartModelContent{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
}

.chartModelLabel{
  display: flex;
  align-items: center;
  color: #0088C7;
  font-size: 14px;
  font-weight: bold;
}

.chartModelDescription{
  font-size: 12px;
}

.chartTypeEditPopover{
  height: fit-content;
  max-height: 500px;
  width: fit-content;
  min-width: 40px;
  margin: 10px 5px 5px 5px;
}

.chartTypeEditSearch{
  display: block;
  align-items: center;
  width: 98%;
  min-height: 30px;
  margin-bottom: 10px;
}

.chartTypeEditValues{
  overflow-y: scroll;
  max-height: 410px;
}

.chartTypeCurrentValue{
  padding: 5px 5px 5px 5px;
}

.chartTypeEditValue{
  cursor: pointer;
  padding: 5px 5px 5px 5px;
}

.chartTypeEditValue:hover{
  background-color: #D7D7D7;
  /* width: fit-content; */
  border-radius: 5px;
}

/* ----- Maps ----- */
.map{
  height: 100%;
  width: 100%;
}

.miniMap{
  height: 100%;
  width: 100%;
}

.miniMapComponent{
  height: 100%;
  width: 100%;
}

.mapDisplay{
  height: 100%;
  width: 66.66666667%;
  border-right: 1px solid #777777;
  padding-right: 20px;
}

.mapWithoutSettings{
  /* display: flex; */
  height: 100%;
  width: 100%;
}

.mapWithSettings{
  display: flex;
  height: 100%;
  width: 100%;
}

.mapSettings{
  display: flex;
  height: 100%;
  width: 33.33333333%;
  /* border-left: 1px solid #777777; */
  margin-left: 20px;
}


.templateIconBlue{
  background-color: #0088C7;
  border-radius: 10px;
  padding: 6px;
}

.templateLabel{
  /* color: #0088C7; */
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
}

.blockMeteoFields{
  border: none;
  box-shadow: none;
  resize: none;
  padding-left: 0px;
}

.meteoLabel{
  font-size: 13px;
  color: #0088C7;
  margin-bottom: 10px;
}

.blockCommunicationList{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.blockCommunicationFields{
  border: none;
  box-shadow: none;
  resize: none;
  padding: 3px 10px;
  margin-right: 10px;
  width: 100%;
}

.blockCommunicationLabel{
  font-size: 14px;
  color: #0088C7;
  /* margin-right: 20px; */
  margin-top: 6px;
}

.blockCommunicationPreviewHeader{
  display: inline-flex;
  align-items: center;
  height: 30px;
  margin-bottom: 10px;
}

.blockCommunicationPreviewLabel{
  font-size: 16px;
  font-weight: bold;
  color: #0088C7;
  margin-left: 15px;
}

.blockBurned{
  height: calc(100% - 110px);
  max-height: 100%;
}

.blockWorkload{
  height: calc(100% - 110px);
  max-height: 100%;
}

.burnedChart{
  height: calc(100vh - 135px);
  max-height: 100%;
}

.burnedHeatmap{
  /* height: 50%; */
  /* max-height: 400px; */
  margin-bottom: 30px;
}

.burnedPivot{
  /* height: 50%; */
  /* max-height: 400px; */
}

.workloadHeatmap{
  /* height: 50%; */
  /* max-height: 400px; */
  margin-bottom: 30px;
}

.workloadChart{
  height: calc(100vh - 135px);
  max-height: 100%;
}

.workloadPivot{
  /* height: 50%; */
  /* max-height: 400px; */
}

.communicationContent{
  padding-top: 10px;
}

.noresize{
  resize: none;
}

.resourceName{
  font-size: 12px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
}

.selectedMeteo{
  border-bottom: 5px solid #000000;
  padding-bottom: 5px;
}

.selectionEmpty{
  margin: auto 0px auto 33px;
}

.selectionHeatmap{
  display: inline-flex;
  height: 35px;
  /* height: 33px; */
  margin-left: 5px;
  /* margin-top: 30px; */
  /* margin-bottom: 10px; */
}

.clearHeatmapIcon{
  margin: auto 10px auto 0;
}

.selectionDetails{
  margin: auto;
}

/* .selectFilter{
  margin-bottom: 0px;
} */

/* .selectFilterView{
  font-size: 12px;
  cursor: pointer;
  max-height: 30px;
  min-width: 100px;
  border: 1px solid #8E8E90;
  border-radius: 30px;
} */

.communicationContent{
  padding-top: 10px;
}

.selectFilterPeriod{
  font-size: 12px;
  cursor: pointer;
  max-height: 30px;
  min-width: 100px;
  border: 1px solid #8E8E90;
  border-radius: 30px;
}

/*  ----- ----- Block Communication ----- ----- */
.blockCommunicationNavigation{
  display: flex;
  align-items: center;
  width: 100%;
  /* width: calc(100vw - 128px); */
  border-radius: 12px;
  background-color: #F5F5F5;
  margin-top: -5px;
  margin-bottom: 5px;
  padding: 5px 0px;
}

.blockCommunicationHeader{
  display: inline-flex;
  height: 100%;
  width: 100%;
  margin-top: 10px;
}

.blockCommunicationBorder{
  border-bottom: 1px solid #777777;
  margin-top: 10px;
  /* margin-bottom: 10px; */
}

.blockCommunicationContent{
  /* display: flex; */
  height: calc(100% - 30px);
  /* height: 100%; */
  width: 100%;
  margin-top: 10px;
}

.communicationTitle{
  text-align: center;
  font-size: 13px;
  font-weight: bold;
}

.addPresentationPopup{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1001;
  top: 0;
  left: 0;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0, 0, 0, 0.3);
}

.addPresentationInnerPopup{
  width: 45%;
  background-color: rgb(255, 255, 255);
  border: 1px solid #888;
  border-radius: 12px;
  margin: auto;
  /* margin-top: 10%; */
  padding: 20px;
}

.popupAddPresentation{
  max-height: 500px;
}

.popupPresentationTemplate{
  max-height: 480px;
}

.addPresentationLabel{
  display: flex;
  align-items: center;
  color: #0088C7;
  font-size: 16px;
  font-weight: bold;
}

.newPresentationTemplates{
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
  margin-bottom: 50px;
}

.newPresentationEmptyTemplate{
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 200px;
  cursor: pointer;
  border: 1px dashed #9E9EA0;
  border-radius: 12px;
  padding: 20px;
}

.newPresentationTemplate{
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 200px;
  border: 1px dashed #9E9EA0;
  border-radius: 12px;
  padding: 15px;
}

.presentationTemplate{
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.presentationTemplateSelected{
  display: flex;
  align-items: center;
  cursor: pointer;
  border: solid 3px #FABD05;
  background-color: #F5F5F5;
  border-radius: 9px;
  padding: 5px 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.communicationErrors{
  height: 20px;
  margin-top : -10px;
  margin-bottom: 15px;
}

.communicationSlideList{
  height: calc(100vh - 260px);
}

.communicationSlideListErrors{
  height: calc(100vh - 275px);
}

.slidesList{
  height: calc(100vh - 230px);
  /* height: 100%; */
  /* margin-top: 5px; */
}

.slide{
  height: 120px;
  width: 100%;
}

.slideTitle{
  display: flex;
  height: 14px;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
}

.slideItemLabel{
  display: flex;
  height: 12px;
  font-size: 10px;
  font-family: 'Montserrat', sans-serif;
}

.slideBorder{
  border: solid 1px #8E8E90;
  border-radius: 3px;
}

.slideLabel{
  display: flex;
  align-items: center;
  color: #0088C7;
  font-size: 14px;
  font-weight: bold;
}

.slideLabel:focus{
  display: flex;
  align-items: center;
  color: #0088C7;
  font-size: 14px;
  font-weight: bold;
}

.slideLabelName{
  display: flex;
  align-items: center;
  width: 50%;
  background-color: #F5F5F5;
  color: #000000;
  font-size: 14px;
  font-weight: bold;
}

.slideLabelName:focus{
  display: flex;
  align-items: center;
  width: 50%;
  background-color: #F5F5F5;
  color: #000000;
  font-size: 14px;
  font-weight: bold;
}

.communicationCentralSlide{
  background-color: #FFFFFF;
  border-radius: 18px;
  margin: 10px 0px;
  /* padding: 0px 60px; */
  padding: 15px 25px;
}

.communicationCentralBlock{
  height: calc(100vh - 230px);
  background-color: #F5F5F5;
  margin: 0px 20px;
  padding: 5px 50px 20px 50px;
}

.communicationCentralBlockErrors{
  height: calc(100vh - 250px);
  background-color: #F5F5F5;
  margin: 0px 10px;
  padding: 40px;
}

.communicationSubtitle{
  font-size: 14px;
  font-weight: bold;
  color: #FFC107;
}

.communicationSubtitle:focus{
  font-size: 14px;
  font-weight: bold;
  color: #FFC107;
}

/* .communicationSubtitle{
  font-size: 14px;
  font-weight: bold;
  color: #E21313;
}

.communicationSubtitle:focus{
  font-size: 14px;
  font-weight: bold;
  color: #E21313;
} */

.spinnerCommunicationActions{
  margin-top: 20px;
  margin-left: 10px;
}

.spinnerCommunicationEdition{
  margin-right: 20px;
}

.spinnerSlideEdition{
  margin-top: 15px;
  margin-bottom: 16px;
}

.spinnerSlideViewLoading{
  margin-top: 15px;
  margin-left: 15px;
  margin-bottom: 16px;
}

.slideModificationPopup{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1001;
  top: 0;
  left: 0;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0, 0, 0, 0.3);
}

.slideInnerModificationPopup{
  max-height: 80%;
  width: 60%;
  background-color: rgb(255, 255, 255);
  border: 1px solid #888;
  border-radius: 12px;
  margin: auto;
  /* margin-top: 10%; */
  padding: 20px;
}

.popupSlideFilters{
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
}

.popupAddSlide{
  max-height: 500px;
}

.newSlideCodeLink{
  padding: 8px 13px;
  /* padding: 10px 5px 10px 0px; */
}

.newSlideInner{
  max-height: 420px;
  padding: 10px 0px;
}

.newSlideCodeLinkBordered{
  border: solid 3px #E21313;
  background-color: #F5F5F5;
  border-radius: 9px;
  padding: 5px 10px;
  margin-right: 10px;
}

.newSlideType{
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
  margin-bottom: 50px;
}

.communicationSlideDateRange{
  /* margin-top: 5px; */
}

.communicationSlideIconParameters{
  display: flex;
  align-items: center;
  height: 30px;
  /* margin-left: 5px; */
  /* margin-right: 5px; */
  padding: 5px 10px;
}

.communicationSlideIconParameters:hover{
  background-color: #F5F5F5;
  border-radius: 5px;
}

.slideParameters{
  height: calc(100vh - 230px);
  /* margin-top: 5px; */
  padding: 0 10px;
}

.slideParametersLabel{
  font-size: 16px;
  font-style: italic;
  color: #0088C7;
  margin: 0px 10px 15px 15px;
}

.slideParametersLabelOrange{
  font-size: 14px;
  font-weight: bold;
  font-style: italic;
  color: #F57C12;
  margin: 0px 10px 15px 15px;
}

.slideParametersSelect{
  width: auto !important;
  border: none;
  box-shadow: none;
  color: #FFC107;
  font-size: 14px;
  font-weight: bold;
  margin: 0px 5px 0px 5px;
  padding: 3px 6px;
}

.slideParametersSelectBlue{
  width: auto !important;
  border: none;
  box-shadow: none;
  color: #0088C7;
  font-size: 14px;
  font-weight: bold;
  margin: 0px 5px 0px 5px;
  padding: 3px 6px;
}

.slideAxes{
  max-width: 230px;
  color: #0088C7 !important;
  border: solid 1px;
  border-radius: 30px !important;
  font-size: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.slideFieldsParameters{
  display: inline-flex;
  /* align-items: center; */
  margin: 10px 0px 10px 0px;
}

.slideFieldsBigLabel{
  color: #000000;
  font-weight: bold;
  font-size: 14px;
  margin: 10px 0px 10px 0px;
}

.slideFieldsLabel{
  display: flex;
  align-items: center;
  height: 30px;
  /* color: #0088C7; */
  font-weight: bold;
  font-size: 12px;
}

/* .slideFieldsLabel{
  display: flex;
  align-items: center;
  height: 30px;
  color: #FFC107;
  font-weight: bold;
  font-size: 12px;
}

.slideFieldsLabel{
  display: flex;
  align-items: center;
  height: 30px;
  color: #E21313;
  font-weight: bold;
  font-size: 12px;
} */

.slideFiltersValues{
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 400px;
  overflow: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.slideFilterValue{
  display: flex;
  align-items: center;
  min-height: 30px;
  cursor: pointer;
  padding: 3px 5px 3px 5px;
}

.slideFilterValue:hover{
  background-color: #D7D7D7;
  /* width: fit-content; */
  border-radius: 5px;
}

.slideAddParametersLabel{
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 30px;
  color: #8E8E90;
  font-size: 12px;
  font-style: italic;
  margin-left: 10px;
}

.slideDateRangeLabel{
  display: flex;
  align-items: center;
  height: 50px;
}

.slideDateRangeSelected{
  display: flex;
  align-items: center;
  height: 50px;
  border: solid 3px #E21313;
  border-radius: 9px;
  margin-left: -3px;
  padding: 10px 5px;
}

.slideSpotLagTextArea{
  width: 70px;
  border: none;
  box-shadow: none;
  resize: none;
  padding: 3px 6px;
}


/* ---------- Filters ---------- */
.filters{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* width: 50%; */
  padding-left: 0px;
  padding-right: 0px;
}

.views{
  display: flex;
  justify-content: flex-start;
  /* width: 50%; */
  padding-left: 0px;
  padding-right: 0px;
}

.filtersBurned{
  display: flex;
  justify-content: flex-start;
  height: 35px;
  /* width: 50%; */
  margin-right: 10px;
  padding-left: 0px;
  padding-right: 0px;
}

.filtersHolidays{
  display: flex;
  justify-content: flex-start;
  height: 35px;
  /* width: 50%; */
  padding-left: 0px;
  padding-right: 0px;
}

/* .filtersViewList{
  display: flex;
  min-height: 30px;
} */

.filtersView{
  display: flex;
  align-items: center;
  /* margin-right: 5px; */
  /* margin-left: 5px; */
  /* padding: 5px 10px; */
}

.filtersView:hover{
  background-color: #D7D7D7;
  /* width: fit-content; */
  border-radius: 5px;
}

.filtersKanban{
  display: flex;
  align-items: center;
  min-height: 30px;
  /* margin-left: 5px; */
  /* margin-right: 5px; */
  padding: 5px 10px;
}

.filtersKanban:hover{
  background-color: #D7D7D7;
  border-radius: 5px;
}

.filtersLevel{
  display: flex;
  align-items: center;
  min-height: 30px;
  /* margin-left: 5px; */
  /* margin-right: 5px; */
  padding: 5px 10px;
}

.filtersLevel:hover{
  background-color: #D7D7D7;
  border-radius: 5px;
}

.filtersDisplayComponent{
  display: flex;
  align-items: center;
  min-height: 30px;
  /* margin-left: 5px; */
  /* margin-right: 5px; */
  padding: 5px 10px;
}

.filtersDisplayComponent:hover{
  background-color: #D7D7D7;
  border-radius: 5px;
}

.filtersBlock{
  display: flex;
  align-items: center;
  min-height: 30px;
  /* margin-left: 5px; */
  /* margin-right: 5px; */
  padding: 5px 10px;
}

.filtersBlock:hover{
  background-color: #D7D7D7;
  border-radius: 5px;
}

.filtersBlockActive{
  display: flex;
  align-items: center;
  min-height: 30px;
  background-color: #CCE7F4;
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px 10px;
}

.filtersBlockPopup{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1001;
  top: 0;
  left: 0;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0, 0, 0, 0.3);
}

.filtersBlockInnerPopup{
  height: 60%;
  width: 80%;
  background-color: rgb(255, 255, 255);
  border: 1px solid #888;
  border-radius: 12px;
  margin: auto;
  /* margin-top: 5%; */
  padding: 20px;
}

.filtersSortPopup{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1001;
  top: 0;
  left: 0;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0, 0, 0, 0.3);
}

.filtersSortInnerPopup{
  height: 40%;
  width: 40%;
  background-color: rgb(255, 255, 255);
  border: 1px solid #888;
  border-radius: 12px;
  margin: auto;
  /* margin-top: 5%; */
  padding: 20px;
}

.popupFiltersBlock{
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
}

.popupFiltersSort{
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
}

.filtersBlockLabel{
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #0088C7;
  margin-right: 20px;
}

.filtersSortTitle{
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #0088C7;
  margin-right: 20px;
}

.addFilterLabel{
  font-size: 12px;
  font-weight: bold;
  color: #00C77A;
  margin-left: 5px;
}

.addSortLabel{
  font-size: 12px;
  font-weight: bold;
  color: #00C77A;
  margin-left: 5px;
}

.filtersBlockContent{
  display: flex;
  flex-direction: row;
  height: 100%;
  /* max-height: 750px; */
  width: 100%;
  overflow: scroll;
  margin-top: 10px;
  margin-bottom: 10px;
  /* padding-top: 10px; */
  /* padding-bottom: 10px; */
}

.filtersSortContent{
  display: flex;
  flex-direction: column;
  height: 100%;
  /* max-height: 750px; */
  width: 100%;
  overflow: scroll;
  margin-top: 10px;
  margin-bottom: 10px;
  /* padding-top: 10px; */
  /* padding-bottom: 10px; */
}

.filtersColumn{
  display: flex;
  width: 250px;
  margin-right: 20px;
}

.filtersSortRow{
  display: flex;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
}

.filtersColumnTitle{
  display: inline-flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.filtersSort{
  display: inline-flex;
  align-items: center;
  width: 200px;
  margin-right: 20px;
}

.filtersColumnName{
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
}

.filtersSortName{
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
}

.filtersColumnLabel{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  color: #777777;
}

.filtersSortLabel{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  color: #777777;
}

.filtersSortValue{
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid #777777;
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 5px;
}

.filtersEditPopover{
  height: fit-content;
  max-height: 500px;
  /* width: fit-content; */
  width: 277.61px;
  min-width: 40px;
  margin: 10px 5px 5px 5px;
}

.filtersEditSearch{
  display: block;
  align-items: center;
  width: 98%;
  min-height: 30px;
  margin-bottom: 10px;
}

.filtersEditColumns{
  overflow-y: scroll;
  max-height: 410px;
}

.filtersEditColumn{
  font-size: 14px;
  cursor: pointer;
  padding: 5px 5px 5px 5px;
}

.filtersEditColumn:hover{
  background-color: #D7D7D7;
  /* width: fit-content; */
  border-radius: 5px;
}

.filtersDisplayColumnsButton{
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 98%; */
  /* background-color: #D5D5D7; */
  min-height: 30px;
  margin-top: 10px;
}

.filtersColumnsButton{
  height: 30px;
  width: fit-content;
  font-size: 12px;
  border-radius: 26px;
  padding: 4px 12px;
  opacity: 1;
}

/* .filtersList{
  display: inline-flex;
  align-items: center;
  height: 40px;
  width: fit-content;
  max-width: fit-content;
  padding: 5px;
}

#selectFilter{
  font-size: 12px;
  cursor: pointer;
  border: none;
  border: 1px solid #8E8E90;
  box-shadow: none;
  border-radius: 30px;
} */

.filtersOperators{
  display: inline-flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 15px;
}

.filtersOperator{
  cursor: pointer;
  margin-right: 5px;
}

/* .filtersEditSearch{
  display: block;
  align-items: center;
  width: 98%;
  min-height: 30px;
  margin-bottom: 10px;
}

.filtersSearch{
  display: flex;
  align-items: center;
  min-height: 30px;
  margin-left: 10px;
} */

.filtersSearchValues{
  display: flex;
  align-items: center;
  min-height: 30px;
  margin-bottom: 10px;
}

.filtersAxeValues{
  display: flex;
  flex-direction: column;
}

.filtersAxeValue{
  display: inline-flex;
  cursor: pointer;
  width: fit-content;
  margin-top: 2px;
  margin-bottom: 2px;
}

.filtersBooleanValues{
  display: flex;
  flex-direction: column;
}

.filtersBooleanValue{
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  margin-top: 2px;
  margin-bottom: 2px;
}

.filterTypeColor{
  display: inline-flex;
  align-items: center;
  width: 110px;
  margin-bottom: 5px;
}

.filterTypeColorValue{
  font-size: 12px;
  resize: none;
  border: 1px solid #8E8E90;
  /* border: none; */
  border-radius: 10px;
  /* box-shadow: none; */
  /* padding-left: 0px; */
}

.filterTypeDate{
  /* display: inline-flex; */
  font-size: 12px;
  /* font-weight: normal; */
  /* justify-content: center; */
  width: max-content;
  /* max-width: max-content; */
  border: 1px solid #8E8E90;
  border-radius: 10px;
  margin-top: 2px;
  margin-bottom: 2px;
  padding: 4px 8px 4px 8px;
}

.filterTypeString{
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.filterTypeStringValue{
  font-size: 12px;
  resize: none;
  border: 1px solid #8E8E90;
  /* border: none; */
  border-radius: 10px;
  /* box-shadow: none; */
  /* padding-left: 0px; */
}

.filtersStatus{
  display: flex;
  align-items: center;
  min-height: 30px;
  /* margin-left: 5px; */
  /* margin-right: 5px; */
  padding: 5px 10px;
}

.filtersStatus:hover{
  background-color: #D7D7D7;
  border-radius: 5px;
}

.filtersPeriod{
  display: flex;
  align-items: center;
  min-height: 30px;
  /* margin-left: 5px; */
  /* margin-right: 5px; */
  padding: 5px 10px;
}

.filtersPeriod:hover{
  background-color: #D7D7D7;
  border-radius: 5px;
}

.conditionalFormattingPopup{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1001;
  top: 0;
  left: 0;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0, 0, 0, 0.3);
}

.conditionalFormattingInnerPopup{
  height: 60%;
  width: 80%;
  background-color: rgb(255, 255, 255);
  border: 1px solid #888;
  border-radius: 12px;
  margin: auto;
  /* margin-top: 5%; */
  padding: 20px;
}

.popupConditionalFormatting{
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
}

.conditionalFormattingLabel{
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #0088C7;
  margin-right: 20px;
}

.conditionalFormattingContent{
  display: flex;
  flex-direction: row;
  height: 100%;
  /* max-height: 750px; */
  width: 100%;
  overflow-y: scroll;
  overflow-x: scroll;
  /* margin-top: 10px; */
  margin-bottom: 10px;
  /* padding-top: 10px; */
  /* padding-bottom: 10px; */
}

.conditionalFormattingContent::-webkit-scrollbar{
  height: 6px;
  width: 6px;
  background-color: #FFFFFF;
  border-radius: 10px;
}

.conditionalFormattingContent::-webkit-scrollbar-thumb{
  background-color: #B1B2BE;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

/* Firefox */
@-moz-document url-prefix() {
  .conditionalFormattingContent{
    /* overflow-x: hidden !important; */
    /* overflow-y: hidden !important; */
    scrollbar-width: thin;
    border-radius: 10px;
  }
}

.conditionalFormatting{
  display: inline-flex;
  justify-content: flex-end;
  width: fit-content;
  border-radius: 5px;
  padding: 3px 6px;
}

.conditionalFormattingValue{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.conditionalFormattingPlanning{
  display: inline;
  justify-content: flex-end;
  width: fit-content;
  border-radius: 5px;
  padding: 3px 6px;
}

.addFormattingLabel{
  font-size: 12px;
  font-weight: bold;
  color: #00C77A;
  margin-left: 5px;
}

.formattingValues{
  display: flex;
  flex-direction: column;
}

.formattingColors{
  display: flex;
  flex-direction: row;
}

.formattingPreview{
  display: flex;
  height: 25px;
  min-height: 25px;
  width: 125px;
  margin-top: 15px;
  /* margin-bottom: 15px; */
}

.multipleModificationPopup{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1001;
  top: 0;
  left: 0;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0, 0, 0, 0.3);
}

.multipleModificationInnerPopup{
  height: 60%;
  width: 80%;
  background-color: rgb(255, 255, 255);
  border: 1px solid #888;
  border-radius: 12px;
  margin: auto;
  /* margin-top: 5%; */
  padding: 20px;
}

.popupMultipleModification{
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
}

.popupPreviewModification{
  height: 100%;
  max-height: 100%;
}

.multipleModificationLabel{
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #0088C7;
}

.multipleModificationLabelYellow{
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #FABD05;
  margin-left: 5px;
  margin-right: 5px;
}

.multipleModificationSearchValues{
  display: flex;
  align-items: center;
  min-height: 30px;
  margin-bottom: 10px;
}

.multipleModificationAxeValues{
  display: flex;
  flex-direction: column;
}

.multipleModificationAxeValue{
  display: inline-flex;
  cursor: pointer;
  width: fit-content;
  margin-top: 2px;
  margin-bottom: 2px;
}

.multipleModificationBooleanValues{
  display: flex;
  flex-direction: column;
}

.multipleModificationBooleanValue{
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  margin-top: 2px;
  margin-bottom: 2px;
}

.multipleModificationTypeColor{
  display: inline-flex;
  align-items: center;
  width: 110px;
  margin-bottom: 5px;
}

.multipleModificationTypeColorValue{
  font-size: 12px;
  resize: none;
  border: 1px solid #8E8E90;
  /* border: none; */
  border-radius: 10px;
  /* box-shadow: none; */
  /* padding-left: 0px; */
}

.multipleModificationTypeDate{
  /* display: inline-flex; */
  font-size: 12px;
  /* font-weight: normal; */
  /* justify-content: center; */
  width: max-content;
  /* max-width: max-content; */
  border: 1px solid #8E8E90;
  border-radius: 10px;
  margin-top: 2px;
  margin-bottom: 2px;
  padding: 4px 8px 4px 8px;
}

.multipleModificationTypeString{
  display: inline-flex;
  align-items: center;
  width: 100%;
  margin-top: 5px;
}

.multipleModificationTypeStringValue{
  font-size: 12px;
  resize: none;
  border: 1px solid #8E8E90;
  /* border: none; */
  border-radius: 10px;
  /* box-shadow: none; */
  /* padding-left: 0px; */
}

.multipleModificationTableContent{
  height: 100%;
  width: 100%;
  overflow: scroll;
  margin-top: 10px;
  margin-bottom: 10px;
}

.filtersColumnChooser{
  display: flex;
  align-items: center;
  min-height: 30px;
  /* margin-left: 5px; */
  /* margin-right: 5px; */
  padding: 5px 10px;
}

.filtersColumnChooser:hover{
  background-color: #D7D7D7;
  border-radius: 5px;
}

.filtersTableSettings{
  display: flex;
  align-items: center;
  min-height: 30px;
  /* margin-left: 5px; */
  /* margin-right: 5px; */
  padding: 5px 10px;
}

.filtersTableSettings:hover{
  background-color: #D7D7D7;
  border-radius: 5px;
}

.filtersChartSettings{
  display: flex;
  align-items: center;
  min-height: 30px;
  /* margin-left: 5px; */
  /* margin-right: 5px; */
  padding: 5px 10px;
}

.filtersChartSettings:hover{
  background-color: #D7D7D7;
  border-radius: 5px;
}

.filtersChartSettingsActive{
  display: flex;
  align-items: center;
  min-height: 30px;
  background-color: #CCE7F4;
  border-radius: 5px;
  padding: 5px 10px;
}

.filtersMapSettings{
  display: flex;
  align-items: center;
  min-height: 30px;
  /* margin-left: 5px; */
  /* margin-right: 5px; */
  padding: 5px 10px;
}

.filtersMapSettings:hover{
  background-color: #D7D7D7;
  border-radius: 5px;
}

.filtersMapSettingsActive{
  display: flex;
  align-items: center;
  min-height: 30px;
  background-color: #CCE7F4;
  border-radius: 5px;
  padding: 5px 10px;
}

.filtersPlanningPeriod{
  display: flex;
  align-items: center;
  min-height: 30px;
  /* margin-left: 5px; */
  /* margin-right: 5px; */
  /* padding: 5px 10px; */
}

.filtersPlanningPeriod:hover{
  background-color: #D7D7D7;
  border-radius: 5px;
}

.planningZoomAutoButton{
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  min-height: 30px;
  border: 1px solid #D7D7D7;
  border-radius: 5px;
  margin-right: 10px;
  padding: 5px 10px;
}

.planningTimelineButton{
  display: inline-flex;
  align-items: center;
  min-height: 30px;
  border: 1px solid #D7D7D7;
  border-radius: 5px;
}

.planningPeriodButton{
  display: flex;
  min-height: 30px;
  border-right: 1px solid #D7D7D7;
  /* border-radius: 5px; */
  padding: 5px 10px;
}

.planningDatepickerSeparator{
  margin-left: 5px;
  margin-right: 10px;
  border-right: 1px solid #D7D7D7;
}

.planningPeriodDatepicker{
  display: flex;
  min-height: 30px;
  /* border-right: 1px solid #D7D7D7; */
  /* border-radius: 5px; */
  padding: 5px 10px;
}

.planningZoomButton{
  display: flex;
  min-height: 30px;
  padding: 5px 10px;
}

.planningTimelinePopover{
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-height: 500px;
  width: fit-content;
  min-width: 100px;
  margin: 5px 5px 5px 5px;
}

.planningPeriodList{
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  padding: 5px;
}

.planningPeriodList:hover{
  background-color: #D7D7D7;
  /* width: fit-content; */
  border-radius: 5px;
}

.filtersPlanningSettings{
  display: flex;
  align-items: center;
  min-height: 30px;
  /* margin-left: 5px; */
  /* margin-right: 5px; */
  padding: 5px 10px;
}

.filtersPlanningSettings:hover{
  background-color: #D7D7D7;
  border-radius: 5px;
}

.columnHeaderEditable{
  color: #000000;
}

.columnHeaderNotEditable{
  color: #8E8E90;
  /* opacity: 0.9; */
}

.columnChooserPopup{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1001;
  top: 0;
  left: 0;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0, 0, 0, 0.3);
}

.columnChooserInnerPopup{
  height: 80%;
  width: 40%;
  background-color: rgb(255, 255, 255);
  border: 1px solid #888;
  border-radius: 12px;
  margin: auto;
  /* margin-top: 5%; */
  padding: 20px;
}

.columnChooserLabel{
  display: flex;
  align-items: center;
  color: #0088C7;
  font-size: 16px;
  font-weight: bold;
}

.columnChooserContent{
  height: 100%;
  /* max-height: 750px; */
  width: 100%;
  overflow-y: scroll;
  margin-top: 10px;
  margin-bottom: 10px;
  /* padding-top: 10px; */
  /* padding-bottom: 10px; */
}

.popupColumnChooser{
  height: 100%;
  max-height: 100%;
}

.filtersSearch{
  display: flex;
  align-items: center;
  min-height: 30px;
  margin-left: 10px;
  margin-right: 10px;
}

.filtersColumnsSearch{
  display: block;
  align-items: center;
  width: 30%;
  min-height: 30px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

.filtersExport{
  display: flex;
  align-items: center;
  min-height: 30px;
  /* margin-left: 10px; */
  /* margin-right: 5px; */
  padding: 5px 10px;
}

.filtersExport:hover{
  background-color: #D7D7D7;
  border-radius: 5px;
}

.filterTimetrackingMonth{
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #FABD05;
  padding-top: 4px;
}

.filtersTasksTimetracking{
  display: flex;
  align-items: center;
  min-height: 30px;
  margin-right: 5px;
}

.filterButton{
  background: #0088C7 0% 0% no-repeat padding-box;
  height: 30px;
  width: fit-content;
  border-radius: 26px;
  font-size: 12px;
  /* margin-left: 20px; */
  /* margin-right: 20px; */
  padding: 4px 12px;
  opacity: 1;
}

.dashboardButton{
  background: #0088C7 0% 0% no-repeat padding-box;
  height: 30px;
  width: fit-content;
  border-radius: 26px;
  font-size: 12px;
  /* margin-left: 20px; */
  /* margin-right: 20px; */
  padding: 4px 12px;
  opacity: 1;
}

.searchBlock{
  display: flex;
  justify-content: center;
  background-color: #FFFFFF;
  margin-bottom: 0px;
}

#searchFilters{
  height: 30px;
  /* width: 180px; */
  min-width: 180px;
  border-radius: 30px;
  font-size: 12px;
}

.levelList{
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  padding: 5px;
}

.levelList:hover{
  background-color: #D7D7D7;
  /* width: fit-content; */
  border-radius: 5px;
}

.axeList{
  display: inline-flex;
  align-items: center;
  height: 40px;
  /* width: 220px; */
  padding: 5px;
}

.keyFieldList{
  display: inline-flex;
  align-items: center;
  height: 40px;
  /* width: 220px; */
  padding: 5px;
}

.axisList{
  display: inline-flex;
  align-items: center;
  height: 34px;
  width: fit-content;
  padding: 2px;
}

.labelAxe{
  width: 60px;
  font-size: 12px;
  color: #0088C7;
  margin-bottom: 0px;
  margin-right: 10px;
}

.labelKeyField{
  width: 60px;
  font-size: 12px;
  color: #0088C7;
  margin-bottom: 0px;
  margin-right: 10px;
}

.labelAxis{
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #0088C7;
  margin-bottom: 0px;
  margin-right: 10px;
}

.colorEditPopover{
  height: fit-content;
  max-height: 500px;
  width: fit-content;
  /* min-width: 40px; */
  margin: 10px 5px;
}

.colorPreview{
  display: flex;
  padding-left: 14px;
}

.axisColor{
  height: 32px;
  width: 32px;
  border-radius: 20px;
  /* margin-top: 3px; */
  /* margin-bottom: 3px; */
}

.selectAxe{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color: #8E8E90;
  border: 1px solid #D5D5D7;
  border-radius: 30px;
  padding: 5px 10px;
}

.selectTemplate{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  font-size: 14px;
  color: #8E8E90;
  border: 1px solid #D5D5D7;
  border-radius: 30px;
  padding: 5px 12px;
}

#selectAxe{
  font-size: 12px;
  /* min-width: 150px; */
  border: 1px solid #8E8E90;
  border-radius: 30px;
  /* margin-right: 20px; */
}

#selectKeyField{
  font-size: 12px;
  /* min-width: 150px; */
  border: 1px solid #8E8E90;
  border-radius: 30px;
  /* margin-right: 20px; */
}

#selectAxis{
  font-size: 12px;
  /* min-width: 150px; */
  border: 1px solid #8E8E90;
  border-radius: 30px;
  /* margin-right: 20px; */
}

.exportList{
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  padding: 5px;
}

.exportList:hover{
  background-color: #D7D7D7;
  /* width: fit-content; */
  border-radius: 5px;
}

.spinnerExport{
  display: block;
  /* position: absolute; */
  max-height: 24px !important;
  max-width: 24px !important;
  margin-top: 0px;
  margin-left: 14px;
  margin-right: 20px;
  /* padding: 5px 10px; */
}

/* ---------- Error Messages ---------- */
.errorModification{
  background-color: #E21313;
  color: #FFFFFF;
  margin-bottom: 10px;
  padding: 5px 10px;
  border-radius: 10px;
}

.errorMessage{
  font-size: 13px;
  color: #E21313;
  /* margin-top: 10px; */
  margin-bottom: 10px;
}

/* ---------- Admin ---------- */
.adminIcon{
  background-color: #0088C7;
  border-radius: 10px;
  padding: 10px;
}

/* ---------- Environment ---------- */
.environmentHeader{
  height: 100%;
  max-height: 150px;
  width: 100%;
  margin: auto;
  padding-top: 10px;
}

.environmentTitle{
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  padding: 15px 0px;
}

.environmentIcon{
  background-color: #0088C7;
  border-radius: 10px;
  padding: 10px;
}

.environmentCategoryIcon{
  border-radius: 10px;
  padding: 10px;
}

.environmentMinicards{
  white-space: nowrap;
  overflow-x: scroll;
}

.environmentRoadmapColumn{
  height: calc(100vh - 155px);
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
}

.environmentRoadmapContent{
  height: calc(100vh - 405px);
  overflow-y: scroll;
}

.environmentResourcesColumn{
  height: calc(100vh - 125px);
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
}

.environmentResourcesContent{
  height: calc(100vh - 370px);
  overflow-y: scroll;
}

.flip-scrollbar{
  transform:rotateX(180deg);
  -ms-transform:rotateX(180deg); /* IE 9 */
  -webkit-transform:rotateX(180deg); /* Safari and Chrome */
  -moz-transform:rotateX(180deg); /* Firefox */
}

.initial{
  position: initial;
}

.btn-addbig{
  /* min-height: 170px; */
  font-size: 15px;
  border: 2px dashed #D5D5D7;
  border-radius: 13px;
  margin-top: 5px;
}

.btn-addvertical{
  font-size: 15px;
  border: 2px dashed #D5D5D7;
  border-radius: 6px;
  height: 160px;
  width: 50px;
  margin-top: 25px;
  margin-right: 20px;
}

.btn-addhorizontal{
  font-size: 15px;
  border: 2px dashed #D5D5D7;
  border-radius: 6px;
  height: 45px;
  width: 280px !important;
  margin-bottom: 5px;
  margin-left: 20px;
  margin-right: 20px;
}

.card-border{
  min-height: 170px;
  width: 280px;
  border-radius: 6px;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
}

.btn-add:hover{
  background-color: #FFFFFF;
  border-color: #B1B2BE;
  cursor: pointer;
  color: #0088C7;
}

.btn-show:hover{
  background-color: #FFFFFF;
  font-weight: bold;
  color: #0088C7;
}


/* ---------- Warnings ---------- */
.cardWarnings{
  /* display: flex; */
  /* align-items: center; */
  background-color: #E21313;
  border-radius: 10px;
  margin-top: -2px;
  margin-right: 10px;
  padding: 2px 8px 2px 4px;
}

/* ---------- Delete Bin ---------- */
.deleteBinIcon{
  background-color: #8E8E90;
  border-radius: 10px;
  padding: 10px;
}

.deleteAllItemsIcon{
  background-color: #8E8E90;
  border-radius: 10px;
  padding: 5px 10px;
}

/* ---------- Notifications ---------- */
.notificationGroup{
  display: flex;
  justify-content: flex-end;
  width: 80%;
  max-width: 600px;
  margin: auto 0px 15px 0px;
}

.notificationCard{
  width: 80%;
  max-width: 600px;
  margin-bottom: 15px;
}

.notificationCardHeader{
  display: flex;
  width: 100%;
}

.notificationCardContent{
  padding: 10px 15px;
}

.notificationMinicardIcon{
  /* margin-right: 8px; */
  padding: 10px 0px;
}

.notificationCardTitle{
  display: flex;
  align-items: center;
  width: 80%;
}

.notificationCardDate{
  width: 15%;
  margin: auto;
}

.notificationCardClose{
  width: 5%;
  margin: auto;
}

.closeNotificationIcon{
  /* margin: auto 10px auto 0; */
}

.notificationMinicardTitle{
  color: #000000;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  padding: 10px 0px;
}

.notificationMinicardDate{
  width: 20%;
}

.notificationsIcon{
  background-color: #000000;
  border-radius: 10px;
  padding: 10px;
}

/* ---------- Help ---------- */
.helpIcon{
  background-color: #0088C7;
  border-radius: 10px;
  padding: 10px;
}

/* ---------- NotificationsParameters ---------- */
.notificationsParamsIcon{
  background-color: #0088C7;
  border-radius: 10px;
  padding: 10px;
}

/* --------- Communication ---------*/
.imgSlideSize{
  background-size: cover;
  height: 70%;
  width: 100%;
  display: block;
}

.blocSlide{
  height: 70%;
  width: 100%;
}

.slideList{
  background-image: url('../Images/Slides_image/SlideListe.png');
  background-size: 100% 100%;
}

.slidePlanning{
  background-image: url('../Images/Slides_image/SlidePlanning.png');
  background-size: 100% 100%;
}

.slideDashboard{
  background-image: url('../Images/Slides_image/SlideDashboard.png');
  background-size: 100% 100%;
}

.slideOnePager{
  background-image: url('../Images/Slides_image/SlideOnePager.png');
  background-size: 100% 100%;
}

.slideMatrix{
  background-image: url('../Images/Slides_image/SlideMatrix.png');
  background-size: 100% 100%;
}

.slideMatrix_1{
  background-image: url('../Images/Slides_image/SlideMatrix-1.png');
  background-size: 100% 100%;
}

.slideBudget{
  background-image: url('../Images/Slides_image/SlideBudget.png');
  background-size: 100% 100%;
}

.slideHistogramme{
  background-image: url('../Images/Slides_image/SlideHistogramme.png');
  background-size: 100% 100%;
}

.slideCard{
  background-image: url('../Images/Slides_image/SlideCard.png');
  background-size: 100% 100%;
}

.slideIntercalaire{
  background-image: url('../Images/Slides_image/SlideIntercalaire.png');
  background-size: 100% 100%;
}

.slideMeetings{
  background-image: url('../Images/Slides_image/SlideMeetings.png');
  background-size: 100% 100%;
}

.slideRadar{
  background-image: url('../Images/Slides_image/SlideRadar.png');
  background-size: 100% 100%;
}

.slideRisks{
  background-image: url('../Images/Slides_image/SlideRisks.png');
  background-size: 100% 100%;
}

.slideRisksArray{
  background-image: url('../Images/Slides_image/SlideRisksArray.png');
  background-size: 100% 100%;
}

.slideRisksArray_1{
  background-image: url('../Images/Slides_image/SlideRisksArray\ –\ 1.png');
  background-size: 100% 100%;
}

/* ---------- Table ---------- */
.tableSettingsPopup{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1001;
  top: 0;
  left: 0;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0, 0, 0, 0.3);
}

.tableSettingsInnerPopup{
  width: 35%;
  background-color: rgb(255, 255, 255);
  border: 1px solid #888;
  border-radius: 12px;
  margin: auto;
  /* margin-top: 10%; */
  padding: 20px;
}

.tableSettingsLabelYellow{
  font-weight: bold;
  font-size: 16px;
  font-weight: bold;
  color: #FABD05;
}

.tableSettingsLabel{
  font-weight: bold;
  font-size: 16px;
  font-weight: bold;
  color: #0088C7;
}

.tableSettingsTitle{
  font-weight: bold;
  color: #0088C7;
  margin-bottom: 10px;
}

.tableSettingSubtitle{
  /* cursor: pointer; */
  display: flex;
  align-items: center;
  width: 130px;
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
  font-size: 13px;
  font-weight: normal;
}

.tableIcon{
  margin-right: 8px;
}

.minitableIcon{
  background-color: #0088C7;
  /* border-radius: 10px; */
  margin-top: -4px;
  /* padding: 10px; */
}

.tableMeetingIconBlue{
  background-color: #0088C7;
  border-radius: 6px;
  padding: 5px 6px 5px 6px;
  max-width: 30px;
}

.booleanTable{
  display: inline-block;
  width: 28px;
}

.booleanMiniTable{
  display: block;
  align-items: center;
  width: 26px;
}

.limitedHeightTable{
  max-height: 30px;
}

.ratingTable{
  display: inline-block;
  align-items: center;
}

.boolean{

}

.iconBooleanTrue{

}

.iconBooleanFalse{

}

.emailTable{
  font-style: italic;
  color: #0088C7;
  cursor: pointer;
}

.emailTable:hover{
  text-decoration: underline;
}

.popover{
  max-width: fit-content;
}

.filtersLevelPopover{
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-height: 500px;
  width: fit-content;
  min-width: 100px;
  margin: 5px 5px 5px 5px;
}

.columnActionsPopover{
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-height: 500px;
  width: fit-content;
  min-width: 40px;
  margin: 10px 5px 5px 5px;
}

.columnActionValue{
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  padding: 5px;
}

.columnActionValue:hover{
  background-color: #D7D7D7;
  /* width: fit-content; */
  border-radius: 5px;
}

.axeEditPopover{
  height: fit-content;
  max-height: 500px;
  width: fit-content;
  /* min-width: 40px; */
  margin: 10px 5px 5px 5px;
}

.axeEditSearch{
  display: block;
  align-items: center;
  width: 98%;
  min-height: 30px;
  margin-bottom: 10px;
}

.axeEditValues{
  overflow-y: scroll;
  max-height: 410px;
}

.axeEditValuesButton{
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 98%; */
  /* background-color: #D5D5D7; */
  min-height: 30px;
  margin-top: 10px;
}

.axeEditButton{
  height: 30px;
  width: fit-content;
  font-size: 12px;
  border-radius: 26px;
  padding: 4px 12px;
  opacity: 1;
}

.axeCurrentValue{
  padding: 5px 5px 5px 5px;
}

.axeEditValue{
  cursor: pointer;
  padding: 5px 5px 5px 5px;
}

.axeEditValue:hover{
  background-color: #D7D7D7;
  /* width: fit-content; */
  border-radius: 5px;
}

.cellTable{
  display: block;
  min-height: 28px;
  width: 100%;
}

.fieldDetails{
  height: 27.14px;
  width: 100%;
}

.form-check-input{
  margin-top: 0px !important;
}

.checkboxObjectTable{
  display: flex;
  align-items: center;
  font-size: 12px;
  /* margin-top: -4px; */
  margin-right: 20px;
  padding-left: 0px;
}

.minitableAxe{
  display: inline-flex;
  font-size: 11px;
  font-weight: normal;
  justify-content: center;
  max-width: max-content;
  margin-top: 2px;
  margin-bottom: 2px;
  padding: 3px 6px 3px 6px;
}

.miniplanningAxe{
  display: inline-flex;
  height: 24px;
  font-size: 11px;
  font-weight: normal;
  align-items: center;
  /* justify-content: center; */
  max-width: max-content;
  padding: 3px 6px 3px 6px;
}

.minitableLocation{
  display: inline-flex;
  font-size: 11px;
  font-weight: normal;
  justify-content: center;
  max-width: max-content;
  margin-top: 2px;
}

.minitablePriority{
  font-size: 11px;
  /* font-weight: bolder; */
  margin-left: -10px;
}

.minitableTimelineLabel{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-size: 11px;
  /* font-weight: bold; */
  width: 100%;
  height: 100%;
}

.textEditorPopup{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1001;
  top: 0;
  left: 0;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0, 0, 0, 0.3);
}

.textEditorInnerPopup{
  /* width: 70%; */
  min-height: 400px;
  min-width: 800px;
  max-height: 100%;
  max-width: 100%;
  background-color: rgb(255, 255, 255);
  border: 1px solid #888;
  border-radius: 12px;
  margin: auto;
  /* margin-top: 10%; */
  padding: 20px;
}

.textEditorClass{
  min-height: 400px;
  min-width: 800px;
  max-height: 600px;
  max-width: 100%;
}

.textEditorFormulaTitle{
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 10px;
}

.textEditorFieldTitle{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 10px;
}

.textEditorFormula{
  display: flex;
  flex-direction: column;
  height: 50%;
  margin-bottom: 20px;
}

.formulaEditorInput{
  margin-bottom: 5px;
}

.formulaEditorTextArea{
  font-size: 12px;
  width: 100%;
  border-radius: 10px;
  box-shadow: none;
  resize: none;
}

.textEditorFormulaConstructor{
  display: flex;
  height: 50%;
  min-height: 250px;
}

.textEditorFormulaFields{
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 250px;
  width: 50%;
  padding-right: 20px;
}

.textEditorFields{
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
  padding-right: 5px;
}

.textEditorField{
  font-size: 14px;
  cursor: pointer;
  padding: 5px 5px 5px 5px;
}

.textEditorField:hover{
  background-color: #D7D7D7;
  /* width: fit-content; */
  border-radius: 5px;
}

.textEditorFieldSearch{
  display: flex;
  align-items: center;
  /* width: 100px; */
  height: 20px;
  /* margin-bottom: 10px; */
}

.textEditorFormulaFunctions{
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 250px;
  width: 50%;
  padding-left: 20px;
}

.textEditorFunctions{
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
  padding-right: 5px;
}

.textEditorFunction{
  font-size: 14px;
  cursor: pointer;
  padding: 5px 5px 5px 5px;
}

.textEditorFunction:hover{
  background-color: #D7D7D7;
  /* width: fit-content; */
  border-radius: 5px;
}

/* Table Audit */
.auditPopup{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1001;
  top: 0;
  left: 0;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0, 0, 0, 0.3);
}

.auditInnerPopup{
  width: 70%;
  background-color: rgb(255, 255, 255);
  border: 1px solid #888;
  border-radius: 12px;
  margin: auto;
  /* margin-top: 10%; */
  padding: 20px;
}

.auditLabel{
  display: flex;
  align-items: center;
  color: #0088C7;
  font-size: 16px;
  font-weight: bold;
}

.auditLabelYellow{
  display: flex;
  align-items: center;
  color: #FABD05;
  font-size: 16px;
  font-weight: bold;
}

.auditEventTypeAdded{
  color: white;
  border: 1px solid #00C77A;
  background-color: #00C77A;
  border-radius: 13px;
  width: 100px;
}

.auditEventTypeModified{
  color: white;
  border: 1px solid #FABD05;
  background-color: #FABD05;
  border-radius: 13px;
  width: 100px;
}

.auditEventTypeDeleted{
  color: white;
  border: 1px solid #E21313;
  background-color: #E21313;
  border-radius: 13px;
  width: 100px;
}

/* ---------- Tree ---------- */
.treeIcon{
  background-color: #0088C7;
  /* margin-top: -4px; */
  margin-right: 8px;
}

.treeIconGrey{
  /* background-color: #0088C7; */
  /* margin-top: -4px; */
  margin-right: 8px;
}

.treeIconBlue{
  background-color: #0088C7;
  display: inline-block;
  border-radius: 7px;
  margin-right: 7px;
  padding: 7px;
}

.treeIconTurquoise{
  background-color: #26A599;
  /* margin-top: -4px; */
  margin-right: 8px;
}


/* ---------- Time Tracking ---------- */
.blockBodyTimetracking{
  display: flex;
  /* flex-direction: column; */
  height: calc(100% - 135px);
  width: 100%;
  padding: 10px 10px 10px 10px;
}

.blockTimetrackingDay{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.blockTimetrackingMonth{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.timetrackingDayContent{
  display: flex;
  height: calc(100% - 101.5px);
  width: 100%;
}

.timetrackingMonthContent{
  display: flex;
  height: calc(100% - 130px);
  width: 100%;
}

.timetrackingHeader{
  height: 100%;
  max-height: 125px;
  width: 100%;
  margin: auto;
  padding-top: 10px;
}

.timetrackingTitle{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
}

.timetrackingIndicators{
  display: flex;
  height: 75px;
  box-shadow: 0px 10px 15px #DCF1EF;
  /* margin-top: 20px; */
  margin-left: 5%;
  margin-right: 5%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.timetrackingIndicator{
  font-size: 22px;
  font-weight: bold;
  margin-top: 2px;
  margin-left: 10px;
  margin-right: 7px;
}

.popoverTimetracking{
  max-width: 400px;
}

.timetrackingMonths{
  display: flex;
  flex-direction: column;
  width: max-content;
}

.timetrackingMonth{
  width: 105px;
  font-size: 14px;
  font-weight: bold;
  margin-left: 5px;
  margin-right: 10px;
}

.timetrackingProgress{
  width: 80px;
  margin-left: 10px;
  margin-right: 10px;
}

.timetrackingBurned{
  font-size: 12px;
  margin-left: 10px;
  margin-right: 5px;
}

.headerToday{
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0088C7;
  min-width: 30px;
  border-radius: 5px;
}

.timetrackingContent{
  height: calc(100% - 20px);
  width: 100%;
  margin-top: 20px;
}

/* .timetrackingMonth{
  display: flex;
  flex-direction: column;
  height: calc(100% - 20px);
  width: 100%;
  margin-top: 20px;
} */

.timetrackingHolidays{
  margin-top: 20px;
  margin-bottom: 20px;
}

.holidaysList{
  display: flex;
  /* min-height: 72px; */
  max-width: 100%;
  margin-top: 10px;
}

.holidays{
  display: inline-block;
  text-align: center;
  margin: 5px 5px;
  min-width: 35px;
}

.holidaysLegend{
  display: flex;
  align-items: center;
  font-size: 11px;
  color: #8E8E90;
  margin-left: 10px;
  margin-right: 10px;
}

.holidaysTable{
  display: flex;
  margin: auto;
  /* display: inline-block; */
  width: 28px;
}

.holidaysMiniTable{
  display: flex;
  margin: auto;
  /* display: inline-block; */
  width: 20px;
}

.timetrackingTable{
  height: calc(100% - 0px);
  width: 100%;
  /* margin-bottom: 20px; */
}

.timetrackingWeeks{
  display: flex;
  align-items: center;
  justify-content: center;
  /* justify-content: space-around; */
  flex-direction: row;
  /* height: 100%; */
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.displayAllWeeks{
  height: 52px;
  color: #0088C7;
  /* border: 1px solid #0088C7; */
  margin-right: 20px !important;
}

.timetrackingSelectedWeek{
  align-content: flex-end;
  border-bottom: 5px solid #000000;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
}

.timetrackingNotSelectedWeek{
  align-content: flex-end;
  border-bottom: 5px solid #FFFFFF;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
}

.timetrackingWeek{
  display: grid;
  border-radius: 10px;
  opacity: 0.4;
  margin-left: 10px;
  margin-right: 10px;
  padding: 5px 10px;
}

.timetrackingCurrentWeek{
  display: grid;
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 5px 10px;
}

.weekColorWhite{
  background-color: #FFFFFF;
  color: #777777;
  border: 1px dashed #777777;
}

.weekColorBlue{
  background-color: #0088C7;
  color: #FFFFFF;
  border: 1px solid #0088C7;
}

.weekColorGreen{
  background-color: #00C77A;
  color: #FFFFFF;
  border: 1px solid #00C77A;
}

.weekColorOrange{
  background-color: #F57C12;
  color: #FFFFFF;
  border: 1px solid #F57C12;
}

.weekColorRed{
  background-color: #E21313;
  color: #FFFFFF;
  border: 1px solid #E21313;
}

.timetrackingAllTasks{
  font-weight: bold;
  border-bottom: 1px solid #000000;
}

.timetrackingOnlyActive{
  font-weight: bold;
  border-bottom: 1px solid #000000;
}

.react-bootstrap-table>table>thead>tr>th{
  vertical-align: baseline;
}

.monthBurned{
  /* display: flex; */
  font-size: 12px;
  justify-content: center;
  max-width: max-content;
  /* padding: 4px 8px 4px 8px; */
}

.lag{
  /* display: flex; */
  font-size: 12px;
  justify-content: center;
  max-width: max-content;
  /* padding: 4px 8px 4px 8px; */
}

.occupationLevelList{
  display: flex;
  min-height: 30px;
}

.occupationLevelWorkload{
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  padding: 5px;
}

.occupationLevelWorkload:hover{
  background-color: #D7D7D7;
  /* width: fit-content; */
  border-radius: 5px;
}

.timetrackingDay{
  font-size: 12px;
  text-align: center;
}

.timetrackingDayTotal{
  width: 38px;
  font-weight: normal;
  border-radius: 5px;
  margin: auto;
  /* padding: 4px 12px; */
}

.brd-dashed-grey-timetrackingDay{
  border: 1px dashed #D5D5D7;
}

.brd-timetrackingDay{
  border: 1px solid #8E8E90;
}

.brd-green-timetrackingDay{
  border: 1px solid #00C77A;
}

.brd-orange-timetrackingDay{
  border: 1px solid #F57C12;
}

.timetrackingWeekend{
  background-color: #D5D5D7;
}

.userFields{
  border: none;
  box-shadow: none;
  resize: none;
  width: 300px;
}

.information{
  
}

.licence{
  min-width: 710px;
}

.parameters{
  min-width: 500px;
}

.flag{
  position: relative;
  right: 40px;
}


/*---------- DropDown ---------*/
.dropdown:hover{
  cursor: pointer;
}

.dropdown{
  position: relative;
  display: inline-block;
}

.dropdown-content{
  display: none;
  padding: 10px;
  position: absolute;
  background-color: white;
  /* min-width: 160px; */
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.show-dropdown{
  display: block;
}

/* ----- Popup Form ------ */
.ganttSettingsPopup{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1001;
  top: 0;
  left: 0;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0, 0, 0, 0.3);
}

.ganttSettingsInnerPopup{
  width: 35%;
  background-color: rgb(255, 255, 255);
  border: 1px solid #888;
  border-radius: 12px;
  margin: auto;
  /* margin-top: 10%; */
  padding: 20px;
}

.ganttModificationPopup{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1001;
  top: 0;
  left: 0;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0, 0, 0, 0.3);
}

.ganttModificationInnerPopup{
  width: 60%;
  background-color: rgb(255, 255, 255);
  border: 1px solid #888;
  border-radius: 12px;
  margin: auto;
  /* margin-top: 10%; */
  padding: 20px;
}

.ganttSettingsLabelYellow{
  font-weight: bold;
  font-size: 16px;
  font-weight: bold;
  color: #FABD05;
}

.ganttSettingsLabel{
  font-weight: bold;
  font-size: 16px;
  font-weight: bold;
  color: #0088C7;
}

.ganttSettingsTitle{
  font-weight: bold;
  color: #0088C7;
  margin-bottom: 10px;
}

.ganttSetting{
  /* cursor: pointer; */
  display: flex;
  align-items: center;
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
  font-size: 13px;
  font-weight: normal;
}

.ganttSettingSubtitle{
  /* cursor: pointer; */
  display: flex;
  align-items: center;
  width: 40px;
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
  font-size: 13px;
  font-weight: normal;
}

/* ----- LoadingSpinner ------ */
.loading{
  position: sticky;
  padding-top: 50px;
  /* padding-right: 150px; */
  top: 0;
}

/* --------- Block Budget + Doughnut -----------*/
.blockBudget{
  display: flex;
  height: 100%;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.blockBudgetComponent{
  height: 100%;
  width: 100%;
}

.blockBudgetDoughnut{
  position: relative;
  width: 100%;
  min-width: 440px;
  max-width: 440px;
  padding: 0 20px 0 20px;
}

.blockBudgetTable{
  width: 100%;
  max-width: calc(100% - 440px);
}

.budgetDoughnut{
  height: calc(100% - 180px);
  width: 100%;
  min-width: 400px;
  /* max-width: 440px; */
  /* aspect-ratio: auto; */
}

.blockBudgetDoughnutValue{
  position: absolute;
  left: 50%;
  margin-top: -210px;
  transform: translate(-50%, -50%);
}

.selectedBudgetUnit{
  border-bottom: 5px solid #000000;
  padding-bottom: 5px;
}

.miniblockBudgetDoughnut{
  display: flex;
  height: inherit;
  /* width: 60%; */
}

.miniblockBudgetDoughnutValue{
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.miniblockBudgetDoughnutPercentage{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 22px;
  font-weight: bold;
}

.miniblockBudgetDoughnutUnit{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 11px;
  font-weight: bold;
}

/* --------- Block Meetings + Calendar -----------*/
.blockMeetings{
  display: flex;
  height: 100%;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.blockMeetingsComponent{
  height: 100%;
  width: 100%;
}

.blockMeetingsCalendar{
  width: 100%;
  min-width: 450px;
  padding-right: 30px;
}

.blockMeetingsTable{
  width: 100%;
  max-width: calc(100% - 450px);
}

/* --------- Block Risks + Matrix -----------*/
.blockRisks{
  display: flex;
  height: 100%;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.blockRisksComponent{
  height: 100%;
  width: 100%;
}

.blockRisksMatrix{
  width: 100%;
  min-width: 500px;
  max-width: 640px;
}

.blockRisksTable{
  width: 100%;
  max-width: calc(100% - 500px);
}

.riskMatrix{
  height: 100%;
  width: 100%;
  max-width: 640px;
}

.matrixAxe{
  font-size: 14px;
  font-weight: bold;
  color: gray;
  margin: 10px;
}

.matrixHr{
  width: 7em;
  border-color: gray;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.matrixOrdinateHr{
  width: 7em;
  border-color: gray;
  box-sizing: border-box;
  transform: rotate(270deg);
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.matrixAbscissaHr{
  width: 7em;
  border-color: gray;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.matrixCell .matrixResult{
  display: inline-block;
}

.matrixCell{
  height: 100px;
  width: 100px;
  min-width: 30px;
  overflow: hidden;
  text-align: center;
  columns: 2; /*used for the two colums*/
  column-gap: 0px;
  column-fill: balance;
  margin : 5px;
  padding: 4px;
}

.matrixResult{
  color: #FFFFFF;
  height: 24px;
  width: 26px;
  font-size: 12px;
  text-align: center;
  border-radius: 20px;
  margin: 3px;
  padding: 3px 3px 3px 3px;
}

.mat-index{
  height: 24px;
  width: 26px;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  padding: 4px 3px 3px 3px;
}

.matrixAbscissa{
  width: 100px;
  font-size: 11px;
  color: gray;
  text-align: center;
  border: 5px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 4px;
}

.matrixOrdinate{
  font-size: 11px;
  color: gray;
  transform: rotate(270deg);
  text-align: center;
  margin-top: 49px;
}

/* Matrix 4 * 3 */
.riskMatrix43{
  height: 100%;
  width: 100%;
  margin-top: 10px;
}

.maxtrixRow43{
  height: 110px;
}

.matrixCell43{
  display: inline-block;
  height: 100px;
  width: 100px;
  min-width: 30px;
  overflow: hidden;
  text-align: center;
  columns: 2;
  column-gap: 0px;
  column-fill: balance;
  margin: 5px;
  padding: 4px;
}

.matrixResult43{
  display: inline-block;
  text-align: center;
  align-items: center;
  height: 24px;
  width: 24px;
  color: #FFFFFF;
  font-size: 12px;
  margin: 3px;
  border-radius: 20px;
  padding: 3px 3px 3px 3px;
}

.matrixAdditional43{
  display: flex;
  align-items: center;
  text-align: center;
  height: 24px;
  width: 24px;
  font-size: 12px;
  font-weight: bold;
  margin: 4px 8px 4px 8px;
}

.matrixOrdinateTitle43{
  display: flex;
  position: absolute;
  height: 100% !important;
  transform: rotate(270deg);
  color: gray;
  left: -145px;
  top: 165px;
}

.matrixAbscissaTitle43{
  display: flex;
  max-width: 330px;
  justify-content: center;
  /* margin: auto; */
}

.miniRiskMatrix43{
  display: block;
  height: 100%;
  width: 100%;
}

.miniMatrixHeight43{
  display: flex;
  height: 25%;
  width: 100%;
}

.miniMatrixWidth43{
  width: 33.33%;
  margin: 5px;
}

.miniMatrixCell43{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* height: 100px; */
  /* min-height: 28px; */
  /* min-width: 28px; */
  padding: 4px;
}

.miniMatrixResult43{
  display: inline-block;
  text-align: center;
  align-items: center;
  aspect-ratio: 1 / 1;
  /* height: 60%; */
  width: 50%;
  min-height: 18px;
  min-width: 18px;
  max-height: 24px;
  max-width: 24px;
  color: #FFFFFF;
  font-size: 11px;
  /* margin: 3px; */
  border-radius: 20px;
  padding: 3px 3px 3px 3px;
}

/* Matrix 5 * 5 */
.riskMatrix55{
  display: flex;
  height: fit-content;
  width: 100%;
  max-width: 640px;
  margin-top: 10px;
}

.matrixContent55{
  width: calc(100% - 30px);
  max-width: 600px;
  margin-right: 30px;
}

.matrixOrdinate55{
  display: flex;
  align-items: center;
  width: 40px;
  margin-top: -65px;
}

.matrixOrdinateTitle55{
  display: block;
  /* position: absolute; */
  /* height: 100%; */
  color: gray;
  text-align: center;
  transform: rotate(180deg);
  writing-mode: tb;
  text-orientation: mixed;
}

.matrixOrdinateLabel55{
  /* display: flex; */
  height: 100%;
  width: 100%;
  font-size: 11px;
  color: gray;
  align-items: center;
  text-align: center;
  transform: rotate(180deg);
  writing-mode: tb;
  text-orientation: mixed;
}

.matrixOrdinateValues55{
  display: flex;
  align-items: center;
  height: 100%;
  width: 25px;
  margin: 2.5px 0px;
}

.matrixHeight55{
  display: inline-flex;
  height: 10vh;
  width: 100%;
  min-height: 80px;
  max-height: 100px;
}

.matrixWidth55{
  display: flex;
  height: 100%;
  width: 20%;
  min-height: 80px;
  max-height: 95px;
  min-width: 75px;
  max-width: 100px;
  margin: 2.5px;
}

.matrixCell55{
  display: inline-block;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  /* height: calc(100% - 5px); */
  /* width: calc(20% - 15px); */
  height: 100%;
  width: 100%;
  min-height: 50px;
  min-width: 50px;
  overflow: hidden;
  text-align: center;
  columns: 2;
  column-gap: 0px;
  /* margin: 2.5px; */
  padding: 4px;
}

.matrixResult55{
  display: inline-block;
  text-align: center;
  align-items: center;
  /* height: 24px; */
  /* width: 24px; */
  height: 30%;
  width: 60%;
  min-height: 24px;
  min-width: 24px;
  color: #FFFFFF;
  font-size: 12px;
  margin: 15%;
  border-radius: 20px;
  padding: 3px 3px 3px 3px;
}

.matrixAdditional55{
  display: flex;
  align-items: center;
  text-align: center;
  height: 24px;
  width: 24px;
  font-size: 12px;
  font-weight: bold;
  margin: 6px 8px 4px 8px;
}

.matrixAbscissaValues55{
  display: flex;
  height: 25px;
  width: 25px;
}

.matrixAbscissaTitle55{
  display: flex;
  max-width: 300px;
  justify-content: center;
  margin: auto;
}

.matrixAbscissaCells55{
  width: calc(100% - 25px);
  max-width: 525px;
}

.matrixAbscissa55{
  position: relative;
  float: left;
  width: calc(20% - 6px);
  min-width: 75px;
  max-width: 100px;
  font-size: 11px;
  color: gray;
  text-align: center;
  /* margin-top: 5px; */
  margin-left: 3px;
  margin-right: 3px;
  padding: 4px;
}

.miniRiskMatrix55{
  display: block;
  height: 100%;
  width: 100%;
}

.miniMatrixHeight55{
  display: flex;
  height: 20%;
  width: 100%;
}

.miniMatrixWidth55{
  width: 20%;
  margin: 5px;
}

.miniMatrixCell55{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* height: 100px; */
  /* min-height: 28px; */
  /* min-width: 28px; */
  padding: 4px;
}

.miniMatrixResult55{
  display: inline-block;
  text-align: center;
  align-items: center;
  aspect-ratio: 1 / 1;
  /* height: 60%; */
  width: 50%;
  min-height: 18px;
  min-width: 18px;
  max-height: 24px;
  max-width: 24px;
  color: #FFFFFF;
  font-size: 11px;
  /* margin: 3px; */
  border-radius: 20px;
  /* padding: 3px 3px 3px 3px; */
}

/* --------- Matrix Colors (Impact/Probability) -----------*/
/* Impact: Critical - Probability: Critical */
.bg-critical-critical{
  background-color: #851D1D;
}
.border-critical-critical{
  border: 1px solid #851D1D;
  background-color: transparent;
}
.critical-critical{
  color: #851D1D;
}

/* ---------- Dark Red ---------- */
/* Impact: Critical - Probability: High */
.bg-critical-high{
  background-color: #851D1D;
}
.border-critical-high{
  border: 1px solid #851D1D;
  background-color: transparent;
}
.critical-high{
  color: #851D1D;
}

/* Impact: High - Probability: Critical */
.bg-high-critical{
  background-color: #851D1D;
}
.border-high-critical{
  border: 1px solid #851D1D;
  background-color: transparent;
}
.high-critical{
  color: #851D1D;
}

/* ---------- Red ---------- */
/* Impact: Critical - Probability: Medium */
.bg-critical-medium{
  background-color: #C73500;
}
.border-critical-medium{
  border: 1px solid #C73500;
  background-color: transparent;
}
.critical-medium{
  color: #C73500;
}

/* Impact: High - Probability: High */
.bg-high-high{
  background-color: #C73500;
}
.border-high-high{
  border: 1px solid #C73500;
  background-color: transparent;
}
.high-high{
  color: #C73500;
}

/* Impact: Medium - Probability: Critical */
.bg-medium-critical{
  background-color: #C73500;
}
.border-medium-critical{
  border: 1px solid #C73500;
  background-color: transparent;
}
.medium-critical{
  color: #C73500;
}

/* ---------- Orange ---------- */
/* Impact: Critical - Probability: Low */
.bg-critical-low{
  background-color: #ED7300;
}
.border-critical-low{
  border: 1px solid #ED7300;
  background-color: transparent;
}
.critical-low{
  color: #ED7300;
}

/* Impact: High - Probability: Medium */
.bg-high-medium{
  background-color: #ED7300;
}
.border-high-medium{
  border: 1px solid #ED7300;
  background-color: transparent;
}
.high-medium{
  color: #ED7300;
}

/* Impact: Medium - Probability: High */
.bg-medium-high{
  background-color: #ED7300;
}
.border-medium-high{
  border: 1px solid #ED7300;
  background-color: transparent;
}
.medium-high{
  color: #ED7300;
}

/* Impact: Low - Probability: Critical */
.bg-low-critical{
  background-color: #ED7300;
}
.border-low-critical{
  border: 1px solid #ED7300;
  background-color: transparent;
}
.low-critical{
  color: #ED7300;
}

/* ---------- Orange ---------- */
/* Impact: Critical - Probability: Very Low */
.bg-critical-verylow{
  background-color: #EDA600;
}
.border-critical-verylow{
  border: 1px solid #EDA600;
  background-color: transparent;
}
.critical-verylow{
  color: #EDA600;
}

/* Impact: High - Probability: Low */
.bg-high-low{
  background-color: #EDA600;
}
.border-high-low{
  border: 1px solid #EDA600;
  background-color: transparent;
}
.high-low{
  color: #EDA600;
}

/* Impact: Medium - Probability: Medium */
.bg-medium-medium{
  background-color: #EDA600;
}
.border-medium-medium{
  border: 1px solid #EDA600;
  background-color: transparent;
}
.medium-medium{
  color: #EDA600;
}

/* Impact: Low - Probability: High */
.bg-low-high{
  background-color: #EDA600;
}
.border-low-high{
  border: 1px solid #EDA600;
  background-color: transparent;
}
.low-high{
  color: #EDA600;
}

/* Impact: Very Low - Probability: Critical */
.bg-verylow-critical{
  background-color: #EDA600;
}
.border-verylow-critical{
  border: 1px solid #EDA600;
  background-color: transparent;
}
.verylow-critical{
  color: #EDA600;
}

/* ---------- Orange ---------- */
/* Impact: High - Probability: Very Low */
.bg-high-verylow{
  background-color: #EDA600;
}
.border-high-verylow{
  border: 1px solid #EDA600;
  background-color: transparent;
}
.high-verylow{
  color: #EDA600;
}

/* Impact: Medium - Probability: Low */
.bg-medium-low{
  background-color: #EDA600;
}
.border-medium-low{
  border: 1px solid #EDA600;
  background-color: transparent;
}
.medium-low{
  color: #EDA600;
}

/* Impact: Low - Probability: Medium */
.bg-low-medium{
  background-color: #EDA600;
}
.border-low-medium{
  border: 1px solid #EDA600;
  background-color: transparent;
}
.low-medium{
  color: #EDA600;
}

/* Impact: Very Low - Probability: High */
.bg-verylow-high{
  background-color: #EDA600;
}
.border-verylow-high{
  border: 1px solid #EDA600;
  background-color: transparent;
}
.verylow-high{
  color: #EDA600;
}


/* ---------- Green ---------- */
/* Impact: Medium - Probability: Very Low */
.bg-medium-verylow{
  background-color: #5FAD74;
}
.border-medium-verylow{
  border: 1px solid #5FAD74;
  background-color: transparent;
}
.medium-verylow{
  color: #5FAD74;
}

/* Impact: Low - Probability: Low */
.bg-low-low{
  background-color: #5FAD74;
}
.border-low-low{
  border: 1px solid #5FAD74;
  background-color: transparent;
}
.low-low{
  color: #5FAD74;
}

/* Impact: Very Low - Probability: Medium */
.bg-verylow-medium{
  background-color: #5FAD74;
}
.border-verylow-medium{
  border: 1px solid #5FAD74;
  background-color: transparent;
}
.verylow-medium{
  color: #5FAD74;
}

/* ---------- Green ---------- */
/* Impact: Low - Probability: Low */
.bg-low-verylow{
  background-color: #5FAD74;
}
.border-low-verylow{
  border: 1px solid #5FAD74;
  background-color: transparent;
}
.low-verylow{
  color: #5FAD74;
}

/* Impact: Very Low - Probability: Low */
.bg-verylow-low{
  background-color: #5FAD74;
}
.border-verylow-low{
  border: 1px solid #5FAD74;
  background-color: transparent;
}
.verylow-low{
  color: #5FAD74;
}


/* ---------- Light Green ---------- */
/* Impact: Very Low - Probability: Very Low */
.bg-verylow-verylow{
  background-color: #5FAD74;
}
.border-verylow-verylow{
  border: 1px solid #5FAD74;
  background-color: transparent;
}
.verylow-verylow{
  color: #5FAD74;
}

/* ---------- Scheduler ---------- */
.miniSchedulerDate{
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
  font-weight: bold;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

/* ---------- Triangle ---------- */
.arrow-right{
  height: 0;
  width: 0;
  position: relative;
  left: -4px;
  bottom: -16px;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid gray;
}

.chart-container{
  position: relative;
  height: 200px;
  width: 200px;
  /* margin: auto; */
}

.blockBudgetDetails{
  /* display: block; */
  /* width: 50%; */
}

.miniblockBudgetDetails{
  height: 100%;
  width: 100%;
  margin-left: 10%;
}

.blockBudgetIndicators{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.miniblockBudgetIndicators{
  height: 220px;
  /* padding-top: 25%;
  padding-bottom: 25%; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}

.budgetDetailsElement{
  width: 33%;
  line-height: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 10px;
}

.miniblockBudgetDetailsElement{
  /* width: 33%; */
  line-height: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 10px;
}

.blockBudgetDoughnutPercentage{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 32px;
  font-weight: bold;
}

.blockBudgetDoughnutUnit{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
}

.blockBudgetIndicatorValue{
  font-size: 18px;
  color: #000000;
  font-weight: bold;
  margin-top: 3px;
}

.miniblockBudgetIndicatorValue{
  font-size: 14px;
  color: #000000;
  font-weight: bold;
  margin-top: 3px;
}

.blockBudgetIndicatorLabel{
  font-size: 12px;
  color: #000000;
  margin-top: 5px;
}

.miniblockBudgetIndicatorLabel{
  font-size: 11px;
  color: #000000;
  margin-top: 5px;
}

.budgetDetailsBudgetBorder{
  border-left: 6px solid #D5D5D7;
  padding-right: 10px;
}

.budgetDetailsNotAllocatedBorder {
    border-left: 6px solid #00C77A;
    padding-right: 10px;
}

.budgetDetailsBurnedBorder{
  border-left: 6px solid #336699;
  padding-right: 10px;
}

.budgetDetailsWorkloadBorder{
  border-left: 6px solid #41B2E7;
  padding-right: 10px;
}

.budgetDetailsOverBurnedBorder{
  border-left: 6px solid #E21313;
  padding-right: 10px;
}

.budgetDetailsOverWorkloadBorder{
  border-left: 6px solid #E21313;
  padding-right: 10px;
}

/* ---------- Responsive Media Queries ---------- */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .tabstersBlogImage{
    display: flex;
    height: 100px;
    width: 100px;
  }

  .tabstersBlogArticleLeft{
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    height: 160px;
    width: 50%;
    margin: auto;
    padding-left: 0px;
  }
  
  .tabstersBlogArticleRight{
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    height: 160px;
    width: 50%;
    margin: auto;
    padding-right: 0px;
  }

  .tabstersBlogArticleName{
    display: flex;
    /* justify-content: center; */
    text-align: center;
    width: 100px;
    margin-top: 10px;
  }

  .cardContent{
    width: 320px;
  }

  .cardName{
    font-size: 14px;
  }

  .cardIconBlue{
    padding: 6px;
  }

  .indicatorsRights{
    padding-right: 0px;
  }

  .navigationSize{
    font-size: 10px;
  }

  .navigationBlocks{
    display: flex;
    flex-wrap: wrap;
    min-width: 345px;
  }

  .navigationActive{
    display: flex;
    flex-wrap: wrap;
    min-width: 345px;
  }

  .blockHeader{
    height: 100%;
    max-height: 85px;
    width: 100%;
    margin: auto;
    padding-top: 5px;
  }

  .blockFilters{
    height: 70px;
  }

  .blockTitle{
    height: 64px;
  }

  .filters{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  
  .views{
    display: flex;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .timetrackingWeeks{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .filtersHolidays{
    display: flex;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .projectManager{
    font-size: 10px;
  }

  .iconsCard{
    height: 18px;
    width: 18px;
  }

  .mediumIcons{
    height: 20px;
    width: 20px;
  }

  .iconsRectangle{
    height: 20px;
    width: 40px;
  }

  .bigBadge{
    font-size: 10px;
    padding: 6px 8px 5px 8px;
  }
}

/* Extra Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 767.99px) {  
  .tabstersBlogImage{
    display: flex;
    height: 120px;
    width: 120px;
  }

  .tabstersBlogArticleLeft{
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    height: 180px;
    width: 50%;
    margin: auto;
    padding-left: 0px;
  }
  
  .tabstersBlogArticleRight{
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    height: 180px;
    width: 50%;
    margin: auto;
    padding-right: 0px;
  }

  .tabstersBlogArticleName{
    display: flex;
    /* justify-content: center; */
    text-align: center;
    width: 120px;
    margin-top: 10px;
  }
  
  .cardContent{
    width: 600px;
  }

  .cardName{
    font-size: 16px;
  }

  .cardIconBlue{
    padding: 7px;
  }

  .cardParents{
    max-width: 350px;
  }

  .indicatorsRights{
    padding-right: 0px;
  }

  .navigationSize{
    font-size: 11px;
  }

  .navigationBlocks{
    flex-flow: wrap;
  }

  .navigationActive{
    flex-flow: wrap;
  }

  .blockHeader{
    max-height: 85px;
  }

  .blockTitle{
    height: 79px;
  }

  .blockFilters{
    height: 70px;
  }

  .filters{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  
  .views{
    display: flex;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .timetrackingWeeks{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .filtersHolidays{
    display: flex;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .miniblockEmptyText{
    font-size: 8px;
    margin-left: 8px;
  }

  /* .miniblockStatus{
    font-size: 9px;
    padding: 3px 4px 3px 4px;
  } */

  .projectManager{
    font-size: 10px;
  }

  .iconsCard{
    height: 20px;
    width: 20px;
  }

  .mediumIcons{
    height: 22px;
    width: 22px;
  }

  .iconsRectangle{
    height: 22px;
    width: 44px;
  }

  .bigBadge{
    font-size: 10px;
    padding: 7px 10px 6px 10px;
  }
}

/* Very Small devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .tabstersBlogImage{
    display: flex;
    height: 160px;
    width: 160px;
  }

  .tabstersBlogArticleLeft{
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    height: 220px;
    width: 50%;
    margin: auto;
    padding-left: 0px;
  }
  
  .tabstersBlogArticleRight{
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    height: 220px;
    width: 50%;
    margin: auto;
    padding-right: 0px;
  }

  .tabstersBlogArticleName{
    display: flex;
    /* justify-content: center; */
    text-align: center;
    width: 180px;
    margin-top: 10px;
  }

  .cardContent{
    width: 768px;
  }

  .cardName{
    font-size: 18px;
  }

  .cardIconBlue{
    padding: 8px;
  }

  .cardParents{
    max-width: 350px;
  }

  .indicatorsRights{
    padding-right: 0px;
  }

  .navigationSize{
    font-size: 11px;
  }

  .navigationBlocks{
    flex-flow: wrap;
    /* margin-top: 30px; */
  }

  .navigationActive{
    flex-flow: wrap;
    /* margin-top: 30px; */
  }

  .blockFilters{
    height: 70px;
  }

  .filters{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  
  .views{
    display: flex;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .timetrackingWeeks{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .filtersHolidays{
    display: flex;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .miniblockEmptyText{
    font-size: 8px;
    bottom: 35px;
    margin-left: 8px;
  }

  /* .miniblockStatus{
    font-size: 9px;
    padding: 3px 4px 3px 4px;
  } */

  .projectManager{
    font-size: 11px;
  }

  .iconsCard{
    height: 22px;
    width: 22px;
  }

  .mediumIcons{
    height: 24px;
    width: 24px;
  }

  .iconsRectangle{
    height: 24px;
    width: 48px;
  }

  .bigBadge{
    font-size: 10px;
    padding: 7px 12px 6px 12px;
  }
}

/* Very devices (laptops/desktops, 992px and 1024px) */
@media only screen and (min-width: 992px) and (max-width: 1023.98px) {
  .tabstersBlogImage{
    display: flex;
    height: 180px;
    width: 180px;
  }

  .tabstersBlogArticleLeft{
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    height: 240px;
    width: 50%;
    margin: auto;
    padding-left: 0px;
  }
  
  .tabstersBlogArticleRight{
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    height: 240px;
    width: 50%;
    margin: auto;
    padding-right: 0px;
  }

  .tabstersBlogArticleName{
    display: flex;
    /* justify-content: center; */
    text-align: center;
    width: 200px;
    margin-top: 10px;
  }
  
  .cardContent{
    min-width: 992px;
  }

  .cardParents{
    max-width: 350px;
  }

  .navigationBlocks{
    /* margin-top: 30px; */
  }

  .navigationActive{
    /* margin-top: 30px; */
  }

  .blockFilters{
    height: 70px;
  }

  .filters{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  
  .views{
    display: flex;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .timetrackingWeeks{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .filtersHolidays{
    display: flex;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .mediumIcons{
    height: 28px;
    width: 28px;
  }

  .iconsRectangle{
    height: 28px;
    width: 56px;
  }

  .bigBadge{
    font-size: 11px;
    padding: 7px 15px 6px 15px;
  }
}

/* Small devices (laptops/desktops, 1024px and 1200px) */
@media only screen and (min-width: 1023.99px) and (max-width: 1199.98px) {
  .tabstersBlogImage{
    display: flex;
    height: 180px;
    width: 180px;
    margin: auto;
  }

  .tabstersBlogArticleLeft{
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    height: 180px;
    width: 50%;
    margin: auto;
    padding-left: 0px;
  }
  
  .tabstersBlogArticleRight{
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    height: 180px;
    width: 50%;
    margin: auto;
    padding-right: 0px;
  }

  .tabstersBlogArticleName{
    display: flex;
    /* justify-content: center; */
    text-align: center;
    width: 200px;
    margin-top: 10px;
  }

  .cardContent{
    min-width: 1024px;
  }

  .cardParents{
    max-width: 350px;
  }

  .blockFilters{
    height: 70px;
  }

  .filters{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  
  .views{
    display: flex;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .timetrackingWeeks{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .filtersHolidays{
    display: flex;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
}

/* Small devices (little laptops and desktops, 1200px and down) */
@media only screen and (max-width: 1200px) {
  .matrixHr{
    width: 50px;
    border-color: gray  !important;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
  }

  .matrixOrdinateTitle43{
    display: flex;
    position: absolute;
    height: 100% !important;
    transform: rotate(270deg);
    color: gray;
    left: -100px;
    top: 215px;
  }

  .miniMatrixResult55{
    padding: 2px 2px 2px 2px;
  }
}

/* Medium devices (large laptops and desktops, between 1200px and 1440px) */
@media only screen and (min-width: 1200px) and (max-width: 1439.98px) {
  .tabstersWebappVersion{
    top: 5px;
    left: 10px;
  }

  .loginContent{
    padding-top: 0px;
  }
  
  .tabstersBlogImage{
    display: flex;
    height: 200px;
    width: 200px;
    margin: auto;
  }

  .tabstersBlogArticleLeft{
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    height: 235px;
    width: 50%;
    margin: auto;
    padding-left: 0px;
  }
  
  .tabstersBlogArticleRight{
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    height: 235px;
    width: 50%;
    margin: auto;
    padding-right: 0px;
  }

  .tabstersBlogArticleName{
    display: flex;
    /* justify-content: center; */
    text-align: center;
    width: 240px;
    margin-top: 10px;
  }
  
  .cardContent{
    min-width: 1200px;
  }

  .blockFilters{
    height: 70px;
  }

  .filters{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  
  .views{
    display: flex;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .matrixHr{
    width: 100px !important;
    border-color: gray;
    box-sizing: border-box;
    /* margin-bottom: 50px; */
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
  }

  .matrixOrdinateTitle43{
    display: flex;
    position: absolute;
    height: 100% !important;
    transform: rotate(270deg);
    color: gray;
    left: -145px !important;
    top: 215px;
  }

  .miniMatrixResult55{
    padding: 2px 2px 2px 2px;
  }
}

/* Medium devices (mediul laptops and desktops, 1440px and down) */
@media only screen and (max-width: 1439px) {
  .slide{
    height: 100px;
    width: 100%;
  }

  .iconsSlide{
    display: inline-block;
    height: 60px;
    width: 142px;
    vertical-align: top;
  }

  .iconsBigSlides{
    display: inline-block;
    height: 200px;
    width: 480px;
    vertical-align: top;
  }
}

/* Large devices (large laptops and desktops, 1440px and up) */
@media only screen and (min-width: 1440px) {
  .cardContent{
    min-width: 1440px;
  }

  .matrixHr{
    width: 100px !important;
    border-color: gray;
    box-sizing: border-box;
    /* margin-bottom: 50px; */
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
  }

  .matrixOrdinateTitle43{
    display: flex;
    position: absolute;
    height: 100% !important;
    transform: rotate(270deg);
    color: gray;
    left: -145px !important;
    top: 215px;
  }

  .miniMatrixResult55{
    padding: 4px 3px 4px 3px;
  }

  .slide{
    height: 120px;
    width: 100%;
  }

  .iconsSlide{
    display: inline-block;
    height: 85px;
    width: 190px;
    vertical-align: top;
  }

  .iconsBigSlides{
    display: inline-block;
    height: 300px;
    width: 720px;
    vertical-align: top;
  }
}

/* Very large devices (large laptops and desktops, 1600px and up) */
@media only screen and (min-width: 1600px) {
  .blockBudgetDoughnut{
    position: relative;
    width: 100%;
    min-width: 480px;
    max-width: 480px;
    padding: 0 20px 0 20px;
  }
  
  .blockBudgetTable{
    width: 100%;
    max-width: calc(100% - 480px);
  }
  
  .budgetDoughnut{
    height: calc(100% - 180px);
    width: 100%;
    min-width: 450px;
    /* max-width: 450px; */
    /* aspect-ratio: auto; */
  }

  .blockBudgetDoughnutValue{
    position: absolute;
    left: 51%;
    margin-top: -225px;
    transform: translate(-50%, -50%);
  }
}

/* Extra large devices (large laptops and desktops, between 1815px and 1930px) */
@media (min-width: 1815px) and (max-width: 1929.98px) {
  .ganttSettingsInnerPopup{
    width: 35% !important;
    background-color: rgb(255, 255, 255);
    border: 1px solid #888;
    border-radius: 12px;
    margin: auto;
    /* margin-top: 10%; */
    padding: 20px;
  }

  .miniMatrixResult55{
    padding: 5px 3px 5px 3px;
  }
}

/* 13" screen */
@media (min-width: 35.5em) {
  .ganttSettingsInnerPopup{
    width: 50%;
    background-color: rgb(255, 255, 255);
    border: 1px solid #888;
    border-radius: 12px;
    margin: auto;
    /* margin-top: 10%; */
    padding: 20px;
  }
}